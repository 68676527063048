export const getDefaultQuestions = () => {
    return [
        {
            _id: 0,
            question:
                "What are the details?",
            questionType: "textarea",
            pushedQuestion: 'Description',
        },
        {
            _id: 1,
            question: "Where do you need this done?",
            questionType: "radio",
            pushedQuestion: 'Where',
            choices: [
                {
                    _id: 0,
                    option: "In Person",
                },
                {
                    _id: 1,
                    option: "Online",
                },
            ],
        },
        {
            _id: 2,
            question: "When do you need this done?",
            questionType: "radio",
            pushedQuestion: 'When',
            choices: [
                {
                    _id: 0,
                    option: "As soon as possible",
                },
                {
                    _id: 1,
                    option: "Any time that works",
                },
                {
                    _id: 2,
                    option: "Specific date",
                },
            ],
        },
        {
            _id: 3,
            question: "What is your budget?",
            questionType: "radio",
            pushedQuestion: 'Default Budget',
            choices: [
                {
                    _id: 0,
                    option: "Total",
                },
                {
                    _id: 1,
                    option: "Hourly Rate",
                }
            ],
        }
    ]
}
