import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import ApmTable from '../../../../appmakers/components/ApmTableWithoutEdit';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { rootUrl } from '../../../../api-handling/rootUrl';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { http } from '../../../../appmakers/services/http-service';

import IntlMessages from '@jumbo/utils/IntlMessages';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { Question } from './questions';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import Swal from 'sweetalert2';
import { selectCat } from '../../../../redux/actions/CategoryManagementAction';

import withReactContent from 'sweetalert2-react-content';
import '../../../../css/CategoryManagement/updateQuestions.css';
import { AddSubCategory } from './AddSubCategory';
const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const MySwal = withReactContent(Swal);

export const UpdateQuestions = ({ setIsSCedit }) => {
  //debugger;
  const { catId } = useParams();
  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();
  let { selectedCat, params } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);

  let [selectedSubcat, setSelectedSubcat] = useState('');
  const [formFlag1, setFormFlag1] = useState(false);
  const [subCatOpen, setSubCatOpen] = useState(false);

  const columns = [{ title: 'Categories Name', field: 'name' },
  { title: 'Actions', field: '_id',render: (rowData) => {
    return (<div>
    <EditIcon   
        onClick = {()=>{
          setSelectedSubcat(rowData);
        setIsSCedit('active');
        //dispatch(selectCat(rowData));
        setFormFlag1(true);
        }
        } 
        style={{cursor:"pointer"}}
      />
      <DeleteIcon   
        onClick = {()=>{
          handleDelete(rowData._id);
        }
        } 
        style={{cursor:"pointer",marginLeft:'20px'}}
      />
      </div>)
    },
    width: "5%"
  }
];
  useEffect(() => {
    http.post(`${rootUrl}/get-profession-by-id`, { id: catId }).then(res => {
      //console.log('----------', res.data.data[0]);
      dispatch(selectCat(res.data.data[0]));
    });
  }, []);
  let handleSubCatQnChange = newQuestion => {
    let objIndex = selectedSubcat.questions.findIndex(obj => obj._id == newQuestion._id);
    selectedSubcat.questions[objIndex].newQuestion = newQuestion.newQuestion;
    selectedSubcat.questions[objIndex].question = newQuestion.question;
    selectedSubcat.questions[objIndex].questionType = newQuestion.questionType;

    selectedSubcat.questions[objIndex].choices = newQuestion.choices;
    setSelectedSubcat({ ...selectedSubcat });
    console.log(selectedSubcat);
  };
  let addNewQa = () => {
    //let _id = selectedSubcat.questions.length + 1;
    let newid;
    if (selectedSubcat.questions.length > 0) {
      newid = selectedSubcat.questions.reduce((prev, current) => (prev._id > current._id ? prev._id + 1 : current._id + 1));
    } else {
      newid = 1;
    }
    let newObj = { _id: newid, question: '', questionType: '', choices: [] };
    selectedSubcat.questions.push(newObj);
    setSelectedSubcat({ ...selectedSubcat });

    //console.log(selectedSubcat);
  };
  let removeQa = key => {
    MySwal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
    }).then(result => {
      if (result.value) {
        selectedSubcat.questions.splice(key, 1);
        setSelectedSubcat({ ...selectedSubcat });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        MySwal.fire('Cancelled', 'Your Question is safe :)', 'success');
      }
    });
    // const newid = selectedSubcat.questions.reduce((prev, current) =>
    //   prev._id > current._id ? prev._id + 1 : current._id + 1,
    // );

    //window.location.reload();

    //history.push(`/category-management-edit/${catId}`);
    //return <Redirect to="/target" />;
    // console.log('removeQa', selectedSubcat);
  };
  let handleSubCatNameChange = value => {
    selectedSubcat.name = value;
    setSelectedSubcat({ ...selectedSubcat });
    //console.log(selectedSubcat);
  };

  const handleSubmit = () => {
    console.log(selectedSubcat);
    ///update-profession-questions
    http.post(`${rootUrl}/update-profession-questions`, { selectedSubcat, selectedCat }).then(res => {
      sweetAlerts('Profession Updated Successfully', 'success');
      setFormFlag1(false);
      window.location.reload();
    });
    

    // history.push(`/category-management-edit/${catId}`);
  };
  const handleDelete = id => {
    //console.log('id=', id);
    ///update-profession-questions
    let key = selectedCat.categories.findIndex(item => item._id == id);
    //console.log('kay=', kay);
    selectedCat.categories.splice(key, 1);
    let clone = JSON.parse(JSON.stringify(selectedCat));
    dispatch(selectCat({ ...clone }));
    console.log(selectedCat);
    http.post(`${rootUrl}/update-profession-categorys`, { selectedCat }).then(res => {
      sweetAlerts('SubCategory deleted successfully', 'success');
      setTimeout(()=>{
        window.location.reload();
      },1000)

      //history.replace(`/category-management-edit/${catId}`);
      //handleClose();
    });
  };
  const addSubCat = () => {
    setSubCatOpen(true);
  };
  let actions = [
    {
      icon: DeleteIcon,
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        console.log(rowData);
        handleDelete(rowData._id);
        // //dispatch(selectCat(rowData));
        //setFormFlag1(true);
      },
    },
    // {
    //   icon: EditIcon,
    //   tooltip: 'Edit',
    //   onClick: (event, rowData) => {
    //     setSelectedSubcat(rowData);
    //     setIsSCedit('active');
    //     //dispatch(selectCat(rowData));
    //     setFormFlag1(true);
    //   },
    // },
  ];
  return (
    <div>
      {subCatOpen && <AddSubCategory subCatOpen={subCatOpen} setSubCatOpen={setSubCatOpen} />}
      {formFlag1 && (
        <Card className={'mt-3 p-3'}>
          <CmtCardHeader>
            <h4>Edit Subcategory</h4>
          </CmtCardHeader>

          <div className={'ml-6'}>
            <TextField
              id="outlined-search"
              onChange={e => {
                handleSubCatNameChange(e.target.value);
              }}
              fullWidth
              defaultValue={selectedSubcat?.name}
              //value={data.price?data.price:selectedCat?.price}
              name={'Subcategory'}
              label="Subcategory Name"
              variant="outlined"
            />
            {selectedSubcat.questions?.length > 0 &&
              selectedSubcat.questions?.map((question, index) => {
                return (
                  <Question
                    key={index}
                    index={index}
                    handleChange={handleSubCatQnChange}
                    question={question}
                    removeQa={removeQa}
                  />
                );
              })}
            <Button
              style={{ float: 'right', marginTop: '10px' }}
              variant="contained"
              color="primary"
              onClick={e => addNewQa(e)}>
              <AddCircleOutlineIcon />
              &nbsp; Add Question
            </Button>
            <div className={'d-flex justify-content-center p3 mt-3'}>
              <Button variant="contained" color="primary" onClick={e => handleSubmit(e)}>
                <BackupOutlinedIcon />
                &nbsp;
                <IntlMessages id="appModule.submit" />
              </Button>
              <Button
                variant="contained"
                className={'cancel'}
                onClick={() => {
                  setFormFlag1(false);
                  setIsSCedit('');
                }}>
                <CloseOutlinedIcon />
                &nbsp;
                <IntlMessages id="appModule.cancel" />
              </Button>
            </div>
          </div>
        </Card>
      )}

      {!formFlag1 && (
        <Card className={'mt-3 p-3'}>
          <CmtCardHeader>
            <h4>Edit Subcategory</h4>
            <Button className="addSubCatBtn" variant="contained" color="primary" onClick={e => addSubCat(e)}>
              <AddCircleOutlineIcon />
              &nbsp; Add Subcategory
            </Button>
          </CmtCardHeader>

          {selectedCat?.categories?.length > 0 && (
            <ApmTable
              columns={columns}
              data={selectedCat.categories}
              title="All Category"
              actions={actions}
              name={'Category'}
              params={params}
              paging={false}
              // updateHandler={updateUserHandler}
              // paramsHandler={paramsHandler}
              // deleteHandler={deleteHandler}
            />
          )}
        </Card>
      )}
    </div>
  );
};
