import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import ApmTable from '../../../../appmakers/components/ApmTableWithoutEdit';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import {
  changeParams,
  getCosts,
  updateCost,
  deleteCost,
  selectCost,
  toggleModal,
  setLocationEdit,
  selectDifLocation,
} from '../../../../redux/actions/CostManagementAction';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import searchLocations from '../../../../api-handling/fetchLocations';
import EditIcon from '@material-ui/icons/Edit';
import { UpdateCost } from './UpdateCost';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { deleteMultipleCost } from '../../../../redux/actions/CostManagementAction';

// get query parameter string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

export const ShowCostWithLocation = () => {
  const dispatch = useDispatch();
  let { allcost, params } = useSelector(({ CostManagementReducer }) => CostManagementReducer);
  let { loading } = useSelector(({ common }) => common);
  const [locations, setLocation] = useState([]);
  const [address, setAddress] = useState('');

  useEffect(() => {
    dispatch(getCosts(params));
  }, [params.page, params.perPage]);

  let handleLocationChange = async value => {
    // alert(value)
    if (value.length > 2) {
      //alert(value)
      await searchLocations(value).then(res => {
        console.log(res);
        if (res.length) {
          setLocation(res);
        }
      });
    }
  };
  const updateUserHandler = data => {
    dispatch(updateCost(data));
    dispatch(getCosts(params));
  };
  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteHandler = data => {
    dispatch(deleteCost(data));
    dispatch(getCosts(params));
  };

  const MultipleDeletehandler = data => {
    dispatch(deleteMultipleCost(data));
    dispatch(getCosts(params));
  };

  const classes = useStyles();
  console.log(allcost);
  const columns = [
    { title: 'Profession', field: 'categoryId.name' },
    { title: 'Locations', field: 'location' },
    { title: 'Cost In $', field: 'credits', type: 'numeric' },
  ];

  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let actions = [
    {
      icon: DeleteOutline,
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        MultipleDeletehandler(rowData);
      },
    },
  ];

  let permission = permissions['User Roles'];

  return (
    <div>
      <UpdateCost />
      {!loading && (
        <ApmTable
          columns={columns}
          data={allcost}
          title="All Professions  Cost"
          params={params}
          actions={actions}
          name={'User Roles'}
          updateHandler={updateUserHandler}
          paramsHandler={paramsHandler}
          deleteHandler={deleteHandler}
        />
      )}
    </div>
  );
};
