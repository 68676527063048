import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import DragDropField from '../../../../appmakers/components/DragDropField';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import sweetAlertsApm from '../../../../appmakers/components/SweetAlertHelper';
import withReactContent from 'sweetalert2-react-content';
import { bulkUploadRecords } from '../../../../redux/actions/BulkUploadActions';
import { fetchSuccess } from "../../../../redux/actions/Common";

import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { socketUrl } from '../../../../api-handling/rootUrl';
import { MySwal, sweetAlerts } from '../../../../helpers/Swal';
import CmtProgressBar from '../../../../@coremat/CmtProgressBar';
import { useSelector } from "react-redux";

const socket = io(socketUrl, {
  transports: ['websocket'],
});

socket.on("connect", () => {
  console.log('Socket connected', socket.id);
})

socket.on("connect_error", (e) => {
  console.log('socket connect_error=', e);
})


const BulkUpload = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);

  const [progressBar, setProgressBar] = useState({
    value: 0,
    total: 0,
  });
  const bulkUploadHandler = file => {
    MySwal.fire({
      icon: 'info',
      html: '<p>Are you sure you want to upload this file?</p>',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: 'yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'no',
    }).then(result => {
      if (result.value) {
        dispatch(bulkUploadRecords(file));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sweetAlerts('info', 'File upload cancelled');
      }
    });
  };

  useEffect(() => {
    socket.on('bulk-upload', data => {
      dispatch(fetchSuccess())
      if (data.id == authUser._id) {
        console.log('bulk-upload-socket')
        const variantString = () => {
          if (data.inserted <= 0) {
            return 'error';
          } else {
            return 'success';
          }
        };
        console.log(variantString());
        sweetAlertsApm(
          <ul>
            <li>Total Records in file {data.totalRecords}.</li>
            <li>Inserted Records {data.inserted}.</li>
            <li>Existing records is {data.alreadyExist}.</li>
            <li style={{ color: 'red' }}>Failed records {data.failed}.</li>
          </ul>,
          variantString(),
        );
        setTimeout(() => {
          history.push('/dashboard');
        }, 4000);

      }
    }
    );
    socket.on('user-process', data => {
      if (data.id == authUser._id) {
        setProgressBar(data.data);
      }
    });

  }, []);

  return (
    <div className="mt-5" style={{ paddingLeft: '10%', paddingRight: '10%' }}>
      {progressBar.total > 0 && (
        <CmtProgressBar
          onlyContained={false}
          thickness={12}
          pointer={false}
          pointerSize={8}
          label="Uploading, Please wait…"
          labelPos="top-left"
          hideValue={false}
          valuePos="right"
          value={progressBar.value}
          total={progressBar.total}
          renderValue={(value, total) => {
            return value + '/' + total;
          }}
          emptyColor={'#e9ecef'}
          containedColor="#1a90ff"
        />
      )}
      <div>
        <Typography variant="h4" className="text-gray-700 mb-4 text-center">
          Upload file for bulk upload
        </Typography>
        <DragDropField onChangeHandler={bulkUploadHandler} acceptedTypes=".csv" />
      </div>
    </div>
  );
};

export default BulkUpload;
