import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Paper } from '@material-ui/core';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import ApmTable from '../../../../appmakers/components/ApmTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeParams,
  deleteMultipleUserRoles,
  deleteUserRole,
  getUserRolesPagination,
  selectUserRole,
  updateUserRole,
} from '../../../../redux/actions/UserRoleActions';
import { ToggleOpenModal } from '../../../../redux/actions/RoleManagement';
import { UserRoleReducer } from '../../../../redux/reducers/UserRoleReducer';
import { CreateUserRole } from './create';
import { Management } from './management';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { setProfession } from 'redux/actions/CostManagementAction';

// get query parameter string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));
export const UserRolesPage = () => {
  const dispatch = useDispatch();
  let { userRoles, params } = useSelector(({ UserRoleReducer }) => UserRoleReducer);
  let { loading } = useSelector(({ common }) => common);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [value, setValue] = useState(1);

  useEffect(() => {
    dispatch(getUserRolesPagination(params));
  }, [params.page, params.perPage]);

  const updateUserHandler = user => {
    dispatch(updateUserRole(user));
  };
  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteUserRoleHandler = data => {
    dispatch(deleteUserRole(data._id));
    dispatch(getUserRolesPagination(params));
  };

  const deleteMultipleHandler = roles => {
    const ids = roles.map(role => role._id);
    console.log('ids', ids);
    dispatch(deleteMultipleUserRoles(ids));
    setTimeout(() => {
      dispatch(getUserRolesPagination(params));
    }, 2000);
  };
  const classes = useStyles();
  const columns = [{ title: 'Title', field: 'title' }];
  let actions = [];

  if (permissions && permissions['User Roles'] && permissions['User Roles'].includes('edit')) {
    actions = [
      {
        icon: SystemUpdateAltIcon,
        tooltip: 'Update Actions',
        onClick: (event, rowData) => {
          dispatch(selectUserRole(rowData));
          dispatch(ToggleOpenModal(true));
        },
      },
      {
        icon: DeleteOutline,
        tooltip: 'Delete',
        onClick: (event, rowData) => {
          deleteMultipleHandler(rowData);
        },
      },
    ];
  }

  return (
    <Grid container spacing={3} className="justify-center">
      <Grid item md={12} sm={12} xs={12}>
        {permissions['User Roles'] && permissions['User Roles'].includes('create') && <CreateUserRole />}
        {!loading && (
          <ApmTable
            columns={columns}
            data={userRoles}
            title="Roles"
            params={params}
            updateHandler={updateUserHandler}
            paramsHandler={paramsHandler}
            actions={actions}
            name={'User Roles'}
            deleteHandler={deleteUserRoleHandler}
          />
        )}
      </Grid>
    </Grid>
  );
};
