import React, { useState, useEffect } from 'react';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import CmtMediaObject from '../../../../../@coremat/CmtMediaObject';
import { Rating } from '@material-ui/lab';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { http } from '../../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../../api-handling/rootUrl';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getChatUsers, getContactUsers, onUserSelect } from '../../../../../redux/actions/Chat';
import { selectUser } from '../../../../../redux/actions/UserManagementActions';
const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quotesContent: {
    marginTop: '1vh',
    marginLeft: '2vh',
  },
}));

const UserDetails = ({ user }) => {
  // console.log("quotes",quotes)
  // console.log("jobId",jobId)
  // console.log("postedBy",postedBy)
  let [details, setDetails] = useState(user);
  // console.log('item=',user)
  const classes = useStyles();
  let history = useHistory();
  const dispatch = useDispatch();

  return (
    <Grid sm={12} md={12} lg={12}>
      <CmtCard>
        <h4 style={{ padding: '12px' }}>Posted By User</h4>
        <CmtCardContent className={`${classes.content} text-center `} style={{ marginTop: '-2rem' }}>
          <CmtMediaObject avatar={user.imageUrl ? user.imageUrl : '/broken-image.jpg'} avatarPos="center"></CmtMediaObject>
          <h5 className={classes.quotesContent}>{user.userName}</h5>
          <div className={'d-flex'}>
            {user.mobile && (
              <h6 className={classes.quotesContent}>
                <CallRoundedIcon /> {user.mobile}{' '}
              </h6>
            )}
            {!user.mobile && user.phone && (
              <h6 className={classes.quotesContent}>
                <CallRoundedIcon /> {user.phone}{' '}
              </h6>
            )}
            {user.email && (
              <h6 className={classes.quotesContent}>
                <EmailRoundedIcon /> {user.email}{' '}
              </h6>
            )}
          </div>
          <Button
            className={classes.quotesContent}
            variant="contained"
            color="primary"
            onClick={() => {
              dispatch(selectUser(details));

              history.push({
                pathname: '/user-details',
                state: details, // your data array of objects
              });
            }}>
            View Details
          </Button>
          {/* <Button className={classes.quotesContent} variant="contained" color="primary" onClick={()=>{openChat(item._id,jobId,postedBy)}}>
                    View Response
                  </Button> */}
        </CmtCardContent>
      </CmtCard>
    </Grid>
  );
};

export default UserDetails;
