import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Badge from '@material-ui/core/Badge';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { shadows } from '@material-ui/system';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';

const monthStaLead = {
  '@media (max-width: 600px)': {
    backgroundColor: 'white',
    margin: '12px 0px 0px 0px',
    float: 'left',
    boxShadow: '5px 7px 19px grey',
  },
  '@media (min-width: 601px)': {
    backgroundColor: 'white',
    margin: '12px 0px 0px 0px',
    float: 'left',
    boxShadow: '5px 7px 19px grey',
  },
};
export const MonthsStatisticsLead = () => {
  const getLeads = () => {
    http
      .get(`${rootUrl}/get-monthly-summary-leads`)
      .then(res => {
        setLeads(res.data.data);
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    getLeads();
  }, []);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [leads, setLeads] = useState([]);
  let history = useHistory();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var d = new Date(); //d.getMonth()
  var months = monthNames.slice(0, d.getMonth() + 1);
  const handleClick = (d, index) => {
    if (!permissions['User Management']) return;
    history.push('/view-leads-details-by-month/' + (d.getMonth() - index + 1));
  };
  return (
    <StyleRoot>
      <div style={monthStaLead}>
        <Card boxShadow={5}>
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
              <h6 style={{ color: 'black', fontSize: '15px' }}> Months Statistics: Lead </h6>
            </Typography>
            <Divider />
            {leads &&
              months.map((job, index) => {
                var c = leads.filter(function (el) {
                  return el._id.month == d.getMonth() - index + 1;
                });
                //console.log(c[0]?.Count.month?c[0]._id.month:0)
                return (
                  <Typography variant="h5" component="h2" style={{ marginTop: '13px' }}>
                    <Badge badgeContent={c[0]?.Count ? c[0].Count : 0} color="primary" showZero>
                      <span
                        style={{
                          width: '120px',
                          backgroundColor: '#eaeaf1',
                          height: '24px',
                          textAlign: 'left',
                          padding: '4px',
                          borderRadius: '8px',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          color: '#667eea',
                        }}
                        onClick={() => handleClick(d, index)}>
                        {/* onClick={()=>{history.push('/view-leads-details/'+job.categoryId)}} */}
                        {monthNames[d.getMonth() - index]}
                      </span>
                    </Badge>
                  </Typography>
                );
              })}
            <Divider style={{ marginTop: '10px' }} />
            {/* <Button
                        variant="contained"
                        color="primary"
                        endIcon={<VisibilityIcon />}
                        style={{margin: '10px 7px',float: 'right',borderRadius: '20px',height: '25px',}}
                        onClick={()=>{history.push('/view-all-leads-by-location')}}
                    >
                        View All
                    </Button> */}
          </CardContent>
          <CardActions>
            <Button size="small"></Button>
          </CardActions>
        </Card>
      </div>
    </StyleRoot>
  );
};
export default MonthsStatisticsLead;
