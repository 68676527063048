import React, { Component } from 'react'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { uploadFile } from 'react-s3';
import { Avatar, Typography, Grid } from "@material-ui/core";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from "@material-ui/core";
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../../api-handling/rootUrl";
import sweetAlerts from "../../../../../appmakers/components/SweetAlertHelper";
import { fetchAndUpdateAuthUser } from '../../../../../redux/actions/Auth';
import Swal from 'sweetalert2';

class PicUploadNoCrop extends Component {

    constructor() {
        super()
        this.state = {
            src: null,
            isDesabled: false,
            crop: {
                unit: "%",
                width: 30,
                aspect: 1 / 1
            },
            croppedImageUrl: null,
        }
    }

    handleFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            this.setState({ src: fileReader.result })
        }
        fileReader.readAsDataURL(e.target.files[0])
    }

    handleSubmit = e => {
        e.preventDefault()
        this.setState({ isDesabled: true })
        //const user = this.props.currentUser
        let user = JSON.parse(localStorage.getItem('adminUser'));
        var form = new FormData();
        form.append('id', user._id);
        form.append('newFile', this.state.croppedImage);
        form.append('newFileName', this.state.croppedImage.name);
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        http.patch(`${rootUrl}/update-profile-image`, form, config).then(res => {
            if (res) {
                this.props.dispatch(fetchAndUpdateAuthUser())
                if (res.data.responseCode != "200") {
                    sweetAlerts(res.data.message, 'error')
                    this.setState({ isDesabled: false })
                } else {
                    sweetAlerts(res.data.message, 'success')
                    this.setState({ isDesabled: false })
                    this.props.handleClose()
                }

            }
        }).catch(error => {
            sweetAlerts(error, 'error')
            this.setState({ isDesabled: false })
        })

    }
    onImageLoaded = image => {
        this.imageRef = image
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    }

    onCropComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = this.getCroppedImg(this.imageRef, crop)
            this.setState({ croppedImageUrl })
        }
    }

    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        )

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                let user = JSON.parse(localStorage.getItem('adminUser'));
                let user_id = user._id;
                this.dataURLtoFile(reader.result, 'adminProfilePicture/' + user_id + '.jpg')
            }
        })
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImage: croppedImage })
    }
    close() {
        this.props.handleClose()
    }
    removePic() {
        Swal.fire({
            title: 'Are you sure?',
            text: `You want to remove your profile pic.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'rgb(102, 126, 234)',
            cancelButtonColor: '#99999987',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ isDesabled: true })
                http.patch(`${rootUrl}/remove-profile-image`, { id: this.props.authUser._id, newFileName: this.props.authUser.imageUrl }).then(res => {
                    if (res) {
                        this.props.dispatch(fetchAndUpdateAuthUser())
                        if (res.data.responseCode != "200") {
                            //this.props.handelProfileedit()
                            Swal.fire(
                                'Error !',
                                `${res.data.message}`,
                                'error'
                            )
                            this.setState({ isDesabled: false })

                        } else {
                            Swal.fire(
                                'Success !',
                                `${res.data.message}`,
                                'success'
                            )
                            this.setState({ isDesabled: false })
                            this.props.handleClose();
                        }
                    }
                }).catch(error => {
                    this.setState({ isDesabled: false })
                    //this.props.handelProfileedit()
                    sweetAlerts(error, 'error')
                })
            } else {
                // this.props.handelProfileedit()
            }
        })
    }

    render() {

        const { crop, profile_pic, src, isDesabled } = this.state

        return (
            <div style={{ textAlign: "center" }}>
                <h3 style={{ textAlign: 'center' }}>Edit Profile Picture</h3>
                <hr />
                {!src && this.props.authUser?.imageUrl &&
                    <div >
                        <Avatar style={{ margin: 'auto', marginBottom: '10px', width: '200px', height: '200px' }} variant="square" alt={this.props.authUser.name} src={this.props.authUser?.imageUrl ? `${this.props.authUser?.imageUrl}?${new Date()}` : `/broken-image.jpg`} />
                    </div>
                }
                <Form onSubmit={this.handleSubmit} encType="multipart/form-data">
                    <label htmlFor="profile_pic" style={{ border: '1px solid', padding: '3px' }}>Update Picture</label>
                    <input type='file' name="profileImage" id='profile_pic' style={{ display: 'none' }} value={profile_pic}
                        onChange={this.handleFile} />

                    {src && (
                        <div>
                            <hr />
                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </div>
                    )}
                    <hr />
                    {!src && this.props.authUser?.imageUrl && <div className='m-3'>
                        {!src && this.props.authUser?.imageUrl && <Button style={{ marginLeft: '5px' }} variant="contained" type="button" disabled={(isDesabled) ? true : false} onClick={() => { this.removePic() }}>Remove Picture</Button>}
                    </div>}
                    <div>
                        <Button style={{ marginRight: '5px' }} variant="contained" type="button" onClick={() => { this.close() }}>cancel</Button>
                        <Button style={{ marginLeft: '5px' }} variant="contained" type="submit" disabled={(!src || isDesabled) ? true : false} >save</Button>
                    </div>
                </Form>
            </div>
        )
    }

}

const mapSTP = state => {
    return { currentUser: state.currentUser }
}
export default connect(mapSTP)(PicUploadNoCrop)
