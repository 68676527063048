import { SET_MENU, OPEN_MODAL } from '../constants/RoleManagementActionTypes';

const initialState = {
  menu: [],
  permissions: [],
  actions: [],
  params: {
    page: 0,
    perPage: 5,
    total: 40,
  },
  users: [],
  showRolesData: false,
};

export const RoleManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MENU: {
      return {
        ...state,
        menu: payload.menu,
        actions: payload.actions,
        permissions: payload.permissions,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        showRolesData: payload,
      };
    }
    default: {
      return state;
    }
  }
};
