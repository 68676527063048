import React from "react";
import {Typography} from "@material-ui/core";
import {Facebook, Instagram, LinkedIn, Pinterest, Twitter, YouTube} from "@material-ui/icons";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";

const ContactInfo = ({user}) => {
    const contactClicked = () => {
        http.post(`${rootUrl}/contact-clicked`, {
            userId: user?._id,
        }).then().catch();
    }
    return (
        <div className="d-flex flex-col ml-4 align-content-between pt-2 text-gray-800">
            <Typography component="h4" variant="h4">Business contact details</Typography>
            <div className="mt-3 d-flex flex-col justify-content-start align-items-start">
            <span className="text-gray-700 social-links-overflow" style={{fontWeight: 'normal'}}>Email :
                    <span style={{color: 'rgb(0, 145, 234)', cursor: 'pointer'}} onClick={() => contactClicked()}>
                        {user?.email ? user?.email : 'N/A'}
                    </span>
                </span>
                <span className="text-gray-700 social-links-overflow" style={{fontWeight: 'normal'}}>Mobile :
                    <span style={{color: 'rgb(0, 145, 234)', cursor: 'pointer'}} onClick={() => contactClicked()}>
                        {user?.mobile ? user?.mobile : 'N/A'}
                    </span>
                </span>
                <span className="text-gray-700 social-links-overflow" style={{fontWeight: 'normal'}}>Website :
                    {user?.website?.length > 0 &&
                    <span style={{color: 'rgb(0, 145, 234)', cursor: 'pointer'}}
                          onClick={() => window.open(`${user?.website}`,'_blank')}>
                        {user?.website}
                    </span>
                    }
                    {user?.website?.length === 0 &&
                    <span>
                        N/A
                    </span>
                    }
                </span>
                <span className="text-gray-700" style={{
                    fontWeight: 'normal', display: 'block',
                    maxWidth: '98%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'
                }}>Address  :
                    <span style={{color: 'rgb(0, 145, 234)'}}>
                        {user?.address ? user?.address : 'N/A'}
                    </span>
                </span>
                <span className="text-gray-700 social-links-overflow" style={{fontWeight: 'normal'}}>Landline : <span
                    style={{color: 'rgb(0, 145, 234)'}}>{user?.landline ? user?.landline : 'N/A'}</span></span>
            </div>
            <div className="d-flex flex-row w-1/2 justify-content-between ml-4 align-items-center mt-3">
                {user?.instagram?.length > 0 &&
                <a href={user?.instagram} target="_blank">
                    <Instagram style={{color:'#8a3ab9'}}/>
                </a>
                }
                {user?.facebook?.length > 0 &&
                <a href={user?.facebook} target="_blank">
                    <Facebook style={{color: '#4267B2'}}/>
                </a>
                }
                {user?.linkedin?.length > 0 &&
                <a href={user?.linkedin} target="_blank">
                    <LinkedIn style={{color: '#0077b5'}}/>
                </a>
                }
                {user?.twitter?.length > 0 &&
                <a href={user?.twitter} target="_blank">
                    <Twitter style={{color: '#1DA1F2'}}/>
                </a>
                }
                {user?.pinterest?.length > 0 &&
                <a href={user?.pinterest} target="_blank">
                    <Pinterest style={{color: '#E60023'}}/>
                </a>
                }
                {user?.youtube?.length > 0 &&
                <a href={user?.youtube} target="_blank">
                    <YouTube style={{color: '#FF0000'}}/>
                </a>
                }
            </div>
        </div>
    )
}
export default ContactInfo;
