import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import {
  ADD_USER_ROLE,
  CHANGE_PARAMS,
  GET_ROLES_PAGINATION,
  SELECT_USER_ROLE,
  GET_PAGE_ACTIONS,
  SET_PAGE_ACTIONS,
  GET_ROLES,
} from '../constants/UserRoleActionTypes';
import sweetAlerts from '../../../src/appmakers/components/SweetAlertHelper';
import { store } from 'App';

export const getAllRolesSuccess = payload => {
  return {
    type: GET_ROLES_PAGINATION,
    payload,
  };
};
export const getRoles = payload => {
  return {
    type: GET_ROLES,
    payload,
  };
};

export const addUserRole = payload => {
  return {
    type: ADD_USER_ROLE,
    payload,
  };
};

export const setPageActions = payload => {
  return {
    type: SET_PAGE_ACTIONS,
    payload,
  };
};

export const selectUserRole = payload => {
  return {
    type: SELECT_USER_ROLE,
    payload,
  };
};

export const getUserRolesPagination = params => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/user-roles`, { params })
      .then(response => {
        dispatch(getAllRolesSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getUserRoles = () => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/get-all-user-roles`, {})
      .then(response => {
        dispatch(getRoles(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts(error.data, 'Error');
        dispatch(fetchError());
      });
  };
};

export const updateUserRoleActions = (_id, actions, dashboard, bulkUpload, activityLog, complaint) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .put(`${rootUrl}/update-user-role`, { actions, dashboard, bulkUpload, activityLog, complaint, roleId: _id })
      .then(response => {
        sweetAlerts('Role updated successfully', 'success');
        const { params } = store.getState().UserRoleReducer;
        dispatch(getUserRolesPagination(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};

export const getAllPageActions = () => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/page-actions`, {})
      .then(response => {
        dispatch(setPageActions(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts(error.data, 'Error');
        dispatch(fetchError());
      });
  };
};

export const changeParams = (key, value) => {
  return {
    type: CHANGE_PARAMS,
    payload: { key, value },
  };
};

export const updateUserRole = userRole => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/update-user-role`, { userRole })
      .then(response => {
        sweetAlerts(response.data, 'success');
        const { params } = store.getState().UserRoleReducer;
        dispatch(getUserRolesPagination(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
        sweetAlerts(error.data, 'error');
      });
  };
};

export const createUserRole = title => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/user-roles`, { title })
      .then(response => {
        dispatch(addUserRole(response.data));
        sweetAlerts('User Role created successfully', 'success');
        const { params } = store.getState().UserRoleReducer;
        dispatch(getUserRolesPagination(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
        sweetAlerts(error.data, 'error');
      });
  };
};

export const deleteUserRole = roleId => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .delete(`${rootUrl}/user-roles/${roleId}`)
      .then(response => {
        dispatch(getUserRoles());
        sweetAlerts(response.data, 'success');
        const { params } = store.getState().UserRoleReducer;
        dispatch(getUserRolesPagination(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
        const { params } = store.getState().UserRoleReducer;
        dispatch(getUserRolesPagination(params));
        dispatch(fetchError());
      });
  };
};

export const deleteMultipleUserRoles = roleIds => {
  console.log('roleIds', roleIds);
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/user-roles/delete`, roleIds)
      .then(response => {
        dispatch(getUserRoles());
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};
