import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import { CHANGE_ADMIN_PARAMS, GET_ALL_ADMIN_SUCCESS, TOGGLE_MODAL, SELECT_ADMIN } from '../constants/AdminActionTypes';
import sweetAlerts from '../../../src/appmakers/components/SweetAlertHelper';
import { store } from 'App';

export const getAllAdminsSuccess = payload => {
  return {
    type: GET_ALL_ADMIN_SUCCESS,
    payload,
  };
};

export const getAdmins = params => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/admin/admin-users`, { params })
      .then(response => {
        dispatch(getAllAdminsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAllUsersByDate = (firstdate, lastdate, params) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/admin/users-by-date`, { firstdate, lastdate, params })
      .then(response => {
        dispatch(getAllAdminsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAllUsersByDateType = (firstdate, lastdate, usertype, params) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/admin/users-by-datetype`, { firstdate, lastdate, usertype, params })
      .then(response => {
        dispatch(getAllAdminsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const changeParams = (key, value) => {
  return {
    type: CHANGE_ADMIN_PARAMS,
    payload: { key, value },
  };
};

export const toggleModal = value => {
  return {
    type: TOGGLE_MODAL,
    payload: value,
  };
};

export const selectAdmin = value => {
  return {
    type: SELECT_ADMIN,
    payload: value,
  };
};

export const updateAdmin = admin => {
  console.log('admin', admin);
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/admin/update-admin`, { admin })
      .then(response => {
        sweetAlerts('Updated successfully', 'success');
        dispatch(fetchSuccess());
        const { params } = store.getState().AdminReducer;
        dispatch(getAdmins(params));
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const deleteAdmin = adminId => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .delete(`${rootUrl}/admin/delete-admin/${adminId}`)
      .then(response => {
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        const { params } = store.getState().AdminReducer;
        dispatch(getAdmins(params));
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};

export const deleteMultipleAdmin = adminIds => {
  console.log('adminIds', adminIds);
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/admin/delete-admin`, adminIds)
      .then(response => {
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        const { params } = store.getState().AdminReducer;
        dispatch(getAdmins(params));
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};
