import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
}));

export default function TextInputField({onChange, value}) {
    const classes = useStyles();

    const handleInputChange = event => {
        onChange(event.target.value)
    };
    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" variant="outlined" onChange={handleInputChange}
                       value={value} size="small"/>
        </form>
    );
}
