import React, { useEffect, useState } from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu } from '../../../../../redux/actions/RoleManagement';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import CategoryIcon from '@mui/icons-material/Category';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const dispatch = useDispatch();
  const { menu } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [navigationMenus, setNavigationMenus] = useState([]);
  useEffect(() => {
    if (!menu || !menu.length) dispatch(getMenu());
    else {
      setNavigationMenus(
        menu.map(item => {
          let icon;
          if (item.name == "Bulk Upload") {
            icon = <UploadFileIcon />
          } else if (item.name == "Dashboard") {
            icon = <HomeIcon />
          } else if (item.name == "User Management") {
            icon = <GroupIcon />
          } else if (item.name == "Category Management") {
            icon = <CategoryIcon />
          } else if (item.name == "User Roles") {
            icon = <RecentActorsIcon />
          } else if (item.name == "Cost Management") {
            icon = <PriceChangeIcon />
          } else if (item.name == "Activity Logs") {
            icon = <ReceiptLongIcon />
          } else if (item.name == "Complaint") {
            icon = <WarningAmberIcon />
          }
          return {
            name: <IntlMessages id={item.title} />,
            type: 'item',
            icon,
            link: item.link,
          };
        }),
      );
    }
  }, [menu]);
  const classes = useStyles();

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
