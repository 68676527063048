import React, {useEffect, useState} from 'react';
import {Button} from "@material-ui/core";
import {Modal} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {
    selectQuestion,
    setIndex, nextOrSubmitHandle, skipHandler, setKey, setQuestions
} from "../../../../redux/actions/JobActions";

export const JobModalFooter = ({professionModalHandler=()=>{}, getMyJobs=()=>{}}) => {
    const dispatch = useDispatch();
    const {
        questions,
        selectedQuestion,
        index,
        others, validPhone
    } = useSelector(({JobReducer}) => JobReducer);
    const {authUser} = useSelector(({auth}) => auth);
    const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");

    const [nextBtnText, setNextBtnText] = useState('Next')
    const [isDisabledNext, setIsDisabled] = useState(false)
    const removeQuestion = (allQuestions, removePushedQuestions) => {
        return allQuestions.filter(question => !removePushedQuestions.includes(question.pushedQuestion))
    }
    const handleBack = () => {
        if (index <= questions.length && questions[index - 1]) {
            let objQuestions = [...questions]
            if (questions[index - 1].pushedQuestion === 'Email') {
                objQuestions = objQuestions.flat();
                objQuestions = removeQuestion(objQuestions, ['Password', 'Phone', 'Phone Recaptcha', 'Phone OTP', 'Posted By', 'Confirm Password'])
                dispatch(setKey({key: 'emailRequest', value: false}))
                dispatch(setQuestions(objQuestions))
                dispatch(setKey({key: 'isPhoneVerified', value: false}))
            } else if (selectedQuestion.pushedQuestion === 'Phone OTP') {
                dispatch(setKey({key: 'showCode', value: false}))
                dispatch(selectQuestion(objQuestions[index - 2]))
                dispatch(setIndex(index - 2))
                return;
            }
            dispatch(selectQuestion(objQuestions[index - 1]))
            dispatch(setIndex(index - 1))
        }
    }
    const nextBtnTextHandler = () => {
        if (index === questions.length - 1 && selectedQuestion.pushedQuestion !== 'Email' || (validPhone &&
            validPhone === selectedQuestion[`answer_${selectedQuestion.questionType}`])) setNextBtnText("Submit")
        else setNextBtnText("Next")
    }

    const processDisabled = () => {
        if (!selectedQuestion) return setIsDisabled(true);
        if (selectedQuestion.length) {
            selectedQuestion.forEach(item => {
                if (processQuestion(item)) {
                    return setIsDisabled(true)
                } else {
                    return setIsDisabled(false)
                }
            })
        } else {
            return setIsDisabled(processQuestion(selectedQuestion))
        }
    }

    const processQuestion = question => {
        if (question.questionType === 'radio') {
            if (Object.keys(others).includes(question[`answer_${question.questionType}`]) && !question[`answer_${question.questionType}_other`]) {
                return true;
            } else if (!question[`answer_${question.questionType}`]) {
                return true
            }
        } else if (question.pushedQuestion === 'Posted By' && !question[`answer_${question.questionType}`]) {
            return true
        } else if (question.pushedQuestion === 'Confirm Password' && (selectedQuestion[1][`answer_${question.questionType}`] != selectedQuestion[2][`answer_${question.questionType}`])) {
            return true
        } else if (question.pushedQuestion === 'Password' && !question[`answer_${question.questionType}`]) {
            return true
        } else if (!question[`answer_${question.questionType}`] && !['Phone OTP', 'Phone', 'Phone Recaptcha'].includes(question.pushedQuestion)) {
            return true;
        } else if (question.pushedQuestion === 'Email' && (!question[`answer_${question.questionType}`] || !emailRegex.test(question.answer_input))) {
            return true;
        }
        return false;
    }


    useEffect(() => {
        nextBtnTextHandler()
        processDisabled()
    }, [index, selectedQuestion])


    return <>
        <Modal.Footer>
            {selectedQuestion.pushedQuestion === 'Phone' &&
            <div className={"flex-grow-1"}>
                <a href="#" onClick={() => dispatch(skipHandler(authUser))}>
                    Skip
                </a>
            </div>
            }
            <div>
                <Button variant={"contained"}
                        onClick={handleBack}
                        disabled={index === 0}
                        color="secondary">
                    back
                </Button>
                <Button variant={"contained"}
                        onClick={() => dispatch(nextOrSubmitHandle(authUser, getMyJobs))}
                        disabled={isDisabledNext}
                        className={"mx-2"}
                        color="primary">
                    {nextBtnText}
                </Button>
            </div>
        </Modal.Footer>

    </>
}
