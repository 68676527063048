import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { http } from '../../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../../api-handling/rootUrl';
import { StyleRoot } from 'radium';
import sweetAlerts from '../../../../../appmakers/components/SweetAlertHelper';
import countryCodes from '../../../../../constants/countryCode.json'
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  editProfileFormRoot: {
    width: '50%',
    marginLeft: '5%',
    marginTop: '-2rem',
  },
});

const editProfile1 = {
  '@media (max-width: 600px)': {
    width: '85%',
    marginLeft: '6%',
  },
};
const EditProfileForm = ({ setFormFlagHandler }) => {
  const classes = useStyles();
  const { authUser } = useSelector(({ auth }) => auth);
  let uName = authUser.firstName;
  let uphonearr = authUser.mobile?.split("-");
  let uphone = uphonearr ? uphonearr[1] : '';
  let uemail = authUser.email;
  const [cCode, setcCode] = useState(uphonearr ? uphonearr[0] : '');
  const [cCodeObj, setcCodeObj] = useState({});
  let [values, setValues] = useState({
    name: uName,
    email: uemail,
    phone: uphone,
  });
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('adminUser'));
    let userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
    let country;
    let uphonearr;
    let dielCode;
    if (user?.mobile) {
      uphonearr = user?.mobile?.split("-");
      console.log('uphonearr=', uphonearr)
      country = countryCodes.find(element => element.dial_code == uphonearr[0]);
      dielCode = country?.dial_code
    } else {
      country = countryCodes.find(element => element.code == userInfo?.country?.short_name);
      dielCode = country?.dial_code
    }
    setcCode(dielCode)
    setcCodeObj(country)
  }, [])

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const submitFormHandler = () => {
    let id = authUser._id;
    let userName = values.name;
    let email = values.email;
    let phone = values.phone;
    if (!userName) {
      sweetAlerts('User Name is required', 'error');
    } else if (!email) {
      sweetAlerts('Email is required', 'error');
    } else if (!phone || phone.length + 1 == cCode.length) {
      sweetAlerts('Phone Number is required', 'error');
    } else {
      http
        .patch(`${rootUrl}/update-user`, {
          id,
          userName,
          email: email.toLowerCase(),
          phone: cCode + '-' + phone,
        })
        .then(res => {
          if (res) {
            if (res.status != '200') {
              sweetAlerts(res.data.message, 'error');
            } else {
              sweetAlerts(res.data.message, 'success');
              let user = JSON.parse(localStorage.getItem('adminUser'));
              user.userName = res.data.result.userName;
              user.email = res.data.result.email;
              user.phone = res.data.result.mobile;
              localStorage.setItem('adminUser', JSON.stringify(user));
              setFormFlagHandler(false);
            }
            //handleClose()
          }
        })
        .catch(error => {
          sweetAlerts(error.data.message, 'error');
        });
    }
  };

  return (
    <StyleRoot>
      <div className={classes.editProfileFormRoot} style={editProfile1}>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.name" />}
              fullWidth
              onChange={handleChange('name')}
              defaultValue={values.name}
              value={values.name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            // helperText={!values.name ? "This Field is Required" : ""}
            // error={!values.name}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={handleChange('email')}
              defaultValue={values.email}
              value={values.email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '18%' }}>

              <Autocomplete
                value={cCodeObj}
                onChange={(event, newValue) => {
                  setcCode(newValue.dial_code);
                  setcCodeObj(newValue);
                }}
                disableClearable
                getOptionLabel={option => {
                  if (option) return option.dial_code + ' ' + option.code;
                  return '';
                }}
                id="combo-box-demo"
                options={countryCodes}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField
                  {...params}
                  // label="Country code"
                  //InputProps={{ ...params.InputProps, endAdornment : null }}

                  variant="outlined"
                  fullWidth
                  margin="normal"
                  className={classes.textFieldRoot}
                />}
              />
            </div>
            <div>
              <Box mb={2}>
                <TextField
                  label={<IntlMessages id="appModule.phone" />}
                  fullWidth
                  onChange={handleChange('phone')}
                  defaultValue={values.phone}
                  value={values.phone}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
            </div>
          </div>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button variant="contained" color="primary" onClick={() => submitFormHandler()}>
                <IntlMessages id="appModule.submit" />
              </Button>
              &nbsp;
              <Button variant="contained" onClick={() => setFormFlagHandler(false)}>
                <IntlMessages id="appModule.cancel" />
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    </StyleRoot>
  );
};

export default EditProfileForm;
