import React, {useEffect} from 'react';
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Button} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {setCategory, setQuestions, setJobModal, selectQuestion, setKey} from "../../../../redux/actions/JobActions";
import {getDefaultQuestions} from "../../../../constants/defaultQuestions";
import Modal from "./Modal";
import VerifyEmail from "../../Common/JobModal/VerifyEmail";
import {http} from "../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../api-handling/rootUrl";
import sweetAlerts from "../../../../appmakers/components/SweetAlertHelper";

export const JobModal = ({btnLabel, fullWidth=false, professionModalHandler=()=>{}, getMyJobs=()=>{}}) => {
    const dispatch = useDispatch();
    const {
        questions,
        selectedQuestion,
        profession,
        address,
        showEmailModal,
        userInfo, isJobModal
    } = useSelector(({JobReducer}) => JobReducer);
    const {authUser} = useSelector(({auth}) => auth);


    const prepareQuestions = (questions) => {
        if (!authUser) {
            const emailQuestion = {
                _id: 121,
                question: "What is your email?",
                questionType: "input",
                pushedQuestion: "Email",
                choices: [],
            }
            questions.push(emailQuestion)
        } else {
            if (!authUser?.phone) {
                questions.push({
                    _id: 129,
                    question: "Enter your phone number",
                    questionType: "input",
                    answer_input: '',
                    pushedQuestion: 'Phone',
                })
            } else if (authUser?.phone && !authUser?.isPhoneVerified) {
                if (!questions.find(i => i.pushedQuestion === 'Phone Recaptcha'))
                    questions.push({
                        _id: 130,
                        question: `Verify your phone number`,
                        questionType: "phone_recaptcha",
                        answer_phone_recaptcha: authUser?.phone,
                        pushedQuestion: 'Phone Recaptcha',
                    })
            }
        }
        return questions;
    }
    const sendVerificationMail = () => {
        http.post(`${rootUrl}/send-verification-mail`, {
            email: authUser ? authUser.email : userInfo.email
        }).then(res => {
            sweetAlerts('Verification link send successfully', 'success')
            if (userInfo.new === false)
                dispatch(setKey({key: 'showEmailModal', value: false}))
        }).catch(error => {
            sweetAlerts('Something went wrong', 'error')
        })
    };


    useEffect(() => {
        if (!profession && !questions.length) return;
        if (!questions.length && profession.categories) {
            if (profession && profession?.categories?.length === 0) {
                dispatch(setQuestions(prepareQuestions(getDefaultQuestions())));
                dispatch(setCategory({...profession}))
            } else if (profession && profession?.categories.length === 1) {
                dispatch(setCategory({...profession.categories[0], questions: []}))
                dispatch(setQuestions(prepareQuestions(profession.categories[0].questions)))
            } else if (profession && profession?.categories.length > 1) {
                const obj = {
                    _id: -1,
                    question: `What type of ${profession.name} service do you need?`,
                    questionType: "radio",
                    valueKey: 'category_id',
                    choices: profession.categories.map(category => {
                        return {category_id: category._id, option: category.name}
                    }),
                    pushedQuestion: 'Sub category',
                };
                dispatch(setQuestions(prepareQuestions([obj])))
            }
        }
        if (questions.length && (!selectedQuestion.length && !selectedQuestion._id)) {
            dispatch(selectQuestion(questions[0]))
        }
    }, [questions.length, profession])


    return <>
        {!isJobModal &&
        <Button variant="contained" color="primary"
                className={{'mt-3':fullWidth}}
                onClick={() => dispatch(setJobModal(true))}
                disabled={!address || !profession} fullWidth={fullWidth}>
            <IntlMessages id={btnLabel}/>
        </Button>
        }
        {profession && <Modal professionModalHandler={professionModalHandler}
                              getMyJobs={getMyJobs}/>}
        {
            showEmailModal && <VerifyEmail
                sendVerificationMail={sendVerificationMail}/>
        }
    </>
}
