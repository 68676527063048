import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import { GET_MENU, OPEN_MODAL, SET_MENU } from '../constants/RoleManagementActionTypes';

export const setMenu = payload => {
  return {
    type: SET_MENU,
    payload,
  };
};

export const ToggleOpenModal = payload => {
  return {
    type: OPEN_MODAL,
    payload: payload,
  };
};

export const getMenu = () => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/load-menu`)
      .then(response => {
        dispatch(setMenu(response.data));
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};
