import React from "react";
import {Typography} from "@material-ui/core";

const BusinessDescription = ({description}) => {
    return (
        <div className="d-flex flex-col ml-4 align-content-between pt-2 text-gray-800">
            <Typography component="h4" variant="h4">Business description</Typography>
            <div className="pr-2 pt-1">
                <Typography component="div" variant="body2">
                    {description? description:'N/A'}
                </Typography>
            </div>
        </div>
    )
}

export default BusinessDescription
