import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import {
  CHANGE_USERS_PARAMS,
  GET_ALL_USERS_SUCCESS,
  TOGGLE_MODAL,
  SELECT_USER,
} from '../constants/userManagementActionTypes';
// import NotificationManager from "react-notifications/lib/NotificationManager";
import sweetAlerts from '../../../src/appmakers/components/SweetAlertHelper';
import { first } from '@amcharts/amcharts4/.internal/core/utils/Array';

export const getAllUsersSuccess = payload => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload,
  };
};

export const getOtherUsers = params => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/admin/other-users`, { params })
      .then(response => {
        dispatch(getAllUsersSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getSearchedUsers = (params, searched) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/search-users-by-all?searchText=${searched}`, { params })
      .then(response => {
        dispatch(getAllUsersSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAdminUsers = params => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/admin/admin-users`, { params })
      .then(response => {
        dispatch(getAllUsersSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAllUsersByDate = (firstdate, lastdate, params) => {
  //alert(firstdate+" "+lastdate+" "+params)
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/admin/users-by-date`, { firstdate, lastdate, params })
      .then(response => {
        dispatch(getAllUsersSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAllUsersByDateType = (firstdate, lastdate, usertype, params) => {
  // alert(firstdate + ' ' + lastdate + ' ' + usertype);
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/admin/users-by-datetype`, { firstdate, lastdate, usertype, params })
      .then(response => {
        dispatch(getAllUsersSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const changeParams = (key, value) => {
  return {
    type: CHANGE_USERS_PARAMS,
    payload: { key, value },
  };
};

export const toggleModal = value => {
  console.log('toggleModal', value);
  return {
    type: TOGGLE_MODAL,
    payload: value,
  };
};

export const selectUser = value => {
  return {
    type: SELECT_USER,
    payload: value,
  };
};

export const updateUser = (user) => {
  delete user.quotedJobs;
  return (dispatch) => {
    dispatch(fetchStart())
    http.put(`${rootUrl}/admin/update-user`, { user }).then(response => {
      // NotificationManager.success('Updated successfully', 'Success')
      sweetAlerts('Updated successfully', 'success')
      dispatch(fetchSuccess())
    }).catch(error => {
      dispatch(fetchError())
    })
  }
}

export const addUser = user => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/admin/add-user`, { user })
      .then(response => {
        //debugger;
        if (response.data.responseCode === 404) {
          sweetAlerts('User Already Exists', 'error');
        } else {
          sweetAlerts('User Added successfully', 'success');
        }
        // NotificationManager.success('Updated successfully', 'Success')
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const deleteUser = userId => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .delete(`${rootUrl}/admin/delete-user/${userId}`)
      .then(response => {
        // NotificationManager.success(response.data, 'Success')
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        //dispatch(getAllUsersSuccess(response.data))
      })
      .catch(error => {
        // NotificationManager.error(error.data, 'error')
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};

export const UpdateUserStatus = userId => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .delete(`${rootUrl}/admin/change-user-status/${userId}`)
      .then(response => {
        // NotificationManager.success(response.data, 'Success')
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        //dispatch(getAllUsersSuccess(response.data))
      })
      .catch(error => {
        // NotificationManager.error(error.data, 'error')
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};
export const deleteMultipleUser = userIds => {
  console.log('userIds', userIds);
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/admin/delete-user`, userIds)
      .then(response => {
        // NotificationManager.success(response.data, 'Success')
        sweetAlerts(response.data, 'success');
        dispatch(fetchSuccess());
        //dispatch(getAllUsersSuccess(response.data))
      })
      .catch(error => {
        // NotificationManager.error(error.data, 'error')
        sweetAlerts(error.data, 'error');
        dispatch(fetchError());
      });
  };
};
