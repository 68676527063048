import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Card, Grid, Box, Button, TextField } from '@material-ui/core/';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import moment from 'moment';
import UserForm from './UserForm';
import Edit from '@material-ui/icons/Edit';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import '../../../../css/Home/UserDeatails.css';
import '../../../../css/Home/awsbtn.css';
import ResponsiveTable from './Table';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';

const main = { display: 'flex', height: '100%' };

const style = {
  width: '80%',
  height: '100%',
  padding: '20px',
  top: 5,
  position: 'relative',
  background: '#ffffff',
  padding: '20px',
  left: 100,
};

const button1 = {
  position: 'absolute',
  margin: '10px',
  paddingRight: '10px',
  paddingLeft: '10px',
  left: '40%',
  textAlign: 'center',
  height: '30px',
  minWidth: '100px',
  color: '#f5f0f0',
  backgroundColor: '#667eea',
  borderRadius: '5px',
};
const button2 = {
  position: 'absolute',
  margin: '10px',
  paddingRight: '10px',
  paddingLeft: '10px',
  left: '50%',
  textAlign: 'center',
  height: '30px',
  minWidth: '100px',
  color: '#f5f0f0',
  backgroundColor: '#9e051c',
  borderRadius: '5px',
};

export const UserDetails = ({ rawdata }) => {
  const history = useHistory();
  const { selectedUser } = useSelector(({ UserManagementReducer }) => UserManagementReducer);
  console.log('selectedUser=', selectedUser);
  console.log('selectedUser=', selectedUser?.jobs);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const [formFlag, setFormFlag] = useState(false);
  const setFormFlagHandler = value => {
    setFormFlag(value);
    setIsEdit('');
  };

  const [opeen, setOpeen] = useState(false);
  const handleOpeen = () => {
    setOpeen(true);
    history.push({
      pathname: '/home', // your data array of objects
    });
  };
  const [values, setValues] = useState(['Customer User', 'Business User']);
  const [selected, setSelected] = useState('Customer User');
  const [switchTo, setSwitchTo] = useState('Business User');
  const [isCustomerActive, setIsCustomerActive] = useState('active');
  const [isBusinessActive, setIsBusinessActive] = useState('');
  const [isEdit, setIsEdit] = useState('');

  const [jobsQuoted, setJobsQuoted] = useState([]);

  //console.log('jobsQuoted=',jobsQuoted);
  function handleChange(event) {
    setSelected(event.target.value);
  }
  const setQuotedJobs = job => {
    setJobsQuoted(jobsQuoted => [...jobsQuoted, job]);
  };
  const switchUser = () => {
    if (selected === 'Customer User') {
      setSelected('Business User');
      setSwitchTo('Customer User');
      setIsCustomerActive('');
      setIsBusinessActive('active');
    } else {
      setSelected('Customer User');
      setSwitchTo('Business User');
      setIsCustomerActive('active');
      setIsBusinessActive('');
    }
  };

  const viewAll = jobs => {
    let Data = {
      jobs: jobs,
    };
    history.push({
      pathname: '/view-posted-or-quoted-leads',
      state: Data, // your data array of objects
    });
  };
  useEffect(() => {
    if (selected == 'Customer User' && selectedUser?.userType == 'Business User') {
      switchUser();
    }
  }, [selectedUser]);
  return (
    <div style={main}>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3} className={'item'}>
          <Card>
            <div className={'leftmenu'}>
              <ul>
                <li className={'main'}>
                  {' '}
                  All Users
                  <ul>
                    <li className={`in ${isCustomerActive}`}> Customer User</li>
                    <li className={`in  ${isBusinessActive}`}> Bussiness User</li>
                  </ul>
                </li>
                <li className={'main'}>
                  {' '}
                  Quick Links
                  <ul>
                    <li className={`in ${isEdit}`}> Edit</li>
                  </ul>
                </li>
              </ul>
            </div>
          </Card>
        </Grid>
        {!formFlag && (
          <Grid item xs={12} sm={12} md={9} lg={9} className={'item'}>
            <Card>
              <CmtCardHeader>
                <h4>
                  Details for User <span className={'link'}>{selectedUser?.userName}</span>
                </h4>
              </CmtCardHeader>
              <Button variant="outlined" className={'ml-4'}>
                View User In Audit Log
              </Button>

              {selectedUser?.userType === 'Business User' && (
                <span className={'switchUser'}>
                  <b>Switch to {switchTo} </b>
                  <span onClick={switchUser}>
                    <AwesomeButton size="icon">
                      {' '}
                      <CompareArrowsIcon />{' '}
                    </AwesomeButton>
                  </span>
                </span>
              )}
              {/* {selectedUser?.userType === 'Business User' && (
              <Select
              value={selected}
              onChange={handleChange}
              >
              {values.map((value, index) => {
              return <MenuItem value={value}>{value}</MenuItem>;
              })}
              </Select>
            )} */}
              <div className={'mt-3 ml-3'}>
                <Grid container>
                  <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      {' '}
                      <label>User Name </label>{' '}
                    </b>{' '}
                    {selectedUser?.userName}
                  </Grid>
                  <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      {' '}
                      <label>User Type </label>{' '}
                    </b>
                    {selectedUser?.userType}
                  </Grid>
                  {selectedUser?.userType === 'Business User' && selected === 'Business User' && (
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Business Name </label>{' '}
                      </b>
                      {selectedUser?.businessName}
                    </Grid>
                  )}
                  {selectedUser?.userType === 'Business User' && selected === 'Business User' && (
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Profession </label>{' '}
                      </b>
                      {selectedUser?.businessProfession}
                    </Grid>
                  )}
                  {selectedUser?.userType === 'Business User' && selected === 'Business User' && (
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Business NZBN </label>{' '}
                      </b>
                      {selectedUser?.businessABN}
                    </Grid>
                  )}

                  <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      {' '}
                      <label>Email </label>{' '}
                    </b>
                    {selectedUser?.email}
                  </Grid>
                  {selectedUser?.mobile && (
                    <Grid item className={'half1'} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Mobile </label>{' '}
                      </b>
                      {selectedUser?.mobile}
                    </Grid>
                  )}
                  <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      {' '}
                      <label>Registration Date </label>{' '}
                    </b>
                    {selectedUser?.registrationDate ? moment(selectedUser.registrationDate).format('MMM DD, YYYY') : ''}
                  </Grid>
                </Grid>
              </div>

              <div className={'d-flex justify-content-center m-3 p3'}>
                <Button
                  className={'edit'}
                  onClick={() => {
                    setFormFlag(true);
                    setIsEdit('active');
                  }}
                  variant="contained"
                  color="success">
                  <Edit style={{ fontSize: 16 }} /> &nbsp;Edit User
                </Button>
                <Button className={'cancel'} onClick={() => handleOpeen()} variant="contained" color="error">
                  <KeyboardBackspaceIcon style={{ fontSize: 16 }} />
                  &nbsp; Back
                </Button>
              </div>
              {console.log('---------', selectedUser?.quotedJobs?.length)}
              {selectedUser?.jobs?.length > 0 && selected === 'Customer User' && (
                <div>
                  <CmtCardHeader>
                    <h4>Leads By {selectedUser?.userName} </h4>
                  </CmtCardHeader>
                  <ResponsiveTable jobs={selectedUser?.jobs}></ResponsiveTable>
                  <div className={'d-flex justify-content-center m-3 p3'}>
                    {selectedUser?.jobs.length > 1 && (
                      <Button
                        className={'cancel'}
                        onClick={() => viewAll(selectedUser.jobs)}
                        variant="contained"
                        color="error">
                        {' '}
                        View All &nbsp;
                        <ViewListOutlinedIcon style={{ fontSize: 20 }} />
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {selectedUser?.quotedJobs?.length > 0 &&
                selectedUser?.quotedJobs.map((quotedJobs, quotedJobsIndex) => {
                  quotedJobs.jobs.map((job, jobindex) => {
                    if (!jobsQuoted.includes(job)) setQuotedJobs(job);
                  });
                })}

              {selectedUser?.quotedJobs?.length > 0 && selected === 'Business User' && (
                <div>
                  <CmtCardHeader>
                    <h4>Quoted Leads By {selectedUser?.userName} </h4>
                  </CmtCardHeader>
                  <ResponsiveTable jobs={jobsQuoted}></ResponsiveTable>
                  <div className={'d-flex justify-content-center m-3 p3'}>
                    {jobsQuoted.length > 1 && (
                      <Button className={'cancel'} onClick={() => viewAll(jobsQuoted)} variant="contained" color="error">
                        {' '}
                        View All &nbsp;
                        <ViewListOutlinedIcon style={{ fontSize: 20 }} />
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </Card>
          </Grid>
        )}
        {formFlag && (
          <Grid item xs={12} sm={12} md={9} lg={9} className={'item'}>
            <Card>
              <CmtCardHeader>
                <h4>
                  Details for User <span className={'link'}>{selectedUser?.userName}</span>
                </h4>
              </CmtCardHeader>
              <UserForm setFormFlagHandler={setFormFlagHandler} />
            </Card>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
