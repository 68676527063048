import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser, setUserRegisteredAlert } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import { NotificationManager } from 'react-notifications';
import { rootUrl } from '../../../api-handling/rootUrl';
import Swal from 'sweetalert2';

const JWTAuth = {
  onRegisterNormalUser: ({ name, email, password, userType }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/register/user', {
          email: email,
          password: password,
          userName: name,
          userType: userType,
          status: 'PENDING',
        })
        .then(({ data }) => {
          if (data.message) {
            if (data.message == 'USER OF THIS EMAIL IS ALREADY EXISTS') {
              NotificationManager.error('Warning message', 'Close after 3000ms', 3000);
              // dispatch(fetchError(data.message));
            } else {
              dispatch(setUserRegisteredAlert(true));
            }
            dispatch(fetchSuccess());

            // localStorage.setItem('adminToken', data.token.access_token);
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            // dispatch(fetchSuccess());
            // dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            // dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          dispatch(fetchError());
        });
    };
  },

  onRegisterBusinessUser: ({
    name,
    email,
    password,
    phone,
    businessName,
    profession,
    businessNumber,
    address,
    userType,
  }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('register/user', {
          email: email,
          password: password,
          userName: name,
          mobile: phone,
          businessName: businessName,
          businessProfession: profession,
          businessABN: businessNumber,
          location: address,
          userType: userType,
          status: 'PENDING',
        })
        .then(({ data }) => {
          if (data.message) {
            if (data.message == 'USER OF THIS EMAIL IS ALREADY EXISTS') {
              dispatch(fetchError(data.message));
            } else {
              dispatch(setUserRegisteredAlert(true));
            }
            dispatch(fetchSuccess());

            // localStorage.setItem('adminToken', data.token.access_token);
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            // dispatch(fetchSuccess());
            // dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    console.log('inside Login');
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('login-user', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            if (data.token && data.user && data.user._id) {
              localStorage.setItem('adminToken', data.token);
              localStorage.setItem('adminUser', JSON.stringify(data.user));
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, data.token));
              if (data.user.userType === 'Customer User') {
                window.location.replace('/my-jobs');
              } else if (data.user.userType === 'Business User') {
                window.location.replace('/leads');
              }
            } else {
              dispatch(fetchError(data.error));
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Invalid Credentials',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch(function (error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/logout')
        .then(({ data }) => {
          console.log('data', data);
          if (data.result) {
            dispatch(fetchSuccess());
            localStorage.removeItem('adminUser');
            localStorage.removeItem('adminToken');
            dispatch(setAuthUser(null));
            //window.location.reload();
            window.location.replace("/");

          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function (error) {
          console.log('error', error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('adminToken');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .post('auth/me')
        .then(({ data }) => {
          if (data.user && data.user._id) {
            dispatch(fetchSuccess());
            dispatch(setAuthUser({ ...data.user, card: data.card }));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function (error) {
          dispatch(updateLoadUser(true));
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        }, 300);
      } catch (e) {
        dispatch(fetchError());
      }
    };
  },
  getSocialMediaIcons: () => {
    return (
      <React.Fragment>
        <br />
        <p className="text-center text-gray-500 font-bold">Sign in using:</p>
        <div className="d-flex flex-row justify-content-between align-items-center w-50 m-auto">
          <a href={rootUrl + '/auth/google'} className="cursor-pointer">
            <img src="/images/icons/googleplus.png" alt="Google image" width="40px" />
          </a>
          <div className="cursor-pointer">
            <img src="/images/icons/linkedin.jpg" alt="Google image" width="43px" />
          </div>
          <div className="cursor-pointer">
            <img src="/images/icons/facebook.png" alt="Google image" width="44px" />
          </div>
        </div>
      </React.Fragment>
    );
  },
};

export default JWTAuth;
