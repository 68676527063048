import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Box from '@material-ui/core/Box';
import { Button, fade, IconButton, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    errorNumber: {
        color: theme.palette.text.primary,
        fontWeight: 800,
        lineHeight: 1,
        marginBottom: 30,
        textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
    },
    searchRoot: {
        position: 'relative',
        width: 260,
        [theme.breakpoints.up('md')]: {
            width: 350,
        },
        '& .searchBtn': {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
        },
        '& .MuiInputBase-root': {
            width: '100%',
        },
        '& .MuiInputBase-input': {
            height: 48,
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            fontSize: 16,
            boxSizing: 'border-box',
            padding: '5px 50px 5px 15px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
}));

const Error404 = () => {
    const classes = useStyles();
    const [validRoutes, setAuthorized] = React.useState(null)
    const { authUser } = useSelector(({ auth }) => auth);
    let history = useHistory();

    const validRouteList = [
        '/dashboard',
        '/settings',
        '/home',
        '/bulk-upload',
        '/user-details',
        '/user-roles',
        '/activity-logs',
        '/complaint',
        '/view-all-leads-by-location',
        '/view-all-leads',
        '/view-leads-details/',
        '/view-leads-details-by-location/',
        '/view-leads-details-by-month/',
        '/view-user-details-by-month/',
        '/category-management-edit/'
    ]
    useEffect(function () {
        (!authUser || !validRoutes) && document.body.classList.add('no-header')
        let find = validRouteList.find(i => window.location.pathname.indexOf(i) !== -1);
        if (find)
            setAuthorized(true)
        else
            setAuthorized(false)
        if (!authUser) {
            setTimeout(() => {
                history.push('/signin');
            }, 5000)
        }
        return function () {
            document.body.classList.remove('no-header')
        }

    }, [validRoutes])
    return (
        <Box className={classes.root}>
            <Box fontSize={{ xs: 50, sm: 60 }} className={classes.errorNumber}>
                {validRoutes && !authUser && "Not Authorized"}
                {!validRoutes && "404"}
            </Box>
            {
                !validRoutes && <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
                    <IntlMessages id="extraPages.404Msg" />
                </Box>
            }
            {/*<Box className={classes.searchRoot}>*/}
            {/*  <InputBase placeholder={'Search...'} inputProps={{ 'aria-label': 'search' }} />*/}
            {/*  <IconButton className="searchBtn">*/}
            {/*    <SearchIcon />*/}
            {/*  </IconButton>*/}
            {/*</Box>*/}
            {
                !validRoutes &&
                <Box mt={8}>
                    <NavLink to="/home" variant="contained" color="primary">
                        <IntlMessages id="extraPages.goHome" />
                    </NavLink>
                </Box>
            }
        </Box>
    );
};

export default Error404;
