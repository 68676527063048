import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import ApmTable from '../../../../appmakers/components/ApmTable';
import { Filters } from './filters';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachmentIcon from '@mui/icons-material/Attachment';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUser,
} from '../../../../redux/actions/UserManagementActions';
import { Card, Grid, Box, Button, TextField } from '@material-ui/core/';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Typography, Divider } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    [theme.breakpoints.up('md')]: {
      width: '30%',
    },
  },
}));
export const Complain = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = React.useState({ one: false, two: false, three: false });
  const [selectedRow, setSelectedRow] = useState({});
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [budget, setBudget] = useState('');

  const [complaint, setComplaint] = useState({
    params: {
      page: 0,
      perPage: 5,
      total: 0,
      startDate: moment()
        .startOf('month')
        .toString(),
      endDate: moment()
        .endOf('day')
        .toString(),
    },
    data: [],
  });
  let [openModal, setOpenModal] = useState(false);
  const handleClick = (rowData) => {
    setSelectedRow(rowData)
    let description = '';
    rowData.jobId.questionAnswers.find(item => {
      if (item.pushedQuestion === 'Description') {
        description = item.answer_textarea ? item.answer_textarea : '';
      }
    });
    setDescription(description)

    let date = '';
    rowData.jobId.questionAnswers.find(item => {
      if (item.pushedQuestion === 'When') {
        if (item.answer_radio === 'Specific date') {
          date = 'on ' + item.answer_radio_other;
        } else date = item.answer_radio;
      }
    });
    setDate(date)

    let budget = '';
    rowData.jobId.questionAnswers.find(item => {
      if (item.pushedQuestion === 'Default Budget') {
        if (item.answer_radio === 'Not yet decided') {
          budget = item.answer_radio;
        } else if (item.answer_radio === 'Hourly Rate') {
          budget = item.answer_radio + ', ' + item.answer_radio_other + ' $ per/hr';
        } else budget = item.answer_radio + ', ' + item.answer_radio_other + ' $';
      }
    });
    setBudget(budget)
    setChecked({ one: false, two: false, three: false })
    setOpenModal(!openModal);
  }

  const handleChange = (val, prev) => {
    let data;
    if (val == 'one') {
      data = { 'one': !prev, 'two': false, 'three': false }
    } else if (val == 'two') {
      data = { 'one': false, 'two': !prev, 'three': false }
    } else if (val == 'three') {
      data = { 'one': false, 'two': false, 'three': !prev }
    }
    setChecked(data);
  };
  const getcomplaint = async () => {
    setLoading(true);
    await http
      .get(`${rootUrl}/complaints`, { params: complaint.params })
      .then(res => {
        setComplaint({
          ...complaint,
          params: { ...complaint.params, total: res.data.total },
          data: res.data.data,
        });

      })
      .catch(error => {
        console.log(error)
        // NotificationManager.error('Error while fetching data', 'Error')
        sweetAlerts('Error while fetching data', 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOpen = rowData => {
    //dispatch(selectUser(rowData));
    // history.push({
    //   pathname: '/user-details',
    //   state: rowData, // your data array of objects
    // });
  };
  const columns = [
    {
      title: 'Created By', field: 'createdBy', render: (rowData) => {
        return (<div style={{ cursor: 'pointer' }} onClick={() => handleOpen(rowData.createdBy)}>{rowData.createdBy?.userName}<br /> {rowData.createdByType}</div>)
      }, editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <Typography color="inherit">{props.rowData.createdBy?.userName}<br /> {props.rowData.createdByType}</Typography>
          </FormControl>
        );
      },
    },
    {
      title: 'Reason', field: 'reason', editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <Select
              size="small"
              value={props.rowData.reason}
              onChange={(e) => props.onChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['Fake profile', 'Spam', 'Poor service Quality', 'Not showing up after payment'].map(option => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      title: 'Subject', field: 'subject', render: (rowData) => {
        return (<div style={{ cursor: 'pointer' }} >{
          (rowData.subject?.length > 20) ? rowData.subject?.substring(0, 20) + '...' : rowData.subject
        }</div>)
      }
    },
    {
      title: 'Message', field: 'message', render: (rowData) => {
        return (<div style={{ cursor: 'pointer' }} >{
          (rowData.message?.length > 20) ? rowData.message?.substring(0, 20) + '...' : rowData.message
        }</div>)
      }
    },
    {
      title: 'Complaint Date Time', field: 'createdAt', render: (rowData) => {
        return (<div>{moment(rowData.createdAt).format('Do MMMM YYYY')}</div>)
      },
      editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <div>{moment(props.rowData.createdAt).format('Do MMMM YYYY')}</div>
          </FormControl>
        );
      },
    },
    {
      title: 'Status',
      field: 'status',
      editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <Select
              size="small"
              value={props.rowData.status || 'Open'}
              onChange={(e) => props.onChange(e.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {['Open', 'In Progress', 'Closed'].map(option => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      title: 'View', field: '_id', render: (rowData) => {
        return (<div>
          <VisibilityIcon
            onClick={() => {
              handleClick(rowData);
              // history.push(`/category-management-edit/${_id}`)
            }
            }
            style={{ cursor: "pointer" }}
          />
          {rowData?.attachments && <AttachmentIcon style={{ cursor: "pointer" }} onClick={() => {
            window.open(
              `${rowData.attachments}`, "_blank");
          }} />}
        </div>)
      },
      editComponent: (props) => {
        return (
          <FormControl variant="standard" >
            <div>
              <VisibilityIcon
                onClick={() => {
                  handleClick(props.rowData);
                  // history.push(`/category-management-edit/${_id}`)
                }
                }
                style={{ cursor: "pointer" }}
              />
              {props.rowData?.attachments && <AttachmentIcon style={{ cursor: "pointer" }} onClick={() => {
                window.open(
                  `${props.rowData.attachments}`, "_blank");
              }} />}
            </div>
          </FormControl>
        );
      },
    }
  ]

  const paramsHandler = (key, value) => {
    setComplaint({
      ...complaint,
      params: { ...complaint.params, [key]: value },
    });
  };
  const deleteHandler = async rowData => {
    await http
      .delete(`${rootUrl}/delete-complaint/${rowData._id}`)
      .then(res => {
        sweetAlerts('Complaint deleted successfully', 'success');
        getcomplaint();
      })
      .catch(error => {
        sweetAlerts('Somthing went wrong', 'error');
      })
  };
  const maltipleDeleteHandler = async rowData => {
    await http
      .post(`${rootUrl}/delete-complaints`, {
        data: rowData
      })
      .then(res => {
        sweetAlerts('Complaint deleted successfully', 'success');
        getcomplaint();
      })
      .catch(error => {
        sweetAlerts('Somthing went wrong', 'error');
      })
  };
  const updateHandler = async rowData => {
    await http
      .post(`${rootUrl}/update-complaint`, {
        createdByType: rowData.createdByType,
        message: rowData.message,
        subject: rowData.subject,
        reason: rowData.reason,
        status: rowData.status,
        complaintId: rowData._id,
      })
      .then(res => {
        sweetAlerts('Complaint updated successfully', 'success');
        getcomplaint();
      })
      .catch(error => {
        // NotificationManager.error('Error while fetching data', 'Error')
        sweetAlerts('Somthing went wrong', 'error');
      })
  };

  useEffect(() => {
    getcomplaint();
  }, [
    complaint.params.page,
    complaint.params.perPage,
    complaint.params.startDate,
    complaint.params.endDate,
  ]);
  let actions = [
    {
      icon: DeleteOutline,
      tooltip: 'Inactive',
      onClick: (event, rowData) => {
        maltipleDeleteHandler(rowData);
      },
    },
  ];
  return (
    <div>
      <Filters setParams={setComplaint} complaint={complaint} />
      {!loading && (
        <ApmTable
          name="Complaint"
          columns={columns}
          actions={actions}
          data={complaint.data}
          title="Complaint"
          showActions={true}
          params={complaint.params}
          paramsHandler={paramsHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}

        />
      )}
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openModal}
          onClose={() => { handleClick(selectedRow) }}
          closeAfterTransition
          backdrop="false"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        //
        >

          <Fade in={openModal} >
            <Grid item xs={12} sm={12} md={9} lg={9} className={'item'}>
              <Card style={{ position: 'relative', maxHeight: '530px', overflow: 'auto' }}>
                <CmtCardHeader >
                  <div >
                    <h4>
                      Complaint details
                    </h4>
                    <div style={{ position: 'absolute', right: '25px', top: '25px' }} onClick={() => { setOpenModal(!openModal) }}><HighlightOffRoundedIcon /></div>
                  </div>
                </CmtCardHeader>

                <Divider style={{ width: '94%', margin: 'auto' }} />

                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5">
                  Created By : {selectedRow.createdBy?.email} ({selectedRow.createdByType})
                  {!checked.one && <ArrowDropDownIcon className="ml-5" onClick={() => { handleChange('one', checked.one) }} />}
                  {checked.one && <ArrowDropUpIcon className="ml-5" onClick={() => { handleChange('one', checked.one) }} />}
                </Typography>
                {checked.one && <div className={'mt-3 ml-3'}>
                  <Grid container>
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>User Name </label>{' '}
                      </b>{' '}
                      {selectedRow.createdBy?.userName}
                    </Grid>
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>User Type </label>{' '}
                      </b>
                      {selectedRow.createdBy?.userType}
                    </Grid>
                    {selectedRow.createdBy?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Business Name </label>{' '}
                        </b>
                        {selectedRow.createdBy?.businessName}
                      </Grid>
                    )}
                    {selectedRow.createdBy?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Profession </label>{' '}
                        </b>
                        {selectedRow.createdBy.professions.map(profession => {
                          return (
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <Typography variant="body2"
                                className="ml-1 mb-2">{profession.name}</Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    )}
                    {selectedRow.createdBy?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Business NZBN </label>{' '}
                        </b>
                        {selectedRow.createdBy?.businessABN}
                      </Grid>
                    )}

                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Email </label>{' '}
                      </b>
                      {selectedRow.createdBy?.email}
                    </Grid>
                    {selectedRow.createdBy?.mobile && (
                      <Grid item className={'half1'} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Mobile </label>{' '}
                        </b>
                        {selectedRow.createdBy?.mobile}
                      </Grid>
                    )}
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Registration Date </label>{' '}
                      </b>
                      {selectedRow.createdBy?.registrationDate ? moment(selectedRow.createdBy.registrationDate).format('MMM DD, YYYY') : ''}
                    </Grid>
                  </Grid>
                  <Divider style={{ width: '94%', margin: 'auto' }} />
                </div>}

                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5">
                  Created Against : {selectedRow.createdAgainst?.email} ({selectedRow.createdAgainstType})
                  {!checked.two && <ArrowDropDownIcon className="ml-5" onClick={() => { handleChange('two', checked.two) }} />}
                  {checked.two && <ArrowDropUpIcon className="ml-5" onClick={() => { handleChange('two', checked.two) }} />}
                </Typography>
                {checked.two && <div className={'mt-3 ml-3'}>
                  <Grid container>
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>User Name </label>{' '}
                      </b>{' '}
                      {selectedRow.createdAgainst?.userName}
                    </Grid>
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>User Type </label>{' '}
                      </b>
                      {selectedRow.createdAgainst?.userType}
                    </Grid>
                    {selectedRow.createdAgainst?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Business Name </label>{' '}
                        </b>
                        {selectedRow.createdAgainst?.businessName}
                      </Grid>
                    )}
                    {selectedRow.createdAgainst?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Profession </label>{' '}
                        </b>
                        {selectedRow.createdAgainst.professions.map(profession => {
                          return (
                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <Typography variant="body2"
                                className="ml-1 mb-2">{profession.name}</Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    )}
                    {selectedRow.createdAgainst?.userType === 'Business User' && (
                      <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Business NZBN </label>{' '}
                        </b>
                        {selectedRow.createdAgainst?.businessABN}
                      </Grid>
                    )}

                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Email </label>{' '}
                      </b>
                      {selectedRow.createdAgainst?.email}
                    </Grid>
                    {selectedRow.createdAgainst?.mobile && (
                      <Grid item className={'half1'} sm={12} md={5} lg={5}>
                        <b>
                          {' '}
                          <label>Mobile </label>{' '}
                        </b>
                        {selectedRow.createdAgainst?.mobile}
                      </Grid>
                    )}
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Registration Date </label>{' '}
                      </b>
                      {selectedRow.createdAgainst?.registrationDate ? moment(selectedRow.createdAgainst.registrationDate).format('MMM DD, YYYY') : ''}
                    </Grid>
                  </Grid>
                  <Divider style={{ width: '94%', margin: 'auto' }} />
                </div>}

                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5">
                  Job details : {selectedRow.jobId?.questionAnswers[0].answer_radio}
                  {!checked.three && <ArrowDropDownIcon className="ml-5" onClick={() => { handleChange('three', checked.three) }} />}
                  {checked.three && <ArrowDropUpIcon className="ml-5" onClick={() => { handleChange('three', checked.three) }} />}
                </Typography>
                {checked.three && <div className={'mt-3 ml-3'}>
                  <Grid container>
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>When </label>{' '}
                      </b>{' '}
                      {date}
                    </Grid>
                    {description && <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Description </label>{' '}
                      </b>{' '}
                      {description}
                    </Grid>}
                    <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                      <b>
                        {' '}
                        <label>Budget </label>{' '}
                      </b>{' '}
                      {budget}
                    </Grid>


                    {selectedRow.jobId &&
                      selectedRow.jobId?.questionAnswers.map(answer => {
                        if (
                          answer.pushedQuestion !== 'Category' &&
                          answer.pushedQuestion !== 'Description' &&
                          answer.pushedQuestion !== 'When' &&
                          answer.pushedQuestion !== 'Default Budget'
                        ) {
                          if (answer[`answer_${answer.questionType}`] == 'Other (Please specify)' || answer[`answer_${answer.questionType}`] == 'Other (please specify)') {
                            return (
                              <Grid item key={answer.pushedQuestion} style={{ minHeight: '6vh' }} className={'half1'} xs={12} sm={12} md={5} lg={5}>
                                <b>
                                  {' '}
                                  <label>{answer.pushedQuestion} </label>{' '}
                                </b>{' '}
                                {answer[`answer_${answer.questionType}_other`]}
                              </Grid>
                            );
                          }
                          return (

                            <Grid item key={answer.pushedQuestion} style={{ minHeight: '6vh' }} className={'half1'} xs={12} sm={12} md={5} lg={5}>
                              <b>
                                {' '}
                                <label>{answer.pushedQuestion} </label>{' '}
                              </b>{' '}
                              {answer[`answer_${answer.questionType}`]}
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                  <Divider style={{ width: '94%', margin: 'auto' }} />
                </div>}
                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5" style={{ wordBreak: 'break-all' }}>
                  Reason : {selectedRow.reason}
                </Typography>
                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5 mr-5" style={{ wordBreak: 'break-all' }}>
                  Subject : {selectedRow.subject}
                </Typography>
                <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5 mr-5" style={{ wordBreak: 'break-all' }}>
                  Message : {selectedRow.message}
                </Typography>
                {selectedRow.attachments && <Typography variant="h4" className="text-gray-700 mb-2 mt-3 ml-5 mr-5" style={{ wordBreak: 'break-all' }}>
                  Attachment : <span
                    onClick={() => {
                      window.open(
                        `${selectedRow.attachments}`, "_blank");
                    }}
                    style={{ cursor: "pointer", color: 'Blue' }}
                  >View Attachment</span><AttachmentIcon style={{ cursor: "pointer", color: 'Blue' }} onClick={() => {
                    window.open(
                      `${selectedRow.attachments}`, "_blank");
                  }} />
                </Typography>}
              </Card>
            </Grid>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};
