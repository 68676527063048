import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LayoutContextProvider from './LayoutContext';
import MainContainer from './MainContainer';

const CmtVerticalLayout = ({ miniSidebarWidth, sidebarWidth, drawerBreakPoint, actionSidebarWidth, ...restProps }) => {
  let [showSidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('adminUser') && localStorage.getItem('adminToken')) {
      setShowSidebar(true)
    }
  }, [])
  return (
    <LayoutContextProvider>
      <MainContainer
        miniSidebarWidth={showSidebar ? miniSidebarWidth : 0}
        sidebarWidth={showSidebar ? sidebarWidth : 0}
        actionSidebarWidth={showSidebar ? actionSidebarWidth : 0}
        drawerBreakPoint={drawerBreakPoint}
        {...restProps}
      />
    </LayoutContextProvider>
  );
};

CmtVerticalLayout.prototype = {
  miniSidebarWidth: PropTypes.number,
  sidebarWidth: PropTypes.number,
  drawerBreakPoint: PropTypes.oneOf(['xs', 'sm', 'md']),
};

CmtVerticalLayout.defaultProps = {
  sidebarWidth: 304,
  miniSidebarWidth: 80,
  drawerBreakPoint: 'sm',
  actionSidebarWidth: 72,
};

export default CmtVerticalLayout;
