import {
    CHANGE_PARAMS,
    GET_ROLES_PAGINATION,
    ADD_USER_ROLE,
    SELECT_USER_ROLE,
    SET_PAGE_ACTIONS, GET_ROLES
} from "../constants/UserRoleActionTypes";

const initialState = {
    params: {
        page: 0,
        perPage: 5,
        total: 40
    },
    userRoles: [],
    allUserRoles: [],
    pageActions: [],
    selected: null
};

export const UserRoleReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_ROLES_PAGINATION: {
            return {
                ...state,
                userRoles: payload.data,
                params: {
                    ...state.params, total: payload.total
                },
            }
        }
        case GET_ROLES: {
            return {
                ...state,
                allUserRoles:payload
            }
        }
        case CHANGE_PARAMS: {
            return {
                ...state, params: {...state.params, [payload.key]: payload.value}
            }
        }
        case SELECT_USER_ROLE: {
            return {
                ...state, selected: payload
            }
        }
        case ADD_USER_ROLE: {
            let userRoles = [...state.userRoles]
            userRoles.push(payload)
            return {
                ...state, userRoles
            }
        }
        case SET_PAGE_ACTIONS: {
            return {
                ...state, pageActions: payload
            }
        }
        default: {
            return state;
        }
    }
}
