import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import 'react-notifications/lib/notifications.css';
import {Button, Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setKey} from "../../../../redux/actions/JobActions";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height:'50%'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        width: '70rem',
    },
    verifyEmail: {
        margin: "0.25em",
    },
    jobPosted: {
        color: "green"
    }
}));

export default function VerifyEmail({sendVerificationMail}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {showEmailModal, userInfo, validEmailEntered} = useSelector(({JobReducer}) => JobReducer);
    useEffect(() => {
        if (userInfo.new === true && !validEmailEntered) {
            sendVerificationMail()
        }
        return () => {
            setTimeout(() => {
                document.body.style.overflow = 'unset';
            }, 1000)
        }
    }, [userInfo.new])

    let actionButton = <Button color="primary"
                               onClick={() => dispatch(setKey({key: 'showEmailModal', value: false}))}>Okay</Button>
    if (userInfo.email && !userInfo.new && !validEmailEntered) {
        actionButton =
            <Button variant="contained" color="secondary" onClick={() => sendVerificationMail()}>Verify Email</Button>
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={showEmailModal}
                onClose={() => dispatch(setKey({key: 'showEmailModal', value: false}))}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={showEmailModal}>
                    <div className={classes.paper} style={{
                        width: "50%"
                    }}>
                        <Typography variant="h3" className={classes.jobPosted}>Job Posted Successfully</Typography>
                        {
                            userInfo.email && userInfo.new && !userInfo.isEmailVerified && !validEmailEntered &&
                            <Typography variant="body2" className={classes.verifyEmail}>
                                Your Email address is not verified. We have sent you an email click on that link to
                                verify your email.
                            </Typography>
                        }
                        {
                            userInfo.email && !userInfo.new && !userInfo.isEmailVerified && !validEmailEntered &&
                            <Typography variant="body2" className={classes.verifyEmail}>
                                Your Email address is not verified. Please verify your email.
                            </Typography>
                        }
                        {
                            userInfo.new && validEmailEntered &&
                            <Typography variant="body2" className={classes.verifyEmail}>
                                Welcome to TradingSeek! we have sent you an email, please click on the link to reset the
                                password.
                            </Typography>
                        }
                        {actionButton}
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
