import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import {Card,Grid,Box,Button,TextField } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {http} from '../../../../appmakers/services/http-service';
import {rootUrl} from "../../../../api-handling/rootUrl";
import WelcomeMessage from "../../Authenticated/Dashboard/welcomemessage";
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import CmtCard from "@coremat/CmtCard";
import SearchIcon from '@material-ui/icons/Search';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtMediaObject from '@coremat/CmtMediaObject';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { selectUser } from '../../../../redux/actions/UserManagementActions';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '16px',
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    quotesContent: {
      marginTop: '1vh',
    },
  }));
  
export const UserSearch = () => {
    const classes = useStyles();
    const [emails, setEmails] = useState([]);
    const [users, setUsers] = useState([]);
    const [msg,setMsg] = useState('Users List');
    const [email, setEmail] = useState('');
    const [userName, setName] = useState('');
    const [userPhone, setPhone] = useState('');
    let history = useHistory();
    const dispatch = useDispatch();

  const handleEmailChange = (val) => {
    if(val.length > 2){
    http.post(`${rootUrl}/search-email`, {
    email: val,
    }).then(res => {
      setEmails(res.data.userEmail)
      console.log('res', res.data.userEmail);
    }).catch(error => {
    console.log('error', error);
    })
    }
  }
  const search = () => {
    http.post(`${rootUrl}/search-users`, {
      email: email,
      userName:userName,
      mobile:userPhone
      }).then(res => {
        setUsers(res.data.users)
        setMsg('Users Not Found');
      }).catch(error => {
      console.log('error', error);
      })
  }
  const setMessage = (val) => { 
    if(val != '')
    setMsg('') 
  }
    return(
        <div style={{width: '80%', margin: '0px auto'}}>
            
        <Card >
            <Grid container spacing={3} className={"justify-content-between p-3"}>                
                <Grid  item xs={3} md={3} sm={3} lg={3}>
                        <TextField id="outlined-basic" label="User Name" variant="outlined" fullWidth 
                        onChange={(e)=>{
                          console.log(e.target.value)
                          setName(e.target.value)
                        }}
                        />
                </Grid>
                <Grid  item xs={3} md={3} sm={3} lg={3}>
                        <TextField id="outlined-basic" label="User Phone" variant="outlined" fullWidth 
                        onChange={(e)=>{
                          console.log(e.target.value)
                          setPhone(e.target.value)
                        }}
                        />
                </Grid>
                <Grid  item xs={4} md={4} sm={4} lg={4}>               
                        <Autocomplete
                            id="combo-box-demo"
                            limitTags={10}
                            name="userEmail"
                            options={emails}
                            getOptionLabel={option => option.email}
                            onChange={(event, value)=>{
                              console.log(value?.email)
                              setEmail(value?.email)
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" label="User Email"
                            onChange={e => {
                              handleEmailChange(e.target.value);
                            }}
                             />}
                        />
                </Grid>    
                <Button variant="contained" color="primary" style={{height:'40px',marginTop:'15px'}}
                onClick={search}
                className={'text-capitalize'}
                >Search</Button>
            </Grid>            
        </Card>
      
        <Card className={'mt-3'} style={{textAlign: 'center'}}>
        {
            users?.length > 0 && <Card className={'mt-3'} style={{textAlign: 'center'}}>
            <CmtCard>
            <CmtCardHeader
            title={<h4 className="font-bold">{'Users List'}</h4>}
            >
            </CmtCardHeader>
            </CmtCard>
            </Card>
          }
        <Grid container spacing={3} className={"justify-content-center p-3"}>
          
          
        {users &&
        users.map((user, index) => {
          setMessage(msg)
          return ( 
          <Grid item sm={12} md={6} lg={4} >
          <CmtCard >
                <CmtCardContent className={`${classes.content} text-center`} style={{height:'289px'}}>               
                  <CmtMediaObject avatar={user?.imageUrl?user.imageUrl:'/broken-image.jpg'}  avatarPos="center" ></CmtMediaObject>
                  <h5 className={classes.quotesContent}>{user.userName}</h5>
                  <div>
                 {user.mobile && <h6 className={classes.quotesContent}><CallRoundedIcon /> {user.mobile} </h6>}
                 {user.email &&<h6 className={classes.quotesContent}><EmailRoundedIcon /> {user.email} </h6>}  
                 </div>
                  <Button className={classes.quotesContent} variant="contained" color="primary"
                  onClick={() => {
                    dispatch(selectUser(user));
      
                    history.push({
                      pathname: '/user-details',
                      state: user, // your data array of objects
                    });
                  }}
                  >
                    View Details
                  </Button>                 
                </CmtCardContent>
              </CmtCard>
          </Grid>
          )
        })
      }
        </Grid>

       { msg != ''  && <CmtCard>
                        <CmtCardHeader
                        title={<h4 className="font-bold">{msg}</h4>}
                        >
                        </CmtCardHeader>
                        
           </CmtCard> }
        </Card>
        </div>
    )
}