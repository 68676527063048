import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Badge from '@material-ui/core/Badge';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { shadows } from '@material-ui/system';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles({
  link: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
});

export const ViewAllLeadsByLocation = () => {
  const [leads, setLeads] = useState([]);
  const classes = useStyles();
  let { index } = useParams();
  let history = useHistory();
  const { startDate, endDate } = useSelector(({ DashboardReducer }) => DashboardReducer);

  const getLeads = () => {
    const startDateUTC = new Date(startDate).toISOString();
    const endDateUTC = new Date(endDate).toISOString();
    http
      .get(`${rootUrl}/get-top-leads-by-locations?startDate=${startDateUTC}&endDate=${endDateUTC}`)
      .then(res => {
        console.log(res.data.data);
        setLeads(res.data.data);
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    getLeads();
  }, []);

  const handleClick = job => {
    history.push('/view-leads-details-by-location/' + job._id.state);
  };
  return (
    <div style={{ width: '70%', margin: '0px auto' }}>
      <Card style={{}} boxShadow={5}>
        <CardContent>
          <Typography style={{ fontSize: 14, textTransform: 'capitalize' }} color="textSecondary" gutterBottom>
            View All Lead Preferences
          </Typography>
          <Divider />
          {leads &&
            leads.map((job, key) => {
              return (
                <Typography variant="h5" component="h2" style={{ margin: '13px', display: 'inline-block' }}>
                  <Badge badgeContent={job.Count} color="primary">
                    <span
                      style={{
                        width: '275px',
                        backgroundColor: '#eaeaf1',
                        height: '24px',
                        textAlign: 'left',
                        padding: '4px',
                        borderRadius: '8px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        color: '#667eea',
                      }}
                      onClick={() => {
                        history.push('/view-leads-details-by-location/' + job._id.state);
                      }}>
                      {/* onClick={()=>{history.push('/view-leads-details/'+job.categoryId)}} */}
                      {job._id.state}
                    </span>
                  </Badge>
                </Typography>
              );
            })}
        </CardContent>
        <CardActions>
          <Button size="small"></Button>
        </CardActions>
      </Card>
    </div>
  );
};
