import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        width: 200,
    },
}));

export default function DateSelector({onChange, val}) {
    const [value, setValue] = React.useState(moment().format('YYYY-MM-DD'));

    const handleInputChange = event => {
        onChange(event.target.value)
    };

    useEffect(() => {
        setValue(val);
    }, [val]);
    const classes = useStyles();

    return (
        <form className={classes.container} noValidate>
            <TextField
                InputProps={{inputProps: {min: moment().format('YYYY-MM-DD')}}}
                id="date"
                label="Select Date"
                type="date"
                value={value}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={handleInputChange}
            />
        </form>
    );
}
