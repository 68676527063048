import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box, fade, makeStyles } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';

import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { Person, Settings, SyncAlt } from "@material-ui/icons";
import { useHistory } from "react-router";
import { setAuthUser } from "../../../../redux/actions/Auth";
import "./tooltip.css"

const useStyles = makeStyles(theme => ({
    profileRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 2,
            zIndex: 1,
            height: 35,
            width: 1,
            backgroundColor: fade(theme.palette.common.dark, 0.15),
        },
    },
}));
const actionsList = [
    {
        icon: <SettingsIcon />,
        label: 'Settings',
    },
    {
        icon: <ExitToAppIcon />,
        label: 'Logout',
    },
]
const UserDropDown = () => {
    const { authUser } = useSelector(({ auth }) => auth);
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const onItemClick = item => {
        if (item.label === 'Settings') {
            history.push('/settings');
        } else if (item.label === 'Logout') {
            dispatch(AuhMethods[CurrentAuthMethod].onLogout());
        }
    };
    let user = JSON.parse(localStorage.getItem('adminUser'));
    return (
        <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')} style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <p style={{ color: "black", margin: "auto", paddingRight: "0.25em", fontWeight: "bold", fontSize: "21px" }}>
                <div className='tooltipmsgsSide'>
                    <p className='mb-0' >
                        {authUser?.firstName.length > 8 ? authUser?.firstName.substring(0, 5) + '...' : authUser?.firstName}
                    </p>
                    <span class="tooltiptextmsgsSide" style={{ marginLeft: '-43px', color: "black", margin: "auto", paddingRight: "0.10em", fontWeight: "bold", fontSize: "12px" }}>{authUser?.firstName}</span>
                </div>
            </p>
            <CmtDropdownMenu onItemClick={onItemClick}
                TriggerComponent={
                    <CmtAvatar
                        size="small"
                        alt={user?.userName}
                        src={`${authUser?.imageUrl}?${authUser?.imageHash ? authUser.imageHash : Date.now()}`}
                    />
                }
                items={actionsList}
            />
        </Box>
    );
};

export default UserDropDown;
