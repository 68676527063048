import React from 'react';
import moment from 'moment';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
// let user = JSON.parse(localStorage.getItem('adminUser'));
var today = new Date()
var curHr = today.getHours()
var welcome = '';
if (curHr < 12) {
  welcome = 'Good Morning';
} else if (curHr < 18) {
  welcome = 'Good Afternoon';
} else {
  welcome = 'Good Evening';
}
const { getStartAndEndOfDayInUTC,
  getStartAndEndOfMonthInUTC,
  getStartAndEndOfYearInUTC,
  getStartAndEndOfMonthInUTCForInsert,
  getOffSet } = require("../../../../helpers/dateHelper")

const utcOffset = getOffSet();
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
var d = new Date();
var dayName = days[d.getDay()];
class WelcomeMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      user: null,
    };
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.setState({ date: new Date() }),
      1000
    );
    let user = JSON.parse(localStorage.getItem('adminUser'));
    this.setState({ user: user })
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      <div >
        <h5>{welcome}, {this.state.user?.firstName}!</h5>
        <h6>{dayName} , {moment(today).format('DD MMM')} {this.state.date.toLocaleTimeString()}</h6>
        {/* <Button variant="contained" color="primary"  >View more</Button> */}
        {/* <Divider /> */}
      </div>
    );
  }
}
export default WelcomeMessage;
