import { SEND_FORGET_PASSWORD_EMAIL, UPDATE_AUTH_USER, UPDATE_LOAD_USER, SET_USER_REGISTERED } from '../../@jumbo/constants/ActionTypes';
import axios from '../../services/auth/jwt/config';

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading,
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status,
    });
  };
};
export const setUserRegisteredAlert = status => {
  return dispatch => {
    dispatch({
      type: SET_USER_REGISTERED,
      payload: status,
    });
  };
};

export const fetchAndUpdateAuthUser = () => {
  return dispatch => {
    axios.post('auth/me')
      .then(({ data }) => {
        if (data.user && data.user._id) {
          dispatch(setAuthUser({ ...data.user, card: data.card }));
        } else {
          dispatch(updateLoadUser(true));
        }
      })
      .catch(function (error) {
        dispatch(updateLoadUser(true));
      });
  }
}
