import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card } from 'semantic-ui-react';
import { UserRolesPage } from './userRoles';
import { Actions } from './Action';
import { useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';

const useStyles = makeStyles(theme => ({
  userRoles: {
    width: '35%',
    justifyContent: 'center',
    textAlign: 'center',
  },
  actions: {
    flex: 1,
    justifyContent: 'center',
    textAlign: 'center',
  },
}));
const rolePageStyle = {
  '@media (max-width:600px)': {
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
  },
};
const dflex = {
  '@media (min-width: 601px)': {
    display: 'flex ',
  },
};
const topMargin = {
  '@media (max-width:600px)': {
    marginTop: '30px',
  },
};

export const Management = () => {
  const classes = useStyles();

  return (
    <StyleRoot>
      <Card className={'m-3 p-4'}>
        <Card.Content>
          <div style={dflex}>
            <div className={classes.userRoles} style={rolePageStyle}>
              <UserRolesPage />
            </div>
            <div className={'flex-grow-1 justify-content-center text-center'} style={topMargin}>
              <Actions />
            </div>
          </div>
        </Card.Content>
      </Card>
    </StyleRoot>
  );
};
