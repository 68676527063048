import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    Divider,
    FormControl,
    InputBase,
    InputLabel,
    NativeSelect,
    TextareaAutosize,
    TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../../api-handling/rootUrl";
import { useHistory } from "react-router";

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        width: '40rem',
    },
    title: {
        textAlign: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const CloseJobModal = ({ open, getJobs, closeModal, jobId }) => {
    const classes = useStyles();

    const history = useHistory();
    const [reason, setReason] = React.useState('');
    const [otherReason, setOtherReason] = React.useState('');

    const [textArea, setTextArea] = useState(false)
    // reason selector handler
    const handleChange = (event) => {
        if (event.target.value === 'Something else') {
            setReason(event.target.value);
            setTextArea(true);
        } else {
            setReason(event.target.value);
            setTextArea(false);
        }
    };

    //submit handler
    const submitHandler = () => {
        http.patch(`${rootUrl}/update-job-status/${jobId}`, {
            status: 'CLOSED',
            reason: otherReason ? otherReason : reason,
        }).then(res => {
            closeModal()
            setReason('')
            setOtherReason('')
            //history.push('/my-jobs');
            getJobs();
        }).catch(error => {
            setReason('')
            setOtherReason('')
            console.log('error', error);
        })
    }

    const cancelReasons = ['i\'m not ready to hire a business right now', 'i\'m going to do the job myself', 'I change my mind, not going to do the job at all', 'Something else']
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => {
                closeModal()
                setReason('')
                setOtherReason('')
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={classes.paper}>
                    <h3 className={classes.title}> Close Job</h3>
                    <Divider />
                    <FormControl className={classes.margin} style={{ width: '100%' }}>
                        <InputLabel htmlFor="demo-customized-select-native">Please select a reason</InputLabel>
                        <NativeSelect
                            id="demo-customized-select-native"
                            value={reason}
                            onChange={handleChange}
                            input={<BootstrapInput />}
                            style={{ marginTop: '1rem' }}
                        >
                            <option value="">--Select reason--</option>
                            {cancelReasons.map((value) => {
                                return (
                                    <option value={value} key={value}>{value}</option>
                                );
                            })}
                        </NativeSelect>
                    </FormControl>
                    {textArea &&
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            defaultValue={otherReason}
                            onChange={(e) => {
                                setOtherReason(e.target.value)
                            }}
                            variant="outlined"
                            className="w-100"
                            style={{ marginTop: '1vh' }}
                        />
                    }
                    <Button disabled={!reason} style={{ width: '100%', marginTop: '1vh' }} variant="contained"
                        color="primary" onClick={() => submitHandler()}>
                        Submit
                    </Button>

                </div>
            </Fade>
        </Modal>
    );
}

export default CloseJobModal;
