import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import EditPasswordForm from "./EditPasswordForm";
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../../api-handling/rootUrl";
import Radium, { StyleRoot } from 'radium';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
    header: {
        //marginLeft: '3%',
        marginTop: '1%',
        fontWeight: 'bold'
    },
    passwordRoot: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '2rem',
    },
})
const editPwd = {
    '@media (max-width: 600px)': {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
    },
    '@media (min-width: 601px)': {
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
    }
}
const Password = ({ setFormFlagHandlerProfile, setFormFlagPwd }) => {
    const classes = useStyles();
    const [passwordFormFlag, setPasswordFormFlag] = useState(false);
    const [passwordLastChanged, setPasswordLastChanged] = useState('');
    // useEffect(() => {
    //     fetchPasswordLastChanged();
    // });

    const fetchPasswordLastChanged = () => {
        let user = JSON.parse(localStorage.getItem('adminUser'));
        let id = user?._id;
        http.post(`${rootUrl}/pwd-last-changed`, {
            id
        }).then(res => {
            setPasswordLastChanged(res.data.PasswordLastChangeDate)
        }).catch(error => {
        })

    }
    useEffect(() => {
        fetchPasswordLastChanged();
    }, [])
    const setFormFlagHandler = (value) => {
        //alert(value)
        setPasswordFormFlag(value)
        setFormFlagPwd(value)
        setFormFlagHandlerProfile(false)
    }
    return (
        // <StyleRoot>
        //     <div>
        //         <h4 className={classes.header}>Password</h4>

        //         {!passwordFormFlag &&
        //         <div style={editPwd}>
        //             <p style={{color: 'gray',}}>Password last changed: {passwordLastChanged}</p>
        //             <span style={{paddingLeft: '5rem', color: '#0091ea', cursor: 'pointer'}}
        //                   onClick={() => setFormFlagHandler(true)}>Reset password</span>
        //         </div>
        //         }

        //         {
        //             passwordFormFlag &&
        //             <EditPasswordForm setFormFlagHandler={setFormFlagHandler}
        //                               setPasswordLastChanged={setPasswordLastChanged}/>
        //         }
        //     </div>
        // </StyleRoot>
        <StyleRoot>
            <div>
                <h4 className={classes.header}>Password</h4>

                {!passwordFormFlag &&
                    <Grid container spacing={2}
                    //style={editPwd}
                    >
                        <Grid item xs={12} sm={12} lg={12} xl={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} lg={8} xl={8} style={{ color: 'gray', }}>Password last changed: {passwordLastChanged}</Grid>
                                <Grid item xs={12} sm={12} lg={4} xl={4} style={{ color: '#0091ea', cursor: 'pointer' }}
                                    onClick={() => setFormFlagHandler(true)}>Reset password
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    passwordFormFlag &&
                    <EditPasswordForm setFormFlagHandler={setFormFlagHandler}
                        setPasswordLastChanged={setPasswordLastChanged} />
                }
            </div>
        </StyleRoot>
    );
}

export default Password;
