import { locationsJSON } from '../constants/DataBasedOnUrl'

const searchLocations = async searchText => {
    let matches = await locationsJSON.filter(item => {
        return (item.postcode + ', ' + item.locality + ', ' + item.territory).match(new RegExp(searchText, 'i'))
    });

    if (matches.length === 0) {
        matches = [];
    }
    return matches;
};

export default searchLocations;
