import React, {useEffect, useState} from "react";
import {Avatar, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Star} from "@material-ui/icons";
import {Rating} from "@material-ui/lab";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    large: {
        width: theme.spacing(25),
        height: theme.spacing(25),
    },
}));


const BasicInfo = ({user, userId}) => {
    const classes = useStyles();

    let [avgUserReviews, setAvgUserReviews] = useState(0);
    const dispatch = useDispatch();

    const {authUser} = useSelector(({auth}) => auth);


    useEffect(() => {
        getReviewsByUser()
    }, []);

    const getReviewsByUser = async () => {
        await http
            .get(`${rootUrl}/get-user-reviews/${userId}`)
            .then(res => {
                setAvgUserReviews(res.data?.reduce((total, next) => total + next.stars, 0) / res.data.length)
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .then(res => {
            });
    }

    return (
        <div className="d-flex flex-col justify-content-center align-items-center pt-8">
            <Avatar alt="Remy Sharp" src={user.imageUrl} className={classes.large}/>
            <div className="text-white p-1 mt-4" style={{background: 'rgb(102, 126, 234)', borderRadius: '5px'}}>
                <Star fontSize="small"/> Featured
            </div>
            <Typography component="h1" variant="h1" className="mt-4 font-italic">{user.businessName}</Typography>
            <div className="d-flex flex-row justify-content-center align-items-center w-3/4 m-2">
                <Rating name="half-rating-read" value={parseFloat(avgUserReviews.toFixed(1))} precision={0.5} readOnly/>
                <Typography component="h6" variant="h6" aria-setsize="50"
                            color={"primary"}>({avgUserReviews ? avgUserReviews.toFixed(1) : '0.0'})</Typography>
            </div>
            <Typography component="div" variant="body2" className="m-1">Completed {authUser?.wonJobs?.length} jobs on
                {process.env.REACT_APP_APP_NAME}</Typography>
            <Typography component="h5" variant="h5" className="m-1">NZBN <a
                href={`https://www.nzbn.govt.nz/mynzbn/search/${user.businessABN}`}>{user.businessABN}</a></Typography>
        </div>
    )
}

export default BasicInfo;
