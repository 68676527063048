import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import sweetAlerts from '../../../../../src/appmakers/components/SweetAlertHelper';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles } from '../../../../redux/actions/UserRoleActions';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Modal from '@material-ui/core/Modal';
import { getAdmins } from '../../../../redux/actions/AdminActions';
import Radium, { StyleRoot } from 'radium';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  let top = 50;
  let left = 50;
  let wid = 50;

  if (window.innerWidth < 600) {
    wid = 100;
    left = 0;
    top = 0;
  }
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${wid}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  password: '',
  confirmPassword: '',
  roleIds: [],
};

export const Admins = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  const [emailInvalid, setEmailInvalid] = useState(null);
  let { allUserRoles } = useSelector(({ UserRoleReducer }) => UserRoleReducer);
  let { params } = useSelector(({ AdminReducer }) => AdminReducer);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [data, setData] = useState({ ...resetForm });
  const [open, setOpen] = useState(false);
  //validations
  const requiredFields = {
    firstName: 'required|alpha',
    lastName: 'required|alpha',
    email: 'required|email',
    mobile: 'required|phone',
    password: 'required|min:5|max:10',
    confirmPassword: `required|confirmPassword:${data.password}`,
    roleIds: 'required:length',
  };

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      confirmPassword: {
        // name the rule
        message: 'The :attribute does not match password',
        rule: (val, params, validator) => {
          return params[0] === val;
        },
        required: true, // optional
      },
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  //handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    if (event.target.name == 'email') {
      if (emailInvalid) {
        setEmailInvalid(null);
      }
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  const handleRoleChange = (value, _id) => {
    let roleIds = [...data.roleIds];
    if (!value) roleIds = roleIds.filter(i => i !== _id);
    else roleIds.push(_id);
    setData({ ...data, roleIds });
  };

  const createUser = () => {
    if (validator.allValid()) {
      dispatch(fetchStart());
      http
        .post(`${rootUrl}/admin/users`, { ...data, email: data.email.toLowerCase() })
        .then(response => {
          setData({ ...resetForm });
          handleClose();
          sweetAlerts(response.data, 'success');
          dispatch(getAdmins(params));
          dispatch(fetchSuccess());
        })
        .catch(error => {
          setEmailInvalid(<span className={'text-danger m-1 ml-2'}>Email already exists</span>);
          dispatch(fetchError());
        });
    }
  };

  useEffect(() => {
    dispatch(getUserRoles());
    validator.showMessages();
  }, []);

  return (
    <StyleRoot>
      <div>
        {permissions['User Management'] && permissions['User Management'].includes('create') && (
          <Button
            variant="contained"
            className={'m-3'}
            size={'large'}
            onClick={handleOpen}
            color="primary"
            style={{ textTransform: 'capitalize' }}>
            Create Admin
          </Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Card style={modalStyle}>
            <Card.Content className={'p-4 m-4'}>
              <Typography variant="h2" className={'mb-3'}>
                Create Admin
              </Typography>
              <Container>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.firstName}
                      name={'firstName'}
                      label="First Name"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('firstName')}
                  </div>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.lastName}
                      name={'lastName'}
                      label="Last Name"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('lastName')}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.email}
                      name={'email'}
                      label="Email"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('email')}
                    {emailInvalid}
                  </div>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.mobile}
                      name={'mobile'}
                      label="Phone Number"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('mobile')}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.password}
                      name={'password'}
                      label="Password"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('password')}
                  </div>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      style={{ width: '100%' }}
                      onChange={handleChange}
                      value={data.confirmPassword}
                      name={'confirmPassword'}
                      label="Confirm Password"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('confirmPassword')}
                  </div>
                </div>
                <Typography className={'mb-3'} variant="h2">
                  Roles
                </Typography>
                {validationError('roleIds')}
                <div className={'w-50 p-0'} style={{ marginLeft: '1em' }}>
                  <Grid container className={'p-0'} spacing={1}>
                    {allUserRoles.map(item => {
                      return (
                        <Grid item xs={4} key={item._id}>
                          <FormControlLabel
                            label={item.title}
                            control={
                              <Checkbox
                                checked={data.roleIds.includes(item._id)}
                                onChange={event => handleRoleChange(event.target.checked, item._id)}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Container>
              <div className={'d-flex justify-content-end'}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleClose}
                  color="secondary"
                  style={{ textTransform: 'capitalize' }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={'ml-2'}
                  size="large"
                  onClick={createUser}
                  disabled={!validator.allValid()}
                  color="primary"
                  style={{ textTransform: 'capitalize' }}>
                  Create
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Modal>
      </div>
    </StyleRoot>
  );
};
