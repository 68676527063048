export const GET_PROFESSION = 'get_profession';
export const SET_PROFESSION = 'set_profession';
export const GET_ALL_DIF_COST_SUCCESS = 'get_all_dif_cost_success';
export const GET_ALL_COST_SUCCESS = 'get_all_cost_success';
export const CHANGE_COST_PARAMS = 'change_cost_params';
export const TOGGLE_MODAL = 'toggle-modal';
export const SELECT_COST = 'select-cost';
export const SET_LOCATION_EDIT = 'set-location-edit';



