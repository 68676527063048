import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import Box from '@material-ui/core/Box';
import { Lock } from "@material-ui/icons";
import MyProfile from "./myProfile";
import Password from "./password";
import TabPanel from "../../Common/TabPanel"


function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
}));

export default function Settings() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable force tabs example">
                    <Tab style={{ textTransform: "capitalize" }} label="My Profile" icon={<PersonPinIcon />} {...a11yProps(0)} />
                    {/* <Tab  style={{textTransform:"capitalize"}} label="Password" icon={<Lock/>} {...a11yProps(1)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <MyProfile />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                <Password/>
            </TabPanel> */}
        </Box>
    );
}
