import React, {useState} from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {Button, Divider, TextareaAutosize, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        width: '40rem',
        height: '70vh',
        overflow: 'auto'
    },
    title: {
        textAlign: 'center',
    }
}));

const JobDetailsModal = ({open, closeModal, item, category}) => {
    console.log('item', item);
    const classes = useStyles();
    let [descInput, setDescInput] = useState(false)

    // update description
    const updateDescription = (value) => {
        setDescInput(false)
        item.questionAnswers.find(item => {
            if (item.pushedQuestion === 'Description') {
                item.answer_textarea = value;
            }
        })
        http.patch(`${rootUrl}/update-description`, {
            data: item
        }).then(res => {

        }).catch(error => {
            console.log('error', error);
        });
    }
    //show description from array to show separately
    let description = '';
    item.questionAnswers.find(item => {
        if (item.pushedQuestion === 'Description') {
            description = item.answer_textarea ? item.answer_textarea : ''
        }
    })
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => closeModal()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={classes.paper}>
                    <h3 className={classes.title}>{category} job details</h3>
                    <Divider/>
                    <div style={{marginTop: '2vh'}}>
                        <div style={{minHeight: '6vh'}}>
                            <p><span style={{fontSize:'17px'}}>Status</span>: {item.status}</p>                            
                            <Divider/>
                        </div>
                        <div style={{minHeight: '6vh'}}>
                            <p><span style={{fontSize:'17px'}}>Description</span>:  {description}</p>
                            {(description !== '') ?                           
                                <div>                                    
                                    <div><Button style={{height: '20px'}} variant="contained" color="primary" onClick={() => setDescInput(true)}>Edit</Button></div>
                                </div> :
                                <div><Button style={{height: '20px'}} variant="contained" color="primary" onClick={() => setDescInput(true)}>Add</Button></div>
                            }
                           
                            {descInput &&
                            <div>
                                <TextField
                                    autoFocus
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    defaultValue={description}
                                    onChange={(e) => description = e.target.value}
                                    variant="outlined"
                                    className="w-100"
                                    style={{marginTop: '1vh'}}
                                />
                                <div style={{margin: '1rem'}}>
                                    <Button variant="contained"
                                            onClick={() => setDescInput(false)}>Cancel</Button>&nbsp;
                                    <Button variant="contained" color="primary"
                                            onClick={() => updateDescription(description)}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                            }
                            <Divider/>
                        </div>
                        {item && item.questionAnswers.map((answer) => {
                            if (answer.pushedQuestion !== 'Category' && answer.pushedQuestion !== 'Description') {
                                return (
                                    <div key={answer.pushedQuestion} style={{minHeight: '6vh'}}>
                                        <p><span style={{fontSize:'17px'}}><b>{answer.pushedQuestion}</b></span>: {answer[`answer_${answer.questionType}`]}</p>
                                        
                                        <Divider/>
                                    </div>
                                )
                            }
                        })}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

export default JobDetailsModal;
