import React, { Fragment } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import './styles/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
export const store = configureStore();

const App = () => (
  <AppContextProvider>
    {/* <Fragment> */}
    {/* <ReactNotification /> */}
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppWrapper>
          <Switch>
            <Routes />
          </Switch>
        </AppWrapper>
      </ConnectedRouter>
    </Provider>
    {/* </Fragment> */}
  </AppContextProvider>
);

export default App;
