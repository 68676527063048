export const getOffSet = () => {
    let offset = 0;
    if (process.env.REACT_APP_COUNTRY === "NET" || process.env.REACT_APP_COUNTRY === "IN") {
        offset = +330;
    } else if (process.env.REACT_APP_COUNTRY === "NZ") {
        offset = +720;
    } else if (process.env.REACT_APP_COUNTRY === "AU") {
        offset = +600;
    }
    return offset;
};


