import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
// import NotificationManager from "react-notifications/lib/NotificationManager";
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(4),
  },
}));

export const Filters = ({ setParams, complaint }) => {
  const classes = useStyles();
  const [admins, setAdmins] = useState([]);
  const modules = ['User Roles', 'Admins', 'User Management'];
  const actions = ['Create', 'Update', 'Delete'];

  const getAdmins = async () => {
    await http
      .get(`${rootUrl}/admin/admin-users`, {})
      .then(res => {
        setAdmins(res.data);
      })
      .catch(error => {
        // NotificationManager.error('Error while fetching data', 'Error')
        sweetAlerts('Error while fetching data', 'Error');
      });
  };

  const handleChange = (event, key) => {
    const value = ['startDate', 'endDate'].includes(key) ? event.toString() : event.target.value;
    setParams({ ...complaint, params: { ...complaint.params, [key]: value } });
  };

  const EmailFilter = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="email-label">Email</InputLabel>
        <Select labelId="email-label" value={complaint.params.user} onChange={e => handleChange(e, 'user')}>
          <MenuItem value={null}>Clear</MenuItem>
          {admins.map(i => (
            <MenuItem key={i._id} value={i._id}>
              {i.firstName} : {i.email}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const ModuleFilter = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="email-label">Module</InputLabel>
        <Select labelId="email-label" value={complaint.params.module} onChange={e => handleChange(e, 'module')}>
          <MenuItem value={null}>Clear</MenuItem>
          {modules.map(i => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const ActionFilter = () => {
    return (
      <FormControl className={classes.formControl}>
        <InputLabel id="email-label">Action</InputLabel>
        <Select labelId="email-label" value={complaint.params.action} onChange={e => handleChange(e, 'action')}>
          <MenuItem value={null}>Clear</MenuItem>
          {actions.map(i => (
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    if (!admins.length) {
      getAdmins();
    }
  }, [admins]);

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  return (
    <Card className={'mb-2 p-4'}>
      <Card.Header className={'text-bold'}>Filters</Card.Header>
      <Card.Content>
        <div className={'mt-1'}>
          <KeyboardDatePicker
            className={classes.formControl}
            disableToolbar
            variant="inline"
            format="MM/DD/yyyy"
            margin="normal"
            label="Start Date"
            id="date-picker-inline"
            value={complaint.params.startDate}
            onChange={e => {
              handleChange(e, 'startDate');
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardDatePicker
            disableToolbar
            className={classes.formControl}
            variant="inline"
            format="MM/DD/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="End Date"
            value={complaint.params.endDate}
            onChange={e => {
              handleChange(e, 'endDate');
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </div>
      </Card.Content>
    </Card>
  );
};
