import React from 'react';
import {Modal} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {QuestionItem} from "./questions";
import {JobModalFooter} from "./Footer";
import CloseIcon from "@material-ui/icons/Close";
import {closePostJobModal, setKey} from "../../../../redux/actions/JobActions";

export default ({professionModalHandler=()=>{}, getMyJobs=()=>{}}) => {
    const {
        showModal,
        selectedQuestion,
        profession,
        questions,
    } = useSelector(({JobReducer}) => JobReducer);
    const {authUser} = useSelector(({auth}) => auth);

    const dispatch = useDispatch()
    const result = selectedQuestion.length ? selectedQuestion.map((item, index) => {
        return <QuestionItem key={index} item={item}/>
    }) : <QuestionItem item={selectedQuestion}/>;

    const closeModalHandler = () => {
        const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
        let validEmail = false;
        let flatQuestions = questions
        flatQuestions = flatQuestions.flat();
        flatQuestions.map(i => {
            if (i.pushedQuestion === 'Email' && emailRegex.test(i.answer_input)) {
                validEmail = true;
                dispatch(setKey({key: 'validEmailEntered', value: true}))
            }
        })
        professionModalHandler()
        dispatch(closePostJobModal(
            validEmail, authUser,
        ))
    }

    return <>
        <Modal size="lg"
               show={showModal}
               className="job-modal"
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    FAQ's For {profession.name}
                </Modal.Title>
                <CloseIcon className="cursor-pointer" onClick={() => closeModalHandler()}/>
            </Modal.Header>
            <Modal.Body>
                {result}
            </Modal.Body>
            <JobModalFooter professionModalHandler={professionModalHandler}
                            getMyJobs={getMyJobs}/>
        </Modal>
    </>

}
