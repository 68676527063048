import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const CmtImage = ({ src, alt, color, ...rest }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: color }}>
      <img src={src} alt="TradingSeekLogo" style={{ width: '2rem' }} />
      <Typography variant="h2" className="ml-1 text-xl font-weight-bold text-gray-900 hover:text-blue-600 cursor-pointer">
        TradingSeek{' '}
      </Typography>
      <Typography
        className="text-gray-900  font-weight-bold hover:text-blue-600 cursor-pointer"
        style={{ marginTop: '26px', marginLeft: '-39px', fontSize: '12px' }}>
        Admin
      </Typography>
    </div>
  );
};

export default CmtImage;
