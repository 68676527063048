import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Typography} from "@material-ui/core";
import {TextArea} from "./TextArea";
import {RadioInput} from "./Radio";
import {setQuestions, selectQuestion, setKey, setIndex} from "../../../../redux/actions/JobActions";
import RangeInput from "./RangeInput";
import TextInputField from "./TextInputField";
import PasswordInput from "./PasswordInput";
import FirebaseValidation from "./FirebaseValidation";

export const QuestionItem = ({item}) => {
    const dispatch = useDispatch()
    const {question, questionType} = item
    let [isVerify, setVerify] = useState(false);
    const {questions, selectedQuestion, showCode, index, validPhone} = useSelector(({JobReducer}) => JobReducer);
    let [validationError, setValidationError] = useState('')

    const handleInputChange = (value, otherFields = {}) => {
        const ques = [...questions]
        let index = null;
        for (let i = 0; i < ques.length; i++) {
            if (ques[i].length) {
                const exist = ques[i].findIndex(i => i._id === item._id);
                if (exist !== -1) {
                    index = i;
                    break;
                }
            } else {
                if (ques[i]._id == item._id) {
                    index = i;
                    break;
                }
            }

        }
        if (ques[index].length) {
            const childIndex = ques[index].findIndex(i => i._id === item._id);
            ques[index][childIndex] = {...item, [`answer_${questionType}`]: value, ...otherFields};
        } else {
            ques[index] = {...item, [`answer_${questionType}`]: value, ...otherFields};
        }
        dispatch(setQuestions(ques))

        if (selectedQuestion.length) {
            const selected = [...selectedQuestion]
            const index = selected.findIndex(i => i._id === item._id)
            selected[index] = {...item, [`answer_${questionType}`]: value, ...otherFields};
            dispatch(selectQuestion(selected))
        } else {
            dispatch(selectQuestion({...item, [`answer_${questionType}`]: value, ...otherFields}))
        }

    }
    useEffect(() => {
        checkValidation()
    }, [selectedQuestion])
    const checkValidation = () => {
        if (selectedQuestion.length && selectedQuestion[1][`answer_${selectedQuestion[1].questionType}`] &&
            selectedQuestion[2][`answer_${selectedQuestion[2].questionType}`] && selectedQuestion[1][`answer_${selectedQuestion[1].questionType}`] !== selectedQuestion[2][`answer_${selectedQuestion[2].questionType}`]) {
            setValidationError('Passwords not matched')
        } else setValidationError('')
    }
    const verifyPhone = () => {
        let ques = [...questions];
        if (!ques.find(i => i.pushedQuestion === 'Phone Recaptcha'))
            ques.splice(index + 1, 0, {
                _id: 130,
                question: `Verify your phone number`,
                questionType: "phone_recaptcha",
                pushedQuestion: 'Phone Recaptcha',
            })
        ques = ques.map(i => {
            if (i.pushedQuestion === 'Phone Recaptcha')
                i.answer_phone_recaptcha = selectedQuestion[`answer_${selectedQuestion.questionType}`]
            return i;
        })
        dispatch(setQuestions(ques))
        dispatch(selectQuestion(ques[index + 1]))
        dispatch(setIndex(index + 1))
    }

    let inputField = null;
    switch (questionType) {
        case 'textarea':
            inputField = <TextArea onChange={handleInputChange}
                                   value={item[`answer_${questionType}`]}/>
            break;
        case 'radio':
            inputField = <RadioInput onChange={handleInputChange}
                                     choices={item.choices}
                                     valueKey={item.valueKey}
                                     specificValue={item[`answer_${questionType}_other`]}
                                     type={questionType}
                                     value={item[`answer_${questionType}`]}/>
            break;
        case 'range':
            inputField = <RangeInput onChange={handleInputChange}
                                     selectedRange={item[`answer_${questionType}`]}/>
            break;
        case 'input':
            if (item.pushedQuestion === 'Phone') {
                inputField = <div>
                    <TextInputField onChange={handleInputChange}
                                    value={item[`answer_${questionType}`]}
                    />
                    <Button onClick={verifyPhone}
                            variant="contained" color="primary" disabled={!item[`answer_${questionType}`] || (
                        item[`answer_${questionType}`] && validPhone === item[`answer_${questionType}`])}>
                        Verify Phone
                    </Button>
                </div>
            } else {
                inputField = <div>
                    <TextInputField onChange={handleInputChange}
                                    value={item[`answer_${questionType}`]}
                    />
                    {/*{item.pushedQuestion === 'Posted By' && item[`answer_${questionType}`].length ===0 &&*/}
                    {/*<span className="text-danger pb-1">Required*</span>*/}
                    {/*}*/}
                </div>

            }
            break;
        case 'password':
            inputField = <div>
                <PasswordInput onChange={handleInputChange}
                               val={item[`answer_${questionType}`]}
                />
                {item.pushedQuestion === 'Confirm Password' && validationError.length > 0 &&
                <span className="text-danger">{validationError}</span>
                }
            </div>
            break;
        case 'phone_recaptcha':
            inputField = <FirebaseValidation/>
    }

    useEffect(() => {
    }, [selectedQuestion])

    return <>
        <Typography variant="h3"
                    className={"mb-2"}>{question}</Typography>
        {inputField}
    </>
}
