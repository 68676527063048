// const proxyurl = "https://cors-anywhere.herokuapp.com/";
import { ServerUrl, SocketIoUrl, FrontendUrl } from '../constants/DataBasedOnUrl'
var serverUrl = ServerUrl;
var socketIoUrl = SocketIoUrl;
var frontEndUrl = FrontendUrl;

console.log('Environment: ', process.env.REACT_APP_ENVIRONMENT);
if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  serverUrl = `http://localhost:8080`;
  socketIoUrl = `http://localhost:8080`;
  frontEndUrl = `http://localhost:3000`;
}

export const rootUrl = serverUrl;
export const socketUrl = socketIoUrl;
export const frontendUrl = frontEndUrl