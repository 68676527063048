import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {selectQuestion, setQuestions} from "../../../../redux/actions/JobActions";
import {TextArea} from "./TextArea";
import DateSelector from "./DateSelector";
import RangeInput from "./RangeInput";
import TextInputField from "./TextInputField";
import BudgetInput from "./BudgetInput";

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: "80%"
        },
    },
}));


export const RadioInput = ({value, choices, onChange, valueKey, specificValue = null, type = null}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {profession, selectedQuestion, questions, others} = useSelector(({JobReducer}) => JobReducer);
    const {authUser} = useSelector(({auth}) => auth);

    const options = choices.map((choice, index) => {
        return <FormControlLabel value={choice.option}
                                 key={index}
                                 control={<Radio color="primary"/>} label={choice.option}/>
    });


    const handleInputChange = event => {
        if (valueKey === 'category_id') {
            const itemQues = profession.categories.find(i => i.name == event.target.value)?.questions;
            const ques = [questions[0], ...itemQues]
            const index = ques.findIndex(i => i._id === selectedQuestion._id)
            ques[index] = {...selectedQuestion, [`answer_radio`]: event.target.value};
            if (!authUser) {
                const emailQuestion = {
                    _id: 121,
                    question: "What is your email?",
                    questionType: "input",
                    pushedQuestion: "Email",
                    choices: [],
                }
                ques.push(emailQuestion)
            }
            console.log('ques', ques);
            dispatch(setQuestions(ques))
            dispatch(selectQuestion({...selectedQuestion, answer_radio: event.target.value}))
            console.log('ques', ques);
        } else {
            onChange(event.target.value)
        }
    };

    const handleSpecificField = (text) => {
        onChange(value, {[`answer_${type}_other`]: text})
    }

    let specificField = null;
    if (Object.keys(others).includes(value)) {
        switch (others[value]) {
            case 'textarea':
                specificField = <TextArea onChange={handleSpecificField}
                                          value={specificValue}/>
                break;
            case 'dateSelector':
                specificField = <DateSelector onChange={handleSpecificField}
                                              val={specificValue}/>
                break;
            case 'BudgetInput': {
                if (value !== 'Not yet decided')
                    specificField = <BudgetInput onChange={handleSpecificField}
                                                 value={specificValue}/>
            }
                break;
        }
    }


    return <>
        <RadioGroup name="answers" value={value} onChange={handleInputChange}>
            {options}
        </RadioGroup>
        {specificField}
    </>
}
