import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import {lighten, makeStyles} from '@material-ui/core/styles';
import {red} from '@material-ui/core/colors';
import {Button, CardContent, Grid, TextField, Typography} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import {getProfessions, setKey, setProfession} from "../../../../../redux/actions/JobActions";
import {Address} from '../../../Common/JobModal/Address'
import {JobModal} from '../../../Common/JobModal'
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        backgroundColor: lighten(theme.palette.background.paper, 0.1),
    },
    getFreeQuotesBtn: {
        background: 'linear-gradient(45deg, #667eea 30%, #6076d6 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgb(207,226,243)',
        color: 'white',
        height: 48,
        padding: '0 30px',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(270deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

export const Job = ({
                        btnLabel = 'appModule.continue', professionModalHandler = () => {
    }, getMyJobs = () => {
    }
                    }) => {
    const dispatch = useDispatch();
    let {professions, profession, address} = useSelector(({JobReducer}) => JobReducer);
    // let [address, setAddress] = useState('');

    useEffect(() => {
        if (!professions.length)
            dispatch(getProfessions())
    }, []);

    const professionHandler = (value) => {
        dispatch(setKey({key: 'profession', value}))
    }
    const addressHandler = (value) => {
        dispatch(setKey({key: 'address', value}))
    }

    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="caption" className="text-gray-700 fs-6">Tell us about your job
                    and get free quotes</Typography>
                <div style={{marginTop: '2rem'}}>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <label className={"mb-2"}>What service do you need?</label>
                            <Autocomplete
                                size='small'
                                id="combo-box-demo"
                                options={professions}
                                value={profession}
                                getOptionLabel={option => option.name}
                                onChange={(event, value) => professionHandler(value)}
                                renderInput={params => <TextField {...params} variant="outlined"/>}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Address className={"flex-grow-1 pl-2"} onChange={(value) => addressHandler(value)}/>
                        </Grid>
                    </Grid>
                    <JobModal btnLabel={btnLabel} fullWidth={true} professionModalHandler={professionModalHandler}
                              getMyJobs={getMyJobs}/>
                    <br/>
                    {/*<Button style={{width: '100%', textTransform: "capitalize"}} onClick={() => getQuotation()}*/}
                    {/*        variant="contained" color="primary"*/}
                    {/*        disabled={address === '' || profession === ''}*/}
                    {/*>*/}
                    {/*    Continue*/}
                    {/*</Button>*/}
                </div>
            </CardContent>
        </Card>
    );
};
