import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Fade from '@material-ui/core/Fade';
import { useDispatch, useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { useHistory } from 'react-router';
import { fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import Badge from '@material-ui/core/Badge';
import moment from 'moment';
const { getStartAndEndOfDayInUTC,
  getStartAndEndOfMonthInUTC,
  getStartAndEndOfYearInUTC,
  getStartAndEndOfMonthInUTCForInsert,
  getOffSet } = require("../../../../helpers/dateHelper")

const userStyle = {
  '@media (max-width: 600px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 0px 0px -25px',
    float: 'left',
    padding: '20px',
  },
  '@media (min-width: 601px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 10px',
    float: 'left',
  },
};
export const UserPreference = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const utcOffset = getOffSet();

  const { count, startDate, endDate } = useSelector(({ DashboardReducer }) => DashboardReducer);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);

  const [businessUserCount, setBusinessUserCount] = useState(0);
  const [normalUserCount, setNormalUserCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(0);
  const [msg, setMsg] = useState("Today's Registered User");
  const [msgnumeric, setMsgnumeric] = useState('1');
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl();
  };

  const handleClck = userType => {
    history.push('/view-user-details-by-user-type/' + userType);
  };

  const getCount = val => {
    const startDateUTC = new Date(startDate).toISOString();
    const endDateUTC = new Date(endDate).toISOString();
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/get-dashboard-data-user?startDate=${startDateUTC}&endDate=${endDateUTC}`)
      .then(res => {
        let result = res.data.data[0].usersCountByType;
        const business_user = result.filter(user => {
          return user._id === 'Business User';
        });
        const normmal_user = result.filter(user => {
          return user._id === 'Customer User';
        });

        const buser = business_user[0] ? business_user[0].Count : 0;
        const nuser = normmal_user[0] ? normmal_user[0].Count : 0;
        //debugger
        setNormalUserCount(nuser);
        dispatch(fetchSuccess());
        setBusinessUserCount(buser);
      })
      .catch(e => {
        dispatch(fetchSuccess());
        console.log(e)
      });


    // http
    //   .get(`${rootUrl}/get-daily-summary`)
    //   .then(res => {
    //     let result = '';
    //     if (val == 2) {
    //       result = res.data.data[0].users_count_month;
    //     } else if (val == 3) {
    //       result = res.data.data[0].users_count_year;
    //     } else {
    //       result = res.data.data[0].users_count;
    //     }
    //     console.log('alluserresult', result);
    //     const business_user = result.filter(user => {
    //       return user._id === 'Business User';
    //     });

    //     const buser = business_user[0] ? business_user[0].Count : 0;

    //     const normmal_user = result.filter(user => {
    //       return user._id === 'Customer User';
    //     });

    //     const nuser = normmal_user[0] ? normmal_user[0].Count : 0;
    //     //debugger
    //     setNormalUserCount(nuser);
    //     dispatch(fetchSuccess());
    //     setBusinessUserCount(buser);
    //   })
    //   .catch(e => {
    //     dispatch(fetchSuccess());
    //     console.log(e)
    //   });
  };
  useEffect(() => {
    if (!startDate || !endDate)
      return
    getCount(count);
  }, [startDate, endDate]);


  return (
    <StyleRoot>
      <div>

        <CardContent>
          <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            <h6 style={{ color: 'black', fontSize: '15px' }}> User Count </h6>
          </Typography>
          <Divider />
          <Typography variant="h5" component="h2" style={{ marginTop: '13px' }}>
            <Badge badgeContent={businessUserCount} color="primary" showZero={true}>
              <span
                style={{
                  backgroundColor: '#eaeaf1',
                  height: '24px',
                  textAlign: 'left',
                  padding: '4px',
                  borderRadius: '8px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#667eea',
                  width: '275px'
                }}
                onClick={() => handleClck(1)}
              >
                {/* onClick={()=>{history.push('/view-leads-details/'+job.categoryId)}} */}
                Business user
              </span>
            </Badge>
          </Typography>
          <Typography variant="h5" component="h2" style={{ marginTop: '13px' }}>
            <Badge badgeContent={normalUserCount} color="primary" showZero={true}>
              <span
                style={{
                  backgroundColor: '#eaeaf1',
                  height: '24px',
                  textAlign: 'left',
                  padding: '4px',
                  borderRadius: '8px',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  color: '#667eea',
                  width: '275px'
                }}
                onClick={() => handleClck(2)}
              >
                {/* onClick={()=>{history.push('/view-leads-details/'+job.categoryId)}} */}
                Customer user
              </span>
            </Badge>
          </Typography>

          {/* <Typography style={{ fontSize: 14, marginTop: 4 }} color="textSecondary" gutterBottom>
            <h7 style={{ color: 'black' }} onClick={() => handleClck(1)}>
              {' '}
              Business user : {businessUserCount}
            </h7>
            <Button
              variant="contained"
              color="primary"
              endIcon={<VisibilityIcon />}
              style={{
                float: 'right',
                borderRadius: '20px',
                height: '25px',
                textTransform: 'capitalize',
              }}
              onClick={() => handleClck(1)}
            >
              View
            </Button>
          </Typography> */}
        </CardContent>
        {/* <CardContent>
          <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            <h7 style={{ color: 'black' }} >
              {' '}
              Customer user : {normalUserCount}
            </h7>
            <Button
              variant="contained"
              color="primary"
              endIcon={<VisibilityIcon />}
              style={{
                float: 'right',
                borderRadius: '20px',
                height: '25px',
                textTransform: 'capitalize',
              }}
              onClick={() => handleClck(2)}
            >
              View
            </Button>
          </Typography>
        </CardContent> */}
      </div>
    </StyleRoot>
  );
};
export default UserPreference;
