import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, Collapse, fade, IconButton, Link } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import sweetAlerts, { sweetAlertsOkBtn } from '../../../../appmakers/components/SweetAlertHelper';
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
    display:'flex',
    [theme.breakpoints.down('md')]: {
     display:'block'
    },
    
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = useState({
    email: null,
  });
  const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    if (send_forget_password_email) {
      setOpen(true);

      setTimeout(() => {
        dispatch(setForgetPassMailSent(false));
        history.push('/');
      }, 1500);
    }
  }, [send_forget_password_email]);

  const onSubmit = async () => {
    let err = {};
    err.email = !email || !emailRegex.test(email) ? true : null;
    setErrors(err);
    if (!Object.values(err).includes(true)) {
      http
        .get(`${rootUrl}/forgot-password?email=${email}`)
        .then(res => {
          setEmail('');
          sweetAlertsOkBtn('We have sent you a password reset email. Please check you email', 'success');
        })
        .catch(error => {
          sweetAlerts(error.data, 'error');
          console.log('error', error);
        });
    }
  };

  return (
    <AuthWrapper>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/Tradingseek.svg'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/Tradingseek.svg'} />
        </Box>
        <Typography component="div"  className={`${classes.titleRoot}`}>
        <Typography component="div" variant="h1">
          Forgot Password
          </Typography>
          <div style={{marginLeft:'auto'}}>
            {/* <NavLink  to="/home">Back to home page</NavLink> */}
            <Box component="span" ml={2}>
                <Link href="/signin">
                Back to home page
                </Link>
              </Box>
          </div>
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <Box mb={5}>
          <TextField
            label={<IntlMessages id="appModule.email" />}
            fullWidth
            onChange={event => setEmail(event.target.value)}
            value={email}
            margin="normal"
            variant="outlined"
            className={classes.textFieldRoot}
            onKeyUp={e => {
              if (e.key == 'Enter') {
                onSubmit();
              }
            }}
          />
          {errors.email && <p className="text-danger ml-1">Valid email  is required</p>}
        </Box>
        <Box mb={5}>
          <Button onClick={onSubmit} variant="contained" color="primary">
            <IntlMessages id="appModule.resetPassword" />
          </Button>
        </Box>

        <Box>
          <Typography>
            Don't remember your email?
            <Box component="span" ml={2}>
              <Link href="#">
                <IntlMessages id="appModule.contactSupport" />
              </Link>
            </Box>
          </Typography>
        </Box>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
