import {
  SET_PROFESSION,
  GET_ALL_DIF_COST_SUCCESS,
  GET_ALL_COST_SUCCESS,
  CHANGE_COST_PARAMS,
  TOGGLE_MODAL,
  SELECT_COST,
  SET_LOCATION_EDIT,
} from '../constants/CostManagementAccionTypes';

const initialState = {
  professions: [],
  alldifcost: [],
  allcost: [],
  selectedCost: null,
  open: false,
  addressValue: {},
  params: {
    page: 0,
    perPage: 5,
    total: 40,
  },
};

export const CostManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_PROFESSION: {
      return {
        ...state,
        professions: payload,
      };
    }
    case GET_ALL_DIF_COST_SUCCESS: {
      return {
        ...state,
        alldifcost: payload.data,
        params: { ...state.params, total: payload.total },
      };
    }
    case GET_ALL_COST_SUCCESS: {
      return {
        ...state,
        allcost: payload.data,
        params: { ...state.params, total: payload.total },
      };
    }
    case CHANGE_COST_PARAMS: {
      return {
        ...state,
        params: { ...state.params, [payload.key]: payload.value },
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        open: payload,
      };
    }
    case SELECT_COST: {
      return {
        ...state,
        selectedCost: payload,
      };
    }
    case SET_LOCATION_EDIT: {
      return {
        ...state,
        addressValue: payload,
      };
    }
    default: {
      return state;
    }
  }
};
