import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import {
  SET_PROFESSIONS,
  SET_QUESTIONS,
  SET_CATEGORY,
  SET_MODAL,
  SELECT_QUESTION,
  SET_INDEX,
  SET_KEY,
} from '../constants/JobActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import moment from 'moment';
import sweetAlerts from '../../appmakers/components/SweetAlertHelper';

export const setProfessions = payload => {
  return {
    type: SET_PROFESSIONS,
    payload,
  };
};

export const setKey = payload => {
  return {
    type: SET_KEY,
    payload,
  };
};

export const setIndex = payload => {
  return {
    type: SET_INDEX,
    payload,
  };
};

export const selectQuestion = payload => {
  return {
    type: SELECT_QUESTION,
    payload,
  };
};

export const setQuestions = payload => {
  payload = payload.map(item => {
    if (item.questionType === 'range') item[`answer_${item.questionType}`] = item[`answer_${item.questionType}`] || 0;
    else if (item.questionType === 'radio') {
      item[`answer_${item.questionType}`] = item[`answer_${item.questionType}`] || null;
      item[`valueKey`] = item[`valueKey`] || null;
    } else item[`answer_${item.questionType}`] = item[`answer_${item.questionType}`] || '';
    item[`answer_${item.questionType}_other`] = item[`answer_${item.questionType}_other`] || '';
    return item;
  });
  return {
    type: SET_QUESTIONS,
    payload,
  };
};

export const setCategory = payload => {
  return {
    type: SET_CATEGORY,
    payload,
  };
};

export const setJobModal = payload => {
  return {
    type: SET_MODAL,
    payload,
  };
};

export const getProfessions = () => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/get-all-profession`)
      .then(response => {
        dispatch(setProfessions(response.data.data));
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      })
      .finally(() => dispatch(fetchSuccess()));
  };
};

const filterSingleQuestion = (allQuestions, key) => {
  let question = allQuestions.find(question => question.pushedQuestion === key);
  if (question) return question[`answer_${question.questionType}`];
  return null;
};

const prepareJobData = (dispatch, payload, authUser, validEmailEntered) => {
  let data = {};
  let selectedCategory = {
    answer_radio: payload.profession.name,
    choices: [],
    createdAt: moment(),
    pushedQuestion: 'Category',
    question: `What service do you need?`,
    questionType: 'radio',
    updatedAt: moment(),
  };
  let selectedAddress = {
    answer_radio: payload.address,
    choices: [],
    createdAt: moment(),
    pushedQuestion: 'Location',
    question: 'Where do you want the job done?',
    questionType: 'radio',
    updatedAt: moment(),
  };
  let objQuestions = [...payload.questions];
  objQuestions.unshift(selectedAddress);
  objQuestions.unshift(selectedCategory);
  const userInfoQuestions = ['Email', 'Posted By', 'Password', 'Confirm Password', 'Phone', 'Phone Recaptcha', 'Phone OTP'];
  if (authUser) {
    data.jobInfo = {
      user: authUser._id,
      address: payload.address,
      category: payload.profession._id,
      questionAnswers: objQuestions.filter(datum => !userInfoQuestions.includes(datum.pushedQuestion)),
    };
    if (!authUser?.phone) {
      data.phoneInfo = {
        userId: authUser?._id,
        phone: payload?.validPhone,
        isPhoneVerified: payload.isPhoneVerified,
      };
    } else if (authUser?.phone && !authUser?.isPhoneVerified) {
      data.phoneInfo = {
        userId: authUser?._id,
        phone: authUser?.phone,
        isPhoneVerified: payload.isPhoneVerified,
      };
    }
  } else {
    objQuestions = objQuestions.flat();
    data.jobInfo = {
      address: payload.address,
      category: payload.profession._id,
      questionAnswers: objQuestions.filter(datum => !userInfoQuestions.includes(datum.pushedQuestion)),
    };
    data.userInfo = {
      ...payload?.userInfo,
      dbPassword: payload?.userInfo.password,
      email: filterSingleQuestion(objQuestions, 'Email'),
      userName: filterSingleQuestion(objQuestions, 'Posted By'),
      password: filterSingleQuestion(objQuestions, 'Password'),
      confirmPassword: filterSingleQuestion(objQuestions, 'Confirm Password'),
      phone: payload?.validPhone,
      isPhoneVerified: payload.isPhoneVerified,
      validEmailEntered,
    };
  }
  return data;
};

const postJob = (dispatch, payload, authUser, validEmailEntered = false, getMyJobs = () => {}) => {
  let data = prepareJobData(dispatch, payload, authUser, validEmailEntered);
  http
    .post(`${rootUrl}/create-job`, data)
    .then(res => {
      if (res) {
        getMyJobs();
        sweetAlerts('Success Job', 'success');
        if (authUser) {
          if (payload?.userInfo?.isEmailVerified === false) {
            dispatch(setKey({ key: 'showEmailModal', value: true }));
          }
        } else {
          if (payload?.userInfo?.isEmailVerified === false) {
            dispatch(setKey({ key: 'showEmailModal', value: true }));
          }
        }
        // if (payload.phoneInfo.isEmailVerified === false || payload.userInfo.isEmailVerified === false) {
        //     dispatch(setKey({key: 'showEmailModal', value: true}))
        // }

        dispatch(setKey({ key: 'profession', value: null }));
        dispatch(setKey({ key: 'address', value: '' }));
        dispatch(setKey({ key: 'isPhoneVerified', value: false }));
        dispatch(setKey({ key: 'validPhone', value: '' }));
        dispatch(setKey({ key: 'showCode', value: false }));
        dispatch(setKey({ key: 'validEmailEntered', value: false }));
        dispatch(setKey({ key: 'questions', value: [] }));
        dispatch(setKey({ key: 'selectedQuestion', value: {} }));
        dispatch(setKey({ key: 'emailRequest', value: false }));
        dispatch(setKey({ key: 'showFirebaseRecaptcha', value: false }));
        dispatch(setKey({ key: 'index', value: 0 }));
        dispatch(setKey({ key: 'addressAutoCompleteKey', value: !payload?.addressAutoCompleteKey }));
        dispatch(setKey({ key: 'autocomplete', value: '' }));
        dispatch(
          setKey({
            key: 'userInfo',
            value: {
              email: '',
              isEmailVerified: false,
              new: false,
            },
          }),
        );
        dispatch(setKey({ key: 'isJobModal', value: false }));
        dispatch(setJobModal(false));
      }
    })
    .catch(error => {
      sweetAlerts(error.data, 'error');
    });
};
const verifyPasswordAction = payload => {
  return dispatch => {
    let data = {
      email: payload?.userInfo?.email,
      password: payload?.selectedQuestion[`answer_${payload?.selectedQuestion.questionType}`],
    };
    http
      .post(`${rootUrl}/verify-password`, data)
      .then(res => {
        if (res) {
          dispatch(setNextQuestion(payload));
        }
      })
      .catch(error => {
        sweetAlerts(error.data, 'error');
      });
  };
};
const setNextQuestion = payload => {
  return dispatch => {
    dispatch(selectQuestion(payload?.questions[payload?.index + 1]));
    dispatch(setIndex(payload?.index + 1));
    dispatch(setKey({ key: 'emailRequest', value: false }));
    if (payload?.selectedQuestion.pushedQuestion === 'Phone Recaptcha') dispatch(setKey({ key: 'showCode', value: true }));
    else if (payload?.selectedQuestion.pushedQuestion === 'Phone') {
      let ques = [...payload?.questions];
      if (!ques.find(i => i.pushedQuestion === 'Phone Recaptcha'))
        ques.splice(payload?.index + 1, 0, {
          _id: 130,
          question: `Verify your phone number`,
          questionType: 'phone_recaptcha',
          pushedQuestion: 'Phone Recaptcha',
        });
      ques.map(i => {
        if (i.pushedQuestion === 'Phone Recaptcha')
          i.answer_phone_recaptcha = payload?.selectedQuestion[`answer_${payload?.selectedQuestion.questionType}`];
        return i;
      });
    }
  };
};

const handleNext = (dispatch, payload, authUser) => {
  if (payload?.selectedQuestion.pushedQuestion === 'Email' && !payload.emailRequest) {
    http
      .get(`${rootUrl}/get-user-by-email`, {
        params: { userEmail: payload?.selectedQuestion.answer_input },
      })
      .then(res => {
        if (res.data) {
          if (!res.data.isEmailVerified) {
            dispatch(
              setKey({
                key: 'userInfo',
                value: { ...res.data, email: res.data.email, new: false, isEmailVerified: false },
              }),
            );
          } else {
            dispatch(
              setKey({
                key: 'userInfo',
                value: { email: res.data.email, new: false, isEmailVerified: true },
              }),
            );
          }
          let ques = [...payload?.questions];
          if (!res.data.password) {
            const pushedQuestions = [
              {
                _id: 123,
                question: 'Enter your Name',
                questionType: 'input',
                answer_input: '',
                pushedQuestion: 'Posted By',
              },
              {
                _id: 124,
                question: 'Enter your password',
                questionType: 'password',
                answer_password: '',
                pushedQuestion: 'Password',
              },
              {
                _id: 248,
                question: 'Confirm your password',
                questionType: 'password',
                answer_password: '',
                pushedQuestion: 'Confirm Password',
              },
            ];
            ques.splice(payload?.index + 1, 0, [...pushedQuestions]);
          } else {
            const passwordQuestion = {
              _id: 127,
              question: 'Enter your password',
              questionType: 'password',
              answer_password: '',
              pushedQuestion: 'Password',
            };
            ques.splice(payload?.index + 1, 0, passwordQuestion);
          }
          if (!res.data?.phone) {
            ques.splice(payload?.index + 2, 0, {
              _id: 129,
              question: 'Enter your phone number',
              questionType: 'input',
              answer_input: '',
              pushedQuestion: 'Phone',
            });
          } else if (res.data?.phone && !res.data?.isPhoneVerified) {
            if (!ques.find(i => i.pushedQuestion === 'Phone Recaptcha'))
              ques.splice(payload?.index + 2, 0, {
                _id: 130,
                question: `Verify your phone number`,
                questionType: 'phone_recaptcha',
                answer_phone_recaptcha: res.data?.phone,
                pushedQuestion: 'Phone Recaptcha',
              });
          }
          dispatch(setQuestions(ques));
          dispatch(selectQuestion(ques[payload?.index + 1]));
          dispatch(setKey({ key: 'emailRequest', value: true }));
          dispatch(setIndex(payload?.index + 1));
        } else {
          dispatch(
            setKey({
              key: 'userInfo',
              value: {
                email: filterSingleQuestion(payload.questions, 'Email'),
                new: true,
                isEmailVerified: false,
              },
            }),
          );
          dispatch(setKey({ key: 'emailRequest', value: true }));
          let ques = [...payload?.questions];

          const pushedQuestions = [
            {
              _id: 123,
              question: 'Enter your Name',
              questionType: 'input',
              answer_input: '',
              pushedQuestion: 'Posted By',
            },
            {
              _id: 124,
              question: 'Enter your password',
              questionType: 'password',
              answer_password: '',
              pushedQuestion: 'Password',
            },
            {
              _id: 248,
              question: 'Confirm your password',
              questionType: 'password',
              answer_password: '',
              pushedQuestion: 'Confirm Password',
            },
          ];
          ques.splice(payload?.index + 1, 0, [...pushedQuestions]);
          ques.splice(payload?.index + 2, 0, {
            _id: 129,
            question: 'Enter your Phone',
            questionType: 'input',
            answer_input: '',
            pushedQuestion: 'Phone',
          });
          dispatch(setQuestions(ques));
          dispatch(selectQuestion([...pushedQuestions]));
          dispatch(setKey({ key: 'emailRequest', value: true }));
          dispatch(setIndex(payload?.index + 1));
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  } else if (payload?.index <= payload?.questions.length && payload?.questions[payload?.index + 1]) {
    if (payload?.selectedQuestion.pushedQuestion === 'Password') {
      dispatch(verifyPasswordAction(payload));
    } else {
      dispatch(setNextQuestion(payload));
    }
  }
};

export const nextOrSubmitHandle = (authUser, getMyJobs) => {
  return (dispatch, getState) => {
    let payload = getState().JobReducer;
    if (
      (payload.index === payload.questions.length - 1 && payload.selectedQuestion.pushedQuestion !== 'Email') ||
      (payload.validPhone &&
        payload.validPhone === payload.selectedQuestion[`answer_${payload.selectedQuestion.questionType}`])
    ) {
      postJob(dispatch, payload, authUser, false, getMyJobs);
    } else {
      handleNext(dispatch, payload, authUser);
    }
  };
};
export const skipHandler = authUser => {
  return async (dispatch, getState) => {
    let payload = getState().JobReducer;
    if (
      authUser ||
      payload?.questions?.flat()?.find(i => i.pushedQuestion === 'Confirm Password') ||
      payload?.questions?.flat()?.find(i => i.pushedQuestion === 'Password')
    ) {
      await postJob(dispatch, payload, authUser);
      dispatch(setJobModal(false));
      dispatch(setQuestions([]));
      dispatch(setCategory(null));
      dispatch(selectQuestion([]));
      dispatch(setIndex(0));
      dispatch(setKey({ key: 'emailRequest', value: false }));
      dispatch(setKey({ key: 'profession', value: null }));
    } else handleNext(dispatch, payload);
  };
};

export const closePostJobModal = (validEmailEntered, authUser) => {
  return (dispatch, getState) => {
    let payload = getState().JobReducer;
    if (validEmailEntered) {
      postJob(dispatch, payload, authUser, validEmailEntered);
    } else {
      dispatch(setKey({ key: 'profession', value: null }));
      dispatch(setKey({ key: 'address', value: '' }));
      dispatch(setKey({ key: 'isPhoneVerified', value: false }));
      dispatch(setKey({ key: 'validPhone', value: '' }));
      dispatch(setKey({ key: 'showCode', value: false }));
      dispatch(setKey({ key: 'validEmailEntered', value: false }));
      dispatch(setKey({ key: 'questions', value: [] }));
      dispatch(setKey({ key: 'selectedQuestion', value: {} }));
      dispatch(setKey({ key: 'emailRequest', value: false }));
      dispatch(setKey({ key: 'showFirebaseRecaptcha', value: false }));
      dispatch(setKey({ key: 'index', value: 0 }));
      dispatch(setKey({ key: 'addressAutoCompleteKey', value: !payload?.addressAutoCompleteKey }));
      dispatch(
        setKey({
          key: 'userInfo',
          value: {
            email: '',
            isEmailVerified: false,
            new: false,
          },
        }),
      );
      dispatch(setJobModal(false));
    }
  };
};
