import React from 'react';
import { Typography } from '@material-ui/core';
import { BusinessCenter, CheckCircle } from '@material-ui/icons';

import CancelIcon from '@mui/icons-material/Cancel';

const Qualification = ({ selectedUser }) => {
  return (
    <div className="d-flex flex-col mt-4 ml-4 align-content-between pt-2 text-gray-800">
      <Typography component="h4" variant="h4">
        Qualifications
      </Typography>
      <div className="pr-2 pt-6">
        <Typography component="div" variant="body2">
          To meet our high standards, all verified business qualifications have been reviewed by TradingSeek.
        </Typography>
        <div className="d-flex flex-row justify-between m-4">
          <span className="text-gray-700 social-links-overflow" style={{ fontWeight: 'normal' }}>
            <BusinessCenter /> NZBN <span style={{ color: 'rgb(0, 145, 234)' }}>{selectedUser.businessNo}</span>
          </span>
          <span className="text-gray-700 social-links-overflow" style={{ fontWeight: 'normal', color: 'rgb(70,207,152)' }}>
            {selectedUser.isEmailVerified && (
              <>
                <CheckCircle /> <span>Verified</span>
              </>
            )}
          </span>
          <span className="text-gray-700 social-links-overflow" style={{ fontWeight: 'normal', color: 'rgb(222, 29, 29)' }}>
            {selectedUser.isEmailVerified != true && (
              <>
                <CancelIcon /> <span>Not Verified</span>
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Qualification;
