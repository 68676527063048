import React, { useState, useEffect } from 'react';
import CmtCard from '../../../../../@coremat/CmtCard';
import CmtCardContent from '../../../../../@coremat/CmtCard/CmtCardContent';
import CmtMediaObject from '../../../../../@coremat/CmtMediaObject';
import { Rating } from '@material-ui/lab';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl, frontendUrl } from "../../../../../api-handling/rootUrl";
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { Redirect } from 'react-router-dom'
import { useHistory } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { getChatUsers, getContactUsers, onUserSelect } from '../../../../../redux/actions/Chat';
const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quotesContent: {
    marginTop: '1vh',
  },
}));

const Quotes = ({ quotes, jobId, postedBy }) => {

  // console.log("quotes",quotes)
  // console.log("jobId",jobId)
  // console.log("postedBy",postedBy)

  const classes = useStyles();
  const [items, setItoms] = useState('');
  let history = useHistory();
  const dispatch = useDispatch();


  let [avgUserReviews, setAvgUserReviews] = useState(['']);
  let [userReviews, setUserReviews] = useState(['']);
  const openChat = async (senderId, jobId, receiverId) => {
    console.log("1", senderId)
    console.log("2", jobId)
    console.log("3", receiverId)
    await http.get(`${rootUrl}/conversation-by-id`, { params: { senderId, jobId, receiverId } }).then(data => {
      if (data.status === 200) {
        dispatch(onUserSelect(data.data));
        history.push('/inbox');

      }
    });
  }
  const openBusinessProfile = (senderId, jobId, receiverId) => {
    console.log("1", senderId)
    console.log("2", jobId)
    console.log("3", receiverId)
    history.push({
      pathname: `/business-details/${senderId}`,
    })
  }

  {
    quotes &&
      quotes.map((item, index) => {

        const getReviewsByUser = async () => {
          await http
            .get(`${rootUrl}/get-user-reviews/${item._id}`)
            .then(res => {
              setUserReviews(...userReviews, res.data.length);
              var totalSum = 0;
              setAvgUserReviews(...avgUserReviews, res.data?.reduce((total, next) => total + next.stars, 0) / res.data.length)
            })
            .catch(error => {
              console.log('Error: ', error);
            })
            .then(res => {
            });
        }
        getReviewsByUser();
      })
  }
  console.log(userReviews)

  return (
    <Grid container spacing={3} style={{ overflowY: 'auto', maxHeight: '444px', minHeight: '443px' }}>
      {quotes &&
        quotes.map((item, index) => {

          return (
            <Grid item sm={12} md={12} lg={6} >
              <CmtCard key={index}>
                <CmtCardContent className={`${classes.content} text-center `} style={{ height: '289px' }}>
                  <CmtMediaObject avatar={item.imageUrl ? item.imageUrl : '/broken-image.jpg'} avatarPos="center" ></CmtMediaObject>
                  <h5 className={classes.quotesContent}>{item.businessName}</h5>
                  <div
                    style={{ marginRight: 'auto', marginLeft: '16%' }}
                  //style={{marginLeft: '30px'}}
                  >
                    <Rating
                      className={classes.quotesContent}
                      name="half-rating-read"
                      defaultValue={item.reviews?.reduce((total, next) => total + next.stars, 0) / item.reviews?.length}
                      precision={0.5}
                      readOnly
                    />
                    <span style={{ position: 'absolute', margin: '5px 0px 0px 7px' }}> {item.reviews ? item.reviews?.length : 0} Reviews</span>
                  </div>
                  {item.mobile && <h6 className={classes.quotesContent}><CallRoundedIcon /> {item.mobile} </h6>}
                  {item.email && <h6 className={classes.quotesContent}><EmailRoundedIcon /> {item.email} </h6>}
                  {/* <Button className={classes.quotesContent} variant="contained" color="primary" onClick={()=>{openBusinessProfile(item._id,jobId,postedBy)}}>
                    View Profile
                  </Button>                  */}
                  {/* <Button className={classes.quotesContent} variant="contained" color="primary" onClick={()=>{openChat(item._id,jobId,postedBy)}}>
                    View Response
                  </Button> */}
                  <Button style={{ textTransform: 'capitalize' }} variant="contained" color="primary" onClick={() => window.open(`${frontendUrl}/business-details/${item.businessUrl}`, '_blank')}>
                    Public Profile
                  </Button>
                </CmtCardContent>
              </CmtCard>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default Quotes;
