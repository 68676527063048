import React, {useState} from 'react';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    Divider,
    FormControl,
    InputBase,
    InputLabel,
    NativeSelect,
    TextareaAutosize,
    TextField
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {withStyles} from "@material-ui/styles";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";
import {useHistory} from "react-router";
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        width: '40rem',
    },
    title: {
        textAlign: 'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
  

 

const RequestQuoteModal = ({open, quoteLimit,closeModal, jobId,setModal}) => {
    const classes = useStyles();
    const [selectedValue, setSelectedValue] = React.useState('Yes');

    const history = useHistory();
    const [reason, setReason] = React.useState('');
    const [textArea, setTextArea] = useState(false)
    // reason selector handler
  

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
      };  
    //submit handler
    const submitHandler = () => {
        
        if(selectedValue != "No"){
        http.patch(`${rootUrl}/update-job-status/${jobId}`, {
            quoteLimit: quoteLimit + 7,
        }).then(res => {
            closeModal()
            history.push('/my-jobs');
        }).catch(error => {
            console.log('error', error);
        })
    }else{
        closeModal()
    }
        
    }

    const cancelReasons = ['I need more quotes', 'I\'ve found someone to do the job', 'I want to close this job']
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={() => closeModal()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={classes.paper}>
                    <h3 className={classes.title}> Are You Looking For More Quote </h3>
                    <Divider/>
                    <FormControl className={classes.margin} >
                    <FormControlLabel
                    value="Yes"
                    control={<GreenRadio
                        checked={selectedValue === 'Yes'}
                        onChange={handleChange}
                        value="Yes"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Yes' }}
                    />}
                    label="Yes"
                    labelPlacement="Yes"
                    />
    <FormControlLabel
          value="No"
          control={<Radio
            checked={selectedValue === 'No'}
            onChange={handleChange}
            value="No"
            color="diffault"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'No' }}
        />}
          label="No"
          labelPlacement="No"
        />
                       
                        
                    </FormControl>
                    
                    <Button disabled={!selectedValue} style={{width: '100%', marginTop: '1vh'}} variant="contained"
                            color="primary" onClick={() => submitHandler()}>
                        Submit
                    </Button>

                </div>
            </Fade>
        </Modal>
    );
}

export default RequestQuoteModal;
