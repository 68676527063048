import React, { useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Paper, Card } from '@material-ui/core';
import { Management } from './management';
import { Admins } from '../admins/index';
import { ShowAdmins } from '../admins/show';

export const UserRoles = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (e, value) => {
    setValue(value);
  };

  return (
    <PageContainer>
      <Paper square className={'p-3'}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          textTransForm="capitalize"
          onChange={handleTabChange}
          aria-label="disabled tabs example">
          <Tab style={{ textTransform: 'capitalize', fontWeight: 'bold' }} label="Admin Users" value={0} />
          <Tab style={{ textTransform: 'capitalize', fontWeight: 'bold' }} label="Role Management" value={1} />
        </Tabs>
        {value === 0 && (
          <Card className={'m-2'}>
            <Admins />
            <ShowAdmins />
          </Card>
        )}
        {value === 1 && <Management />}
      </Paper>
    </PageContainer>
  );
};
