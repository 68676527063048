import { CHANGE_ADMIN_PARAMS, GET_ALL_ADMIN_SUCCESS, TOGGLE_MODAL, SELECT_ADMIN } from '../constants/AdminActionTypes';

const initialState = {
  params: {
    page: 0,
    perPage: 5,
    total: 40,
  },
  admins: [],
  selectedAdmin: null,
  open: false,
};

export const AdminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_ADMIN_SUCCESS: {
      return {
        ...state,
        admins: payload.data,
        params: { ...state.params, total: payload.total },
      };
    }
    case CHANGE_ADMIN_PARAMS: {
      return {
        ...state,
        params: { ...state.params, [payload.key]: payload.value },
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        open: payload,
      };
    }
    case SELECT_ADMIN: {
      return {
        ...state,
        selectedAdmin: payload,
      };
    }
    default: {
      return state;
    }
  }
};
