import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import ApmTable from '../../../../appmakers/components/ApmTableWithoutEdit';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateDefCost } from './UpdateDefCost';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

import {
  changeParams,
  getDifCosts,
  updateDiffCost,
  deleteDiffCost,
  deleteDiffCostMultiple,
} from '../../../../redux/actions/CostManagementAction';

// get query parameter string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));
export const ShowDifCost = () => {
  const dispatch = useDispatch();
  let { alldifcost, params } = useSelector(({ CostManagementReducer }) => CostManagementReducer);
  let { loading } = useSelector(({ common }) => common);
  useEffect(() => {
    dispatch(getDifCosts(params));
  }, [params.page, params.perPage]);

  const updateUserHandler = data => {
    dispatch(updateDiffCost(data));
  };

  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteHandler = data => {
    dispatch(deleteDiffCost(data));
  };

  const MultipleDeletehandler = data => {
    dispatch(deleteDiffCostMultiple(data));
  };

  const columns = [
    { title: 'Profession', field: 'name' },
    { title: 'Default Cost In $', field: 'credits' },
  ];

  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let actions = [
    {
      icon: DeleteOutline,
      tooltip: 'Delete',
      onClick: (event, rowData) => {
        MultipleDeletehandler(rowData);
      },
    },
  ];

  return (
    <div>
      <UpdateDefCost />
      {!loading && (
        <ApmTable
          columns={columns}
          data={alldifcost}
          title="All Professions Default Cost"
          params={params}
          actions={actions}
          name={'User Roles'}
          updateHandler={updateUserHandler}
          paramsHandler={paramsHandler}
          deleteHandler={deleteHandler}
        />
      )}
    </div>
  );
};
