import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import { SET_ALL_PROFESSION, CHANGE_CAT_PARAMS, SELECT_CAT, TOGGLE_MODAL } from '../constants/CategoryManagementActionTypes';
import { store } from 'App';
import sweetAlerts from 'appmakers/components/SweetAlertHelper';

export const setAllProfession = payload => {
  return {
    type: SET_ALL_PROFESSION,
    payload,
  };
};

export const toggleModal = value => {
  return {
    type: TOGGLE_MODAL,
    payload: value,
  };
};

export const changeParams = (key, value) => {
  return {
    type: CHANGE_CAT_PARAMS,
    payload: { key, value },
  };
};

export const selectCat = value => {
  return {
    type: SELECT_CAT,
    payload: value,
  };
};

export const getAllProfession = params => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/get-all-profession-list`, { params })
      .then(response => {
        console.log('res=', response.data);
        dispatch(setAllProfession(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const updateProfession = data => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/update-profession`, { data })
      .then(response => {
        sweetAlerts('Profession updated Successfully', 'success');
        const pageParams = store.getState().CategoryManagementReducer.params;
        dispatch(getAllProfession(pageParams));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts('Failed to update Profession', 'error');
        dispatch(fetchError());
      });
  };
};

export const DeleteProfession = params => {
  console.log('params', params);
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/delete-profession-by-id`, params)
      .then(response => {
        sweetAlerts('Profession deleted Successfully', 'success');
        const pageParams = store.getState().CategoryManagementReducer.params;
        dispatch(getAllProfession(pageParams));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts('Failed to update Profession', 'error');
        dispatch(fetchError());
      });
  };
};

export const DeleteProfessionMultiple = params => {
  const filteredParams = params.map(p => p._id);
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .post(`${rootUrl}/delete-profession-by-id-multiple`, filteredParams)
      .then(response => {
        sweetAlerts('Professions deleted Successfully', 'success');
        const pageParams = store.getState().CategoryManagementReducer.params;
        dispatch(getAllProfession(pageParams));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlerts('Failed to delete Profession', 'error');
        dispatch(fetchError());
      });
  };
};

export const getSearchedProfession = (params, searched) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/search-profession-by-all?searchText=${searched}`, { params })
      .then(response => {
        console.log('res=', response.data);
        dispatch(setAllProfession(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};
