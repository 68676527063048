import React, { useState } from 'react';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { http } from "../../../../../appmakers/services/http-service";
import { rootUrl } from "../../../../../api-handling/rootUrl";
import { StyleRoot } from 'radium';
import sweetAlerts from "../../../../../appmakers/components/SweetAlertHelper";

const useStyles = makeStyles({
    editPasswordFormRoot: {
        width: '100%', marginLeft: '5%'
    },
})
const editPwdFrm = {
    '@media (max-width: 600px)': {
        width: '90%', marginLeft: '6%'
    }
}
const EditProfileForm = ({ setFormFlagHandler, setPasswordLastChanged }) => {
    const classes = useStyles();
    let [oldpassword, setOldPwd] = useState("");
    let [newpassword, setNewPwd] = useState("");
    let [confirmpassword, setConfirmPwd] = useState("");

    let [values, setValues] = useState({
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false,
    });


    const handleClickShowOldPassword = () => {
        setValues({ ...values, showOldPassword: !values.showOldPassword });
    };
    const handleClickShowNewPassword = () => {
        setValues({ ...values, showNewPassword: !values.showNewPassword });
    };
    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };


    const submitHandler = () => {
        let user = JSON.parse(localStorage.getItem('adminUser'));
        let postData = {
            id: user._id,
            oldpassword: oldpassword,
            password: newpassword,
            confirmpassword: confirmpassword
        }
        http.post(`${rootUrl}/verify-and-update-password`, {
            data: postData
        }).then(res => {
            if (res) {
                if (res.data.responseCode == "400") {
                    sweetAlerts(res.data.message, 'error')
                } else {
                    sweetAlerts(res.data.message, 'success')
                    setFormFlagHandler(false)
                    const fetchPasswordLastChanged = () => {
                        let user = JSON.parse(localStorage.getItem('adminUser'));
                        let id = user._id;
                        http.post(`${rootUrl}/pwd-last-changed`, {
                            id
                        }).then(res => {
                            setPasswordLastChanged(res.data.PasswordLastChangeDate)
                        }).catch(error => {
                        })
                    }
                }
                //handleClose()
            }
        }).catch(error => {
            sweetAlerts(error.data.message, 'error')

        })
    }

    return (
        <StyleRoot>
            <div  >
                <form>
                    <Box mb={2}>
                        <TextField
                            label={<IntlMessages id="appModule.currentPassword" />}
                            fullWidth
                            value={oldpassword}
                            onChange={event => setOldPwd(event.target.value)}
                            margin="normal"
                            variant="outlined"
                            className={classes.textFieldRoot}
                            type={values.showOldPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowOldPassword}
                                        >
                                            {(values.showOldPassword) ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField
                            label={<IntlMessages id="appModule.newPassword" />}
                            fullWidth
                            value={newpassword}
                            onChange={event => setNewPwd(event.target.value)}
                            margin="normal"
                            variant="outlined"
                            className={classes.textFieldRoot}
                            type={(values?.showNewPassword) ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle new password visibility"
                                            onClick={handleClickShowNewPassword}
                                        >
                                            {values.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>

                    <Box mb={2}>
                        <TextField
                            label={<IntlMessages id="appModule.confirmPassword" />}
                            fullWidth
                            value={confirmpassword}
                            onChange={event => setConfirmPwd(event.target.value)}
                            margin="normal"
                            variant="outlined"
                            className={classes.textFieldRoot}
                            type={(values?.showConfirmPassword) ? 'text' : 'password'}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                        >
                                            {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                    </Box>
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        alignItems={{ sm: 'center' }}
                        justifyContent={{ sm: 'space-between' }}
                        mb={3}>
                        <Box mb={{ xs: 2, sm: 0 }}>
                            <Button variant="contained" color="primary" onClick={() => submitHandler(false)}>
                                <IntlMessages id="appModule.submit" />
                            </Button>&nbsp;
                            <Button variant="contained" onClick={() => setFormFlagHandler(false)}>
                                <IntlMessages id="appModule.cancel" />
                            </Button>
                        </Box>
                    </Box>
                </form>
            </div>
        </StyleRoot>
    );
}

export default EditProfileForm;
