import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getProfession, getCosts } from '../../../../redux/actions/CostManagementAction';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Modal from '@material-ui/core/Modal';
import Radium, { StyleRoot } from 'radium';
import searchLocations from '../../../../api-handling/fetchLocations';
import { store } from 'react-notifications-component';
import sweetAlertsApm from 'appmakers/components/SweetAlertHelper';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  let top = 40 + rand();
  let left = 45 + rand();
  let wid = 50;
  //alert(window.innerWidth );
  if (window.innerWidth < 600) {
    wid = 100;
    left = 0;
    top = 0;
  }
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${wid}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  profession: '',
  Location: '',
  price: 0,
};

export const AddCostWithLocation = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const { professions } = useSelector(({ CostManagementReducer }) => CostManagementReducer);
  const [data, setData] = useState({ ...resetForm });
  const [open, setOpen] = useState(false);
  const [profession, setProfession] = useState('');
  const [address, setAddress] = useState('');
  const [locations, setLocation] = useState([]);

  //validations
  const requiredFields = {
    profession: 'required',
    Location: 'required',
    price: 'required',
  };
  let handleLocationChange = async value => {
    // alert(value)
    if (value.length > 2) {
      //alert(value)
      await searchLocations(value).then(res => {
        if (res.length) {
          setLocation(res);
        }
      });
    }
  };
  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      confirmPassword: {
        // name the rule
        message: 'The :attribute does not match password',
        rule: (val, params, validator) => {
          return params[0] === val;
        },
        required: true, // optional
      },
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  //handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  const AddCost = () => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/add-profession-cost`, { profession: profession._id, location: address, price: data.price })
      .then(response => {
        setData({ ...resetForm });
        handleClose();
        sweetAlertsApm('Successfuly added New Cost', 'success');
        dispatch(getCosts());
        dispatch(fetchSuccess());
      })
      .catch(error => {
        handleClose();
        sweetAlertsApm('Failed to add Cost', 'error');
        dispatch(fetchError());
      });
  };

  useEffect(() => {
    dispatch(getProfession());
  }, []);

  return (
    <StyleRoot>
      <div>
        {permissions['User Management'] && permissions['User Management'].includes('create') && (
          <Button
            variant="contained"
            className={'m-3'}
            size={'large'}
            onClick={handleOpen}
            color="primary"
            style={{ textTransform: 'capitalize' }}>
            Add Cost
          </Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Card style={modalStyle}>
            <Card.Content className={'p-4 m-4'}>
              <Container>
                <Typography variant="h2" className="mt-5 ml-3 fw-bolder">
                  Add Cost
                </Typography>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <Autocomplete
                      className="mt-3"
                      id="combo-box-demo"
                      limitTags={10}
                      options={professions}
                      name="profession"
                      label="Profession"
                      variant="outlined"
                      //value={data.profession}
                      getOptionLabel={option => option.name || ''}
                      // onChange={handleChange}
                      onChange={(event, value) => setProfession(value)}
                      renderInput={params => (
                        <TextField {...params} label="Profession" variant="outlined" onChange={handleChange} />
                      )}
                    />
                    {/* {validationError('profession')} */}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <Autocomplete
                      className="mt-3"
                      id="combo-box-demo"
                      limitTags={10}
                      options={locations}
                      getOptionLabel={option => option.postcode + ', ' + option.locality + ', ' + option.territory}
                      //getOptionLabel={option => option.locality}
                      onChange={(event, value) => {
                        setAddress(value.postcode + ', ' + value.locality + ', ' + value.territory);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                          onChange={e => {
                            handleLocationChange(e.target.value);
                          }}
                        />
                      )}
                    />
                    {/* {validationError('profession')} */}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.price}
                      name={'price'}
                      label="Price In $"
                      variant="outlined"
                      type="number"
                    />
                    {/* {validationError('price')} */}
                  </div>
                </div>
              </Container>
              <div className={'d-flex justify-content-end'}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ textTransform: 'capitalize' }}
                  onClick={handleClose}
                  color="secondary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={'ml-2'}
                  style={{ textTransform: 'capitalize' }}
                  size="large"
                  onClick={AddCost}
                  //disabled={!validator.allValid()}
                  color="primary">
                  Create
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Modal>
      </div>
    </StyleRoot>
  );
};
