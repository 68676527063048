import React, {useEffect, useState} from "react";
import {Button, TextField, Typography} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import {makeStyles} from "@material-ui/styles";
import {Email, Person} from "@material-ui/icons";
import SimpleReactValidator from "simple-react-validator";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";
import {useDispatch, useSelector} from "react-redux";
import {fetchProfession, getAllProfessions} from "../../../../../redux/actions/BusinessDirectoryActions";

const useStyles = makeStyles((theme) => ({
    'Rating .MuiRating-label': {
        marginTop: '4px'
    }
}));

const Review = () => {
    const {selectedUser, professions,profession} = useSelector(({BusinessDirectoryReducer}) => BusinessDirectoryReducer);
    const dispatch = useDispatch();
    let [comment, setComment] = useState('')
    let [name, setName] = useState('')
    let [email, setEmail] = useState('')
    const [rating, setRating] = useState({text: 'Select your rating', score: 0});

    let validator = new SimpleReactValidator();

    useEffect(() => {
        getProfessions()
    }, [profession])


    let getProfessions = async () => {
        await http
            .get(`${rootUrl}/get-all-profession`)
            .then(res => {
                dispatch(getAllProfessions(res.data.data))
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .then(res => {
            });
    };

    const getProfession = async (professionId) => {
        await http
            .get(`${rootUrl}/get-profession/${professionId}`)
            .then(res => {
                dispatch(fetchProfession(res.data))
                // setIsJobModal(true);
            })
            .catch(error => {
                console.log('Error: ', error);
            })
            .then(res => {
            });
    }

    const submitForm = (e) => {
        // e.preventDefault()
        let data = {
            businessUserId: selectedUser?._id,
            comment: comment,
            stars: rating.score,
            profession: profession?._id,
            postedUserName: name,
            postedUserEmail: email,

        }
        if (validator.allValid()) {
            http.post(`${rootUrl}/post-review`, {
                data: data
            }).then(res => {
                setRating({text: 'Select your rating', score: 0})
                setComment('');
                setName('');
                setEmail('');
            }).catch(error => {

            });
        } else {
            validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
        }
    }

    const ratingChanged = (e) => {
        if (e == 5) {
            setRating({text: "Excellent", score: parseInt(e)})
        } else if (e == 4.5 || e == 4) {
            setRating({text: "Very Good", score: parseInt(e)})
        } else if (e == 3 || e == 3.5) {
            setRating({text: "Average", score: parseInt(e)})
        } else if (e == 2 || e == 2.5 || e == 1.5) {
            setRating({text: "Poor", score: parseInt(e)})
        } else if (e == 1 || e == 0.5) {
            setRating({text: "Terrible", score: parseInt(e)})
        }
    }
    return (
        <>
            <div className="d-flex flex-col mt-4 ml-4 align-content-between pt-2 text-gray-800">
                <Typography component="h4" variant="h4">Add your review</Typography>
                <div className="d-flex flex-row justify-content-between align-items-center w-75 pr-2 pt-6">
                    <Rating name="half-rating" value={rating.score} style={{fontSize: 'xxx-large'}}
                            onChange={(e) => {
                                ratingChanged(e.target.value)
                            }}/>
                    <Typography component="div" variant="body2" className="text-gray-700">{rating.text}</Typography>
                </div>
            </div>
            {rating.score > 0 &&
            <div className="p-3">
                <div className="input-group mb-3">
                <textarea className="form-control"
                          placeholder="Share your experience with other TradingSeek customers"
                          value={comment} onChange={(e) => setComment(e.target.value)}
                ></textarea>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text"><Person/></span>
                    <input type="text" className="form-control" placeholder="Please enter you name"
                           value={name} onChange={(e) => setName(e.target.value)}
                    />
                </div>
                {validator.message('name', name, 'required|min:3')}

                <div className="input-group mb-3">
                    <span className="input-group-text"><Email/></span>
                    <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)}
                           placeholder="Please enter your email"/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlSelect1">Select Profession</label>
                    <select className="form-control" placeholder="select profession" onChange={e=> getProfession(e.target.value)}>
                        {professions?.map(item => {
                            return <option value={item._id}>{item.name}</option>
                        })}
                    </select>
                </div>
                {validator.message('email', email, 'required|email')}
                <Button variant="contained" color="primary" fullWidth onClick={submitForm}>Publish</Button>
            </div>
            }
        </>
    )
}

export default Review
