import { fetchError, fetchStart, fetchSuccess } from './Common';
import { http } from '../../appmakers/services/http-service';
import { rootUrl } from '../../api-handling/rootUrl';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import {
  GET_PROFESSION,
  SET_PROFESSION,
  GET_ALL_DIF_COST_SUCCESS,
  GET_ALL_COST_SUCCESS,
  CHANGE_COST_PARAMS,
  TOGGLE_MODAL,
  SELECT_COST,
  SET_LOCATION_EDIT,
} from '../constants/CostManagementAccionTypes';
import { store } from 'App';
import searchLocations from '../../api-handling/fetchLocations';
import sweetAlertsApm from 'appmakers/components/SweetAlertHelper';

export const setProfession = payload => {
  return {
    type: SET_PROFESSION,
    payload,
  };
};

export const changeParams = (key, value) => {
  return {
    type: CHANGE_COST_PARAMS,
    payload: { key, value },
  };
};

export const toggleModal = value => {
  return {
    type: TOGGLE_MODAL,
    payload: value,
  };
};

export const setLocationEdit = payload => {
  console.log(payload);
  return {
    type: SET_LOCATION_EDIT,
    payload,
  };
};

export const selectDifLocation = selectedCost => {
  console.log(selectedCost);

  return dispatch => {
    dispatch(fetchStart());
    searchLocations(selectedCost.location).then(res => {
      console.log('sesssss', res[0]);
      dispatch(fetchSuccess());
      dispatch(setLocationEdit(res[0]));
    }).catch(error => {
      dispatch(fetchError());
    });
    //dispatch(fetchSuccess());
  };
};

export const selectCost = value => {
  return {
    type: SELECT_COST,
    payload: value,
  };
};

export const getProfession = () => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/get-all-profession`)
      .then(response => {
        console.log('res=', response.data.data);
        dispatch(setProfession(response.data.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};
export const getAllDifCostsSuccess = payload => {
  return {
    type: GET_ALL_DIF_COST_SUCCESS,
    payload,
  };
};

export const getDifCosts = params => {
  console.log('get costs');
  return dispatch => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/get-all-profession-dif-cost`, { params })
      .then(response => {
        console.log('inside costs');
        console.log(response.data);
        dispatch(getAllDifCostsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const getAllCostsSuccess = payload => {
  return {
    type: GET_ALL_COST_SUCCESS,
    payload,
  };
};

export const getCosts = params => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/get-all-profession-cost`, { params })
      .then(response => {
        //console.log(response);
        dispatch(getAllCostsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

export const updateDiffCost = data => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/profession-dif-cost-update`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        console.log('params', params);
        sweetAlertsApm('Default Cost updated Successfully', 'success');
        dispatch(getDifCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to update Default Cost', 'error');
        dispatch(fetchError());
      });
  };
};

export const deleteDiffCost = data => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/profession-dif-cost-delete`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        console.log('cost deleted');
        sweetAlertsApm('Default Cost deleted Successfully', 'success');
        dispatch(getDifCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to delete', 'error');
        dispatch(fetchError());
      });
  };
};

export const deleteDiffCostMultiple = data => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/profession-dif-cost-delete-multiple`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        sweetAlertsApm('Default Cost deleted Successfully', 'success');
        dispatch(getDifCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to delete', 'error');
        dispatch(fetchError());
      });
  };
};

export const updateCost = data => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .put(`${rootUrl}/profession-cost-update`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        console.log('response', response.status);
        sweetAlertsApm('Cost updated Successfully', 'success');
        dispatch(getCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to update', 'error');
        dispatch(fetchError());
      });
  };
};

export const deleteCost = data => {
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/profession-cost-delete`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        sweetAlertsApm('Cost deleted Successfully', 'success');
        dispatch(getCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to delete', 'error');
        dispatch(fetchError());
      });
  };
};

export const deleteMultipleCost = data => {
  console.log('data', data);
  return dispatch => {
    dispatch(fetchStart());
    http
      .post(`${rootUrl}/profession-cost-delete-multiple`, { data })
      .then(response => {
        const { params } = store.getState().CostManagementReducer;
        sweetAlertsApm('Cost deleted Successfully', 'success');
        dispatch(getCosts(params));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        sweetAlertsApm('Failed to delete', 'error');
        dispatch(fetchError());
      });
  };
};

export const getSearchedCost = (params, searched) => {
  return async dispatch => {
    dispatch(fetchStart());
    await http
      .get(`${rootUrl}/search-cost-by-all?searchText=${searched}`, { params })
      .then(response => {
        console.log('res=', response.data);
        dispatch(getAllDifCostsSuccess(response.data));
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
      });
  };
};

