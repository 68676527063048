import { SET_ALL_PROFESSION, CHANGE_CAT_PARAMS, SELECT_CAT, TOGGLE_MODAL } from '../constants/CategoryManagementActionTypes';

const initialState = {
  professions: [],
  selectedCat: {},
  params: {
    page: 0,
    perPage: 5,
    total: 40,
  },
  open: false,
};

export const CategoryManagementReducer = (state = initialState, { type, payload }) => {
  //console.log(payload?.total);
  switch (type) {
    case SET_ALL_PROFESSION: {
      return {
        ...state,
        professions: payload.data,
        params: { ...state.params, total: payload.total },
      };
    }
    case CHANGE_CAT_PARAMS: {
      return {
        ...state,
        params: { ...state.params, [payload.key]: payload.value },
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        open: payload,
      };
    }
    case SELECT_CAT: {
      //return Object.assign({}, { ...state }, { selectedCat: payload });
      return {
        ...state,
        selectedCat: payload,
      };
    }
    default: {
      return state;
    }
  }
};
