import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'semantic-ui-react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import '../../../../css/CategoryManagement/updateQuestions.css';

import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';

let answerType1 = ['input', 'textarea', 'radio', 'range'];
export const Question = ({ question, handleChange, index, removeQa }) => {
  let [isRedio, setRedio] = useState(question.questionType);
  let [newOption, setNewOption] = useState('');
  let [newQuestion, setNewQuestion] = useState(question);
  let [choices, setChoices] = useState(question.choices);
  const AutocompleteRef = useRef();

  let handleDelete = choiceId => {
    choices = choices.filter(item => item._id !== choiceId);
    setChoices([...choices]);
    newQuestion.choices = [...choices];
    setNewQuestion({ ...newQuestion });
    console.log('handleDelete =', newQuestion);
    handleChange(newQuestion);
  };

  let handleqnChange = e => {
    newQuestion.question = e.target.value;
    setNewQuestion({ ...newQuestion });
    console.log('handleqnChange =', newQuestion);
    handleChange(newQuestion);
  };
  let handleTypeChange = value => {
    newQuestion.questionType = value;
    console.log('handleTypeChange =', newQuestion);
    setNewQuestion({ ...newQuestion });
    handleChange(newQuestion);
  };
  let handleChoiceChange = () => {
    setChoices([...choices, { _id: choices.length, option: newOption }]);
    setNewOption('');
    newQuestion.choices = [...choices, { _id: choices.length, option: newOption }];
    setNewQuestion({ ...newQuestion });
    console.log(newQuestion);
    handleChange(newQuestion);
  };

  return (
    <div>
      <div className="queation_and_type">
        <div className={'questions'}>
          <b>
            <label className="mt-6 d-block"> Questions </label>{' '}
          </b>
          <TextField
            id="outlined-search"
            onChange={e => {
              console.log(e.target.value);
            }}
            defaultValue={question?.question}
            fullWidth
            //value={data.price?data.price:selectedCat?.price}
            onChange={event => handleqnChange(event)}
            name={question?.question}
            label="Categorie Name"
            variant="outlined"
          />
        </div>
        <div className={'type'}>
          <b>
            <label className="mt-6 d-block"> Answer Type </label>{' '}
          </b>
          <Autocomplete
            className="mt-3"
            id="combo-box-demo"
            limitTags={10}
            options={answerType1}
            name="AnswerType"
            label="Answer Type"
            variant="outlined"
            fullWidth
            //value={data.profession}
            //getOptionLabel={option => option.name || ''}
            //value={profession?profession:selectedCat?.profession}
            defaultValue={question.questionType ? question.questionType : ''}
            // onChange={handleChange}
            onChange={(event, value) => {
              setRedio(value);
              handleTypeChange(value);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Answer Type"
                variant="outlined"
                //onChange={handleChange}
              />
            )}
          />
        </div>
        <div className={'remove'}>
          <b>
            <label className="mt-6 d-block"> Remove </label>
          </b>
          <DeleteIcon
            className="removeqn"
            onClick={e => {
              removeQa(index);
            }}
          />
        </div>
      </div>
      {isRedio === 'radio' && (
        <div>
          <b>
            <label className="mt-6 d-block"> Choices </label>
          </b>

          <TextField
            id="outlined-search"
            style={{ width: '60%' }}
            value={newOption}
            //value={data.price?data.price:selectedCat?.price}
            onChange={event => {
              setNewOption(event.target.value);
            }}
            name={'Choices'}
            label="Choice"
            variant="outlined"
          />
          <Button
            variant="contained"
            color="success"
            className="addOption"
            onClick={e => {
              handleChoiceChange();
            }}>
            Add
          </Button>
        </div>
      )}
      {choices?.length > 0 && isRedio === 'radio' && (
        <div>
          <ul className="d-flex">
            {choices.map((choice, index) => {
              return (
                <li key={index} className={'choices'}>
                  <Chip
                    label={choice.option}
                    variant="outlined"
                    deleteIcon={<DeleteIcon style={{ color: '#dd3f3f' }} />}
                    onDelete={() => {
                      handleDelete(choice._id);
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
