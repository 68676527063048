import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { Card, FormInput, Form } from 'semantic-ui-react';
import { Box, Input, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createUserRole, getUserRoles, selectUserRole } from '../../../../redux/actions/UserRoleActions';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '2em',
    padding: '2em',
  },
  button: {
    marginLeft: '2em',
  },
}));
export const CreateUserRole = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [title, setTitle] = useState();

  const handleChange = e => setTitle(e.target.value);
  const create = () => {
    dispatch(createUserRole(title));
    dispatch(getUserRoles());
    setTitle('');
  };

  return (
    <Card className={classes.container}>
      <Card.Content>
        <Input placeholder={'Role Name'} value={title} onChange={handleChange} />
        <Button
          variant="contained"
          className={classes.button}
          style={{ textTransform: 'capitalize' }}
          disabled={!title}
          onClick={create}
          color="primary">
          Create
        </Button>
      </Card.Content>
    </Card>
  );
};
