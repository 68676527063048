import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import moment from "moment";

const OpeningHours = ({user}) => {
    const [data,setData]=useState(user);

    useEffect(()=>{
        setData(user)
    },[user])

    return (
        <div className="d-flex flex-col ml-4 align-content-between pt-2 text-gray-800">
            <Typography component="h4" variant="h4">Business Opening hours</Typography>
            {data?.openingHours?.map(obj => {
                return <div
                    className="d-flex justify-content-between align-items-center w-50 text-gray-700 pt-3 pl-3">
                    <Typography variant="body2" className="font-weight-bolder">{obj.day}</Typography>
                    {data?.businessAlwaysOpen &&
                    <Typography variant="body2" className="text-gray-700 font-weight-bolder">
                        24 hours service
                    </Typography>
                    }
                    {!data?.businessAlwaysOpen && !obj.isClosed &&
                    <Typography variant="body2" className="text-gray-700 font-weight-bolder">
                        {moment(obj.opening).format('h:mm a')} - {moment(obj.closing).format('h:mm a')}
                    </Typography>
                    }
                    {!data?.businessAlwaysOpen && obj.isClosed &&
                    <Typography variant="body2" className="text-danger font-weight-bolder">
                        Closed
                    </Typography>
                    }
                </div>
            })}
        </div>
    )
}

export default OpeningHours;
