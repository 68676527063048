import React, { useEffect, useState } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Button } from '@material-ui/core/';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import moment from 'moment';
import { blue } from '@material-ui/core/colors';
import { useHistory } from 'react-router';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

export default function ResponsiveTable(props) {
  const history = useHistory();
  const detailview = item => {
    console.log(item);
    let data = {
      job: item,
    };

    history.push({
      pathname: '/jobs-details',
      state: data, // your data array of objects
    });
  };
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Category</Th>
          <Th>Posted on</Th>
          <Th>No of Quotes</Th>
          <Th>Status</Th>
          <Th>Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {props?.jobs?.map((item, index) => {
          let jobName = '';
          item.questionAnswers.find(item1 => {
            if (item1.pushedQuestion === 'Category') {
              jobName = item1.answer_radio;
            }
          });
          return (
            <Tr>
              <Td
                className={'link'}
                onClick={() => {
                  detailview(item);
                }}>
                {jobName}
              </Td>
              <Td>{moment(item.createdAt).format('MMM DD, YYYY')}</Td>
              <Td>
                {item.usersQuoted?.length}/{item.quoteLimit}
              </Td>
              <Td>{item.status}</Td>
              <Td>
                <Button
                  className={'viewbtn capitalize'}
                  variant="contained"
                  onClick={() => {
                    detailview(item);
                  }}>
                  {' '}
                  <RemoveRedEyeIcon /> View{' '}
                </Button>
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
