import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './SweetAlertHelper.css'
const MySwal = withReactContent(Swal);

const sweetAlertsApm = (text, variant) => {
  let title = '';
  if (variant == 'success' || variant == 'Success') {
    title = "<h2 style='color:green'>" + 'Success' + '</h1>';
  } else if (variant == 'error') {
    title = "<h2 style='color:red'>" + 'Error' + '</h1>';
  } else {
    title = variant;
  }
  let timerInterval;
  MySwal.fire({
    icon: variant,
    title: title,
    position: 'top',
    html: text,
    timer: 3000,
    customClass: 'swal-wide',
    timerProgressBar: true,
    showConfirmButton: false,
    onBeforeOpen: () => {
      //Swal.showLoading();
      timerInterval = setInterval(() => {
        const content = Swal.getContent();
        if (content) {
          const b = content.querySelector('b');
          if (b) {
            b.textContent = Swal.getTimerLeft();
          }
        }
      }, 100);
    },
    onClose: () => {
      console.log('kewrfnpnv');
      clearInterval(timerInterval);
    },
  }).then(result => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log('I was closed by the timer');
    }
  });
};
export const sweetAlertsOkBtn = (text, variant, title = null) => {
  MySwal.fire({
    icon: variant,
    title: title ? title : variant.charAt(0).toUpperCase() + variant.slice(1),
    text: text,
  });
};
export default sweetAlertsApm;
