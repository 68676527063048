import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import ApmTable from 'appmakers/components/ApmTableUserRoles';
// import ApmTable from '../../../../appmakers/components/ApmTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeParams,
  deleteAdmin,
  getAllUsersByDate,
  getAllUsersByDateType,
  getAdmins,
  updateAdmin,
  toggleModal,
  selectAdmin,
  deleteMultipleAdmin,
} from '../../../../redux/actions/AdminActions';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { UpdateAdminRoles } from './UpdateRoles';

// get query parameter string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

export const ShowAdmins = () => {
  const dispatch = useDispatch();
  let { admins, params } = useSelector(({ AdminReducer }) => AdminReducer);
  let { loading } = useSelector(({ common }) => common);

  useEffect(() => {
    dispatch(getAdmins(params));
  }, [params.page, params.perPage]);

  const updateUserHandler = user => {
    dispatch(updateAdmin(user));
  };

  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteHandler = admin => {
    dispatch(deleteAdmin(admin._id));
  };

  const deleteMultipleHandler = admins => {
    const adminIds = admins.map(admin => admin._id);
    dispatch(deleteMultipleAdmin(adminIds));
  };

  const columns = [
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'Email', field: 'email' },
    { title: 'Mobile', field: 'mobile' },
    { title: 'Registration', field: 'registrationDate' },
    // { title: 'Actions', field: '' },
  ];

  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let actions = [];
  let permission = permissions['User Roles'];
  if (permission && permission.includes('edit')) {
    actions = [
      {
        icon: SystemUpdateAltIcon,
        tooltip: 'Update Roles',
        onClick: (event, rowData) => {
          dispatch(selectAdmin(rowData));
          dispatch(toggleModal(true));
        },
      },
      {
        icon: DeleteOutline,
        tooltip: 'Delete',
        onClick: (event, rowData) => {
          deleteMultipleHandler(rowData);
        },
      },
    ];
  }
  console.log('actions', actions);

  return (
    <div>
      <UpdateAdminRoles />
      {!loading && (
        <ApmTable
          columns={columns}
          data={admins}
          title="All Users"
          params={params}
          name={'User Roles'}
          actions={actions}
          updateHandler={updateUserHandler}
          paramsHandler={paramsHandler}
          deleteHandler={deleteHandler}
        />
      )}
    </div>
  );
};
