import {CHANGE_USERS_PARAMS, GET_ALL_USERS_SUCCESS, TOGGLE_MODAL, SELECT_USER} from "../constants/userManagementActionTypes";

const initialState = {
    params: {
        page: 0,
        perPage: 5,
        total: 40
    },
    users: [],
    selectedUser:null,
    open:false,
};

export const UserManagementReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case GET_ALL_USERS_SUCCESS: {
            return {
                ...state, users: payload.data, params: {...state.params, total: payload.total}
            }
        }
        case CHANGE_USERS_PARAMS: {
            return {
                ...state, params: {...state.params, [payload.key]: payload.value}
            }
        }
        case TOGGLE_MODAL: {
            return {
                ...state, open:payload
            }
        }
        case SELECT_USER: {
            return {
                ...state, selectedUser:payload
            }
        }
        default: {
            return state;
        }
    }
}
