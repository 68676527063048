import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { fade } from '@material-ui/core';

import IntlMessages from '../../../utils/IntlMessages';
import Button from '@material-ui/core/Button';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { NavLink } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import { useLocation } from 'react-router';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import PasswordInput from '../../../../routes/Pages/Common/JobModal/PasswordInput';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  textCapital: {
    textTransform: 'capitalize',
  },
  textAcc: {
    textAlign: 'center',
    '& a': {
      marginLeft: 4,
    },
  },
  alrTextRoot: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const query = useQuery();
  const [email, setEmail] = useState('');
  const [id, setId] = useState(query.get('email'));
  const [userToken, setUserToken] = useState(query.get('userToken'));



  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    confirmPassword: null,
    password: null,
  });

  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    let err = {};
    err.password = !password ? true : null;
    err.confirmPassword = password !== confirmPassword ? true : null;
    setErrors(err);
    if (!Object.values(err).includes(true)) {
      updateUserByEmail();
    }
  };

  const updateUserByEmail = async () => {
    await http
      .post(`${rootUrl}/update-user-by-email`, {
        email,
        password,
        userToken,
      })
      .then(res => {
        if (Object.keys(res.data).length) {
          getUserById(id);
          let mail = res.data.email;
          sweetAlerts('Password reset successfully', 'success');
        }
      })
      .catch(error => {
        console.log('error', error.data);
        sweetAlerts(error.data, 'error');
      });
  };

  const getUserById = (id) => {
    http
      .get(`${rootUrl}/adminuser/${id}`)
      .then(res => {
        if (!Object.keys(res.data).length || (Object.keys(res.data).length && res.data.password)) {
          setEmail(res.data.email)
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  useEffect(() => {
    getUserById(id);
    document.body.classList.add('no-header');
    return function () {
      document.body.classList.remove('no-header');
    };
  }, [id]);

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={process.env.REACT_APP_LOGO_URL} alt="logo" color="black" />
        </Box>
        <NotificationContainer />
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset your password
        </Typography>
        <form>
          <Box mb={2}>
            <PasswordInput onChange={value => setPassword(value)} val={password} label="Password" />
            {errors.password && <p className="text-danger ml-1">Password field is required</p>}
            {errors.password == null && password.length < 5 && password.length > 0 && <p className="text-danger ml-1">The password must be at least 5 characters.</p>}
            {errors.password == null && password.length > 10 && <p className="text-danger ml-1">The password may not be greater than 10 characters.</p>}
          </Box>
          <Box mb={2}>
            <PasswordInput onChange={value => setConfirmPassword(value)} val={confirmPassword} label="Confirm Password" />
            {errors.confirmPassword && <p className="text-danger ml-1">Passwords does not match</p>}
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ sm: 'center' }}
            justifyContent={{ sm: 'space-between' }}
            mb={3}>
            <Box mb={{ xs: 2, sm: 0 }}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.reset" />
              </Button>
            </Box>
          </Box>
        </form>

        <Typography className={classes.textAcc}>
          Have an account?
          <NavLink to="/signin" color="primary">
            Sign In
          </NavLink>
        </Typography>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
