import { locationsJSON } from '../constants/DataBasedOnUrl'




const searchLocalLocations = async (searchValue) => {
    // let matches = await locationsJSON.filter(item => {
    //     return (item.locality)
    // });
    let matches = await locationsJSON.filter(obj =>
        Object.values(obj).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
        )
    );
    return matches;
};

export default searchLocalLocations;
