import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles } from '../../../../redux/actions/UserRoleActions';
import Modal from '@material-ui/core/Modal';
import { updateAdmin, toggleModal } from '../../../../redux/actions/AdminActions';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 40 + rand();
  const left = 45 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    width: '50%',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  roleIds: [],
  userRoles: false,
};

export const UpdateAdminRoles = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  let { allUserRoles } = useSelector(({ UserRoleReducer }) => UserRoleReducer);
  let { open, selectedAdmin } = useSelector(({ AdminReducer }) => AdminReducer);
  const [data, setData] = useState({ ...resetForm });

  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevData = usePrevious(data);
  //validations
  const requiredFields = {
    roleIds: 'required:length',
  };

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  //handlers
  const handleOpen = () => {
    dispatch(toggleModal(true));
  };

  const handleClose = () => {
    dispatch(toggleModal(false));
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  const handleRoleChange = (value, _id) => {
    let roleIds = [...data.roleIds];
    if (!value) roleIds = roleIds.filter(i => i !== _id);
    else roleIds.push(_id);
    setData({ ...data, roleIds });
  };

  const update = () => {
    dispatch(updateAdmin({ ...selectedAdmin, roles: data.roleIds }));
    handleClose();
  };

  const isChecked = item => {
    return !!data.roleIds.find(i => i == item);
  };

  useEffect(() => {
    if (!allUserRoles.length) {
      dispatch(getUserRoles());
    }
    validator.showMessages();
    console.log('sele', selectedAdmin);
    if (selectedAdmin && selectedAdmin.roles && selectedAdmin.roles.length && !data.userRoles) {
      let obj = { ...data };
      obj.roleIds = selectedAdmin.roles.map(i => i._id);
      if (prevData != obj) {
        setData({ ...obj, userRoles: true });
      }
    }
  }, [data, selectedAdmin]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Card style={modalStyle}>
          <Card.Content className={'p-4 m-4'}>
            <Container>
              <Typography className={'mb-3'} variant="h2">
                Roles
              </Typography>
              {validationError('roleIds')}
              <div className={'w-50 p-0'} style={{ marginLeft: '1em' }}>
                <Grid container className={'p-0'} spacing={1}>
                  {allUserRoles.map(item => {
                    return (
                      <Grid item xs={12} key={item._id}>
                        <FormControlLabel
                          label={item.title}
                          control={
                            <Checkbox
                              checked={isChecked(item._id)}
                              onChange={event => handleRoleChange(event.target.checked, item._id)}
                              color="primary"
                            />
                          }
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Container>
            <div className={'d-flex justify-content-end'}>
              <Button
                variant="contained"
                size="large"
                style={{ textTransform: 'capitalize' }}
                onClick={handleClose}
                color="secondary">
                Cancel
              </Button>
              <Button
                variant="contained"
                className={'ml-2'}
                size="large"
                style={{ textTransform: 'capitalize' }}
                onClick={update}
                disabled={!validator.allValid()}
                color="primary">
                Update
              </Button>
            </div>
          </Card.Content>
        </Card>
      </Modal>
    </div>
  );
};
