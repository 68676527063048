import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import DateSelector from './DateSelector';
import TextAreaField from './TextAreaField';
import TextFieldComponent from './TextFieldComponent';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { http } from '../../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../../api-handling/rootUrl';
import { Button, Divider, TextareaAutosize, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import BudgetInput from './BudgetInput';

import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import searchLocations from '../../../../../api-handling/fetchLocations';
import { fetchStart, fetchSuccess } from '../../../../../redux/actions/Common';
import { selectDifLocation, setLocationEdit } from '../../../../../redux/actions/CostManagementAction';
import { store } from 'react-notifications-component';
import moment from 'moment';
import Quotes from './Quotes';
import UserDetails from './UserDetails';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import sweetAlerts from "../../../../../appmakers/components/SweetAlertHelper";

const statusArray = [
  { status: 'PENDING' },
  { status: 'CLOSED' },
  { status: 'I need more quotes' },
  { status: "I've found someone to do the job" },
  { status: 'I want to close this job' },
];

const useStyles = makeStyles(theme => ({
  inputField: {
    // marginLeft: '2em',
    marginTop: '1em',
  },
  half: {
    width: '50%',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const JobDetails = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const item = location.state.job;
  console.log('itemp', item);
  return <JobDetailsChild item={item} />;
};

export const JobDetailsChild = ({ item }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  //  const item = location.state.job;
  //  console.log("item",item)

  const userData = location.state.job.user[0]; //.postUserId[0];
  console.log('itemc', item);

  //console.log("item",userData)
  let description = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'Description') {
      description = item.answer_textarea ? item.answer_textarea : '';
    }
  });

  let When = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'When') {
      When = item.answer_radio ? item.answer_radio : '';
    }
  });

  let Where = '';
  let DefaultDate = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'Where') {
      Where = item.answer_radio ? item.answer_radio : '';
      DefaultDate = item.answer_radio_other ? item.answer_radio_other : '';
    }
  });

  let DefaultBudget = '';
  let DefaultPrice = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'Default Budget') {
      DefaultBudget = item.answer_radio ? item.answer_radio : '';
      DefaultPrice = item.answer_radio_other ? item.answer_radio_other : '';
    }
  });

  let Location = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'Location') {
      Location = item.answer_radio ? item.answer_radio : '';
    }
  });

  const { addressValue } = useSelector(({ CostManagementReducer }) => CostManagementReducer);
  const { others } = useSelector(({ JobReducer }) => JobReducer);

  const resetForm = {
    Status: item.status,
    Description: description,
    Location: Location,
    Where: Where,
    When: When,
    date: DefaultDate,
    DefaultBudge: DefaultBudget,
    PhoneNumber: item.postUserMobile,
    price: DefaultPrice,
    _id: item._id,
  };
  let [descInput, setDescInput] = useState(false);
  const [anchorEl, setAnchorEl] = useState(0);
  const [msg, setMsg] = useState(item.status);
  const [data, setData] = useState({ ...resetForm });
  const [locations, setLocation] = useState([]);
  const [address, setAddress] = useState('');
  let [budgetType, setBudgetType] = useState('');
  let [textareaFlag, setTextareaFlag] = useState(false);
  let [budgetFlag, setBudgetFlag] = useState(false);

  let [questions, setQuestions] = useState([]);
  const open = Boolean(anchorEl);
  useEffect(() => {
    let loc = {};
    item.questionAnswers.find(item => {
      if (item.pushedQuestion == 'Location') {
        loc.location = item.answer_radio;
      }
    });
    // console.log(loc.location);
    // console.log('obj length', Object.keys(loc).length);
    dispatch(selectDifLocation(loc));
    console.log('usersQuoted', item.usersQuoted);
    setQuestions(item.questionAnswers);
  }, []);

  function getOptions(choices, answer, name) {
    // console.log(choices)
    // choices.map((choice1, index) => {
    //     if(choice1.option == answer)
    //     {
    //        console.log(answer)
    //        console.log(name)
    //        console.log(data)
    //         //setData({...data, [name]: answer})
    //     }
    // })

    return choices?.map((choice, index) => (
      <FormControlLabel value={choice.option} key={index} control={<Radio color="primary" />} label={choice.option} />
    ));
  }

  const submitHandler = () => {
    item.questionAnswers = questions;
    item.status = data.Status;
    item.questionAnswers.find(item => {
      if (item.pushedQuestion === 'Location') {
        item.answer_radio = address;
      }
    });
    //item.postUserMobile = data.PhoneNumber;
    item._id = data._id;
    item.user = item.user[0]?._id;
    item.address = address;
    delete item.Quotedetails;
    delete item.usersQuoted;
    delete item.reviews;
    //console.log("updated item",item)
    dispatch(fetchStart())
    http
      .patch(`${rootUrl}/update-lead`, {
        data: item,
      })
      .then(res => {
        dispatch(fetchSuccess())
        //debugger
        // store.addNotification({
        //   title: 'Success!',
        //   message: res.data.message,
        //   type: 'success',
        //   container: 'top-center',
        //   dismiss: {
        //     duration: 2000,
        //     onScreen: true,
        //   },
        // });
        sweetAlerts(res.data.message, 'success')

        //debugger
        //console.log(res.data.data)
        //const userData = location.state.job.postUserId[0];

        let updatedData = {
          job: res.data.data[0],
        };
        //updatedData.job.postUserId[0] = userData
        //debugger
        history.push({
          pathname: '/jobs-details',
          state: updatedData, // your data array of objects
        });
        window.scrollTo(0, 0);
      })
      .catch(error => {
        dispatch(fetchSuccess())
        console.log('error', error);
        // store.addNotification({
        //     title: "Error!",
        //     message: "error",
        //     type: "warning",
        //     container: "top-center",
        //     animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
        //     animationOut: ["animated", "fadeOut"],
        //     dismiss: {
        //       duration: 2000,
        //       onScreen: true
        //     }
        //   });
        window.scrollTo(0, 0);
      });
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl();
  };

  const handleChange = event => {
    //console.log(data)
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const con = event => {
    const month = new Date().getMonth();
    if (event.target.value === 1) {
      setMsg('PENDING');
    }
    if (event.target.value === 2) {
      setMsg('CLOSED');
    }
    if (event.target.value === 3) {
      setMsg('I need more quotes');
    }
    if (event.target.value === 4) {
      setMsg("I've found someone to do the job");
    }
    if (event.target.value === 5) {
      setMsg('I want to close this job');
    }
    handleClose();
  };

  const closeTextareaFlagHandler = () => {
    setTextareaFlag(false);
  };
  const openTextareaFlagHandler = () => {
    setTextareaFlag(true);
  };

  const closeBudgetFlagHandler = () => {
    setBudgetFlag(false);
  };
  const openBudgetFlagHandler = () => {
    setBudgetFlag(true);
  };

  const selectOption = e => {
    let objIndex = questions.findIndex(obj => obj.question == e.target.name);
    questions[objIndex].answer_radio = e.target.value;
    setQuestions([...questions]);
  };
  const handleSliderChangeInput = e => {
    let objIndex = questions.findIndex(obj => obj.question == e.target.name);
    questions[objIndex].answer_range = parseInt(e.target.value);
    setQuestions([...questions]);
  };
  const handleTextareaChange = e => {
    let objIndex = questions.findIndex(obj => obj.question == e.target.name);
    questions[objIndex].answer_textarea = e.target.value;
    setQuestions([...questions]);
  };
  const handleSliderChange = qnName => (event, newValue) => {
    let objIndex = questions.findIndex(obj => obj.question == qnName);
    questions[objIndex].answer_range = parseInt(newValue);
    setQuestions([...questions]);
  };

  const handleSpecificField = e => {
    let objIndex = questions.findIndex(obj => obj.question == e.target.name);
    questions[objIndex].answer_radio_other = e.target.value;
    setQuestions([...questions]);
  };

  const clickOption = e => {
    console.log('e.target.value', e.target.value);

    if (e.target.value === 'Other (Please specify)') openTextareaFlagHandler();
    else closeTextareaFlagHandler();
  };

  let handleLocationChange = async value => {
    // alert(value)
    if (value.length > 2) {
      //alert(value)
      await searchLocations(value).then(res => {
        console.log(res);
        if (res.length) {
          setLocation(res);
        }
      });
    }
  };

  let jobName = '';
  item.questionAnswers.find(item => {
    if (item.pushedQuestion === 'Category') {
      jobName = item.answer_radio;
    }
  });
  return (
    <div>
      <div className={'d-flex'}>
        <div style={{ width: '44%' }}>
          <Card>
            <CardContent className={'mt-2 ml-2 mb-4'}>
              <div>
                <div className={'mb-3 d-flex'}>
                  <div className={'mb-3'}>
                    <Typography color="textSecondary" component="span">
                      Posted on: {moment(item.createdAt).format('MMM DD, YYYY')}
                    </Typography>
                    <Typography color="textSecondary">
                      No of Quotes:{' '}
                      <strong>
                        {item.usersQuoted.length}/{item.quoteLimit}
                      </strong>
                    </Typography>
                    <Typography color="textSecondary">
                      Category : <strong style={{ fontSize: 'smaller' }}>{jobName}</strong>
                    </Typography>
                  </div>
                  <div className={'mb-3'} style={{ width: '30%' }}>
                    <div className={`${classes.inputField} ml-6`}>
                      Status : <br />
                      <NativeSelect
                        value={data.Status}
                        inputProps={{
                          name: 'Status',
                          id: 'uncontrolled-native',
                        }}
                        onChange={handleChange}>
                        <option value={'PENDING'}>PENDING</option>
                        <option value={'CLOSED'}>CLOSED</option>
                        <option value={'I need more quotes'}>I need more quotes</option>
                        <option value={"I've found someone to do the job"}>I've found someone to do the job</option>
                        <option value={'I want to close this job'}>I want to close this job</option>
                      </NativeSelect>
                    </div>
                  </div>
                </div>

                <div className={' mb-3'}>
                  <div className={classes.inputField} style={{ width: '92%' }}>
                    <Autocomplete
                      id="combo-box-demo"
                      //disabled="true"
                      limitTags={10}
                      name="Location"
                      options={locations}
                      getOptionLabel={option => option.postcode + ', ' + option.locality + ', ' + option.territory}
                      //getOptionLabel={option => option.locality}
                      value={addressValue}
                      onChange={(event, value) => {
                        dispatch(setLocationEdit(value));
                        setAddress(value?.postcode + ', ' + value?.locality + ', ' + value?.territory);
                      }}
                      //defaultValue={addressValue?addressValue:''}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Location"
                          variant="outlined"
                          onChange={e => {
                            handleLocationChange(e.target.value);
                          }}
                        />
                      )}
                    />
                    {/* {validationError('profession')} */}
                  </div>

                  <div className={`${classes.inputField}`}>
                    {questions.map((question, index) => {
                      if (question.pushedQuestion == 'Location' || question.pushedQuestion == 'Category') {
                        return;
                      }
                      if (question.questionType == 'radio') {
                        let specificField = null;
                        if (Object.keys(others).includes(question.answer_radio)) {
                          switch (others[question.answer_radio]) {
                            case 'textarea':
                              specificField = (
                                <TextField
                                  style={{ width: '92%' }}
                                  variant="outlined"
                                  onChange={e => handleSpecificField(e)}
                                  name={question.question}
                                  defaultValue={question.answer_radio_other}
                                />
                              );
                              break;
                            case 'dateSelector':
                              specificField = (
                                <TextField
                                  onChange={e => handleSpecificField(e)}
                                  InputProps={{ inputProps: { min: moment().format('YYYY-MM-DD') } }}
                                  type="date"
                                  name={question.question}
                                  defaultValue={question.answer_radio_other}
                                />
                              );
                              break;
                            case 'BudgetInput':
                              {
                                specificField = (
                                  <BudgetInput
                                    handler={handleSpecificField}
                                    name={question.question}
                                    defaultValue={question.answer_radio_other}
                                  />
                                );
                              }
                              break;
                          }
                        }
                        return (
                          <div>
                            <Typography variant="h3" className={'mt-3'}>
                              {question.question}
                            </Typography>
                            <RadioGroup
                              row
                              defaultValue={question.answer_radio}
                              name={question.question}
                              onChange={selectOption}
                              onClick={clickOption}>
                              {getOptions(question.choices, question.answer_radio, question.pushedQuestion)}
                            </RadioGroup>
                            {specificField}
                          </div>
                        );
                      }
                      if (question.questionType == 'range') {
                        return (
                          <div>
                            <Typography variant="h3" className={'mb-2 mt-5'}>
                              {question.question}
                            </Typography>
                            <div>
                              <Slider
                                style={{ width: '75%' }}
                                value={typeof question.answer_range === 'number' ? question.answer_range : 0}
                                onChange={handleSliderChange(question.question)}
                                name={question.question}
                                aria-labelledby="input-slider"
                              />
                              <Input
                                style={{ margin: '-13px 0px 0px 10px', position: 'absolute' }}
                                className={classes.input}
                                value={question.answer_range}
                                margin="dense"
                                name={question.question}
                                onChange={e => handleSliderChangeInput(e)}
                                //onChange={inputHandler}
                                //onBlur={handleBlur}
                                inputProps={{
                                  step: 1,
                                  min: 0,
                                  max: 100,
                                  type: 'number',
                                  'aria-labelledby': 'input-slider',
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      if (question.questionType == 'textarea') {
                        return (
                          <div>
                            <Typography variant="h3" className={'mb-2 mt-5'}>
                              {question.question}
                            </Typography>
                            <TextField
                              className={classes.inputField}
                              style={{ width: '92%' }}
                              //label="Description"
                              variant="outlined"
                              name={question.question}
                              value={question.answer_textarea}
                              //value={data.Description}
                              onChange={e => handleTextareaChange(e)}
                              multiline
                              rows={2}
                              rowsMax={4}
                            />
                          </div>
                        );
                      }
                      if (question.questionType == 'input') {
                        return (
                          <div>
                            <Typography variant="h3" className={'mb-2 mt-5'}>
                              {question.question}
                            </Typography>
                            <TextField
                              className={classes.inputField}
                              style={{ width: '92%' }}
                              variant="outlined"
                              name={question.question}
                              //defaultValue={question.answer_input}
                              value={question.answer_input}
                              onChange={e => handleTextareaChange(e)}
                              multiline
                              rows={1}
                              rowsMax={1}
                            />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                <Button variant="contained" color="primary" onClick={() => submitHandler()} style={{ float: 'right' }}>
                  Submit
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
        <div style={{ width: '54%', marginLeft: '1%' }}>
          {item.user.length > 0 && (
            <div>
              <UserDetails user={item.user[0]}></UserDetails>
            </div>
          )}
          {item.usersQuoted.length > 0 && (
            <div style={{ marginTop: '1%' }}>
              <Card>
                <CardContent className={'p-2 m-4'}>
                  <h4 style={{ margin: '-1rem 0rem 1rem -1rem' }}>Quoted By Users</h4>
                  <Quotes quotes={item.usersQuoted} jobId={item._id} postedBy={item.user}></Quotes>
                </CardContent>
              </Card>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default JobDetails;
