import React, { useEffect } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import BasicInfo from './components/BasicInfo';
import ContactInfo from './components/ContactInfo';
import BusinessDescription from './components/BusinessDescription';
import SubrubsInfo from './components/SubrubsInfo';
import Qualification from './components/Qualification';
import Review from './components/Review';
import ReviewComment from './components/ReviewComment';
import { Job } from './components/Job';
import { useParams } from 'react-router';
import { http } from '../../../../appmakers/services/http-service';
import { fetchBusinessUser } from '../../../../redux/actions/BusinessDirectoryActions';
import { rootUrl } from '../../../../api-handling/rootUrl';
import { useDispatch, useSelector } from 'react-redux';
import OpeningHours from './components/OpeningHours';

const BusinessPublicProfile = () => {
  const { id } = useParams();
  const { selectedUser } = useSelector(({ BusinessDirectoryReducer }) => BusinessDirectoryReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    await http
      .get(`${rootUrl}/user/${id}`)
      .then(res => {
        dispatch(fetchBusinessUser(res.data));
      })
      .catch(error => {
        console.log('Error', error);
      });
  };

  return (
    <Grid container>
      <Grid item sm={4} className="bg-white" style={{ borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
        <BasicInfo user={selectedUser} userId={id} />
        <Divider className="mt-4 mb-4" />
        <ContactInfo user={selectedUser} />
        <Divider className="mt-4 mb-4" />
        <BusinessDescription description={selectedUser.businessDescription} />
        <Divider className="mt-4 mb-4" />
        <OpeningHours user={selectedUser} />
        <Divider className="mt-4 mb-4" />
        <SubrubsInfo user={selectedUser} />
        <Divider className="mt-4 mb-4" />
        <div className="d-flex flex-col ml-4 mb-4 align-content-between pt-2 text-gray-800">
          <Typography component="h4" variant="h4">
            Accepted payment method
          </Typography>
          <div className="pr-2 mt-3">
            <Typography component="div" variant="body2">
              Data of this part is not sent by client
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item sm={6} className="border bg-white">
        <Qualification selectedUser={selectedUser} />
        <Divider className="mt-4 mb-4" />
        <Review />
        <Divider className="mt-4 mb-4" />
        <ReviewComment />
        <Divider className="mt-4 mb-4" />
      </Grid>
      <Grid item sm={2} className="border h-50 bg-white">
        <Job />
      </Grid>
    </Grid>
  );
};
export default BusinessPublicProfile;
