import React, { useEffect, useState } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import ApmTable from '../../../../appmakers/components/ApmTableWithoutEdit';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCategory } from './UpdateCategory_new';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/icons/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { MySwal, sweetAlerts } from 'helpers/Swal';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';

import {
  getAllProfession,
  changeParams,
  DeleteProfession,
  DeleteProfessionMultiple,
  updateProfession,
} from '../../../../redux/actions/CategoryManagementAction';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

// get query parameter string
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  jobForm: {
    marginTop: '10rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));
export const ShowCategory = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  let { professions, params } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);
  let { loading } = useSelector(({ common }) => common);
  useEffect(() => {
    dispatch(getAllProfession(params));
  }, [params.page, params.perPage]);

  const updateUserHandler = data => {
    dispatch(updateProfession(data));
  };

  const paramsHandler = (key, value) => {
    dispatch(changeParams(key, value));
  };

  const deleteHandler = data => {
    dispatch(DeleteProfession({ id: data._id }));
  };

  const deleteRow = data => {
    MySwal.fire({
      icon: 'info',
      html: '<p>Are you sure you want to delete?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Yes',
      confirmButtonAriaLabel: ' yes',
      cancelButtonText: 'No',
      cancelButtonAriaLabel: 'no',
    }).then(result => {
      if (result.value) {
        dispatch(DeleteProfessionMultiple(data));
        dispatch(getAllProfession(params));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        sweetAlerts('info', 'cancelled');
      }
    });
  };

  const classes = useStyles();
  const columns = [
    { title: 'Profession Name', field: 'name' },
    { title: 'Image', field: 'imageUrl' },
    { title: 'credits', field: 'credits' },
    { title: 'Actions', field: '_id',render: ({_id}) => {
    return (<div>
    <EditIcon   
        onClick = {()=>{
          history.push(`/category-management-edit/${_id}`)
        }
        } 
        style={{cursor:"pointer"}}
      />
      <DeleteOutline   
        onClick = {()=>{
          deleteRow([{_id}]);
        }
        } 
        style={{cursor:"pointer",marginLeft:'20px'}}
      />
      </div>)
    },
    width: "10%"
  }
  ];

  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let actions = [
    // {
    //   icon: EditIcon,
    //   tooltip: 'edit',
    //   onClick: (event, rowData) => {
    //     history.push(`/category-management-edit/${rowData._id}`);
    //   } 
    // },
    {
      icon: DeleteOutline,
      tooltip: 'delete',
      onClick: (event, rowData) => {
        deleteRow(rowData);
      },
    }
    
  ];
  // let permission = permissions['User Roles'];
  return (
    <div>
      {!loading && (
        <ApmTable
          columns={columns}
          data={professions}
          title="All Professions"
          params={params}
          actions={actions}
          name={'User Roles'}
          updateHandler={updateUserHandler}
          paramsHandler={paramsHandler}
          deleteHandler={deleteHandler}
          search={false}
          showActions={false}
        />
      )}
    </div>
  );
};
