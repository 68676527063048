import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Edit } from "@material-ui/icons";
import EditProfilePicModal from "./editProfilePicModal1";

import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser } from "../../../../../redux/actions/Auth";

const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const SmallAvatar = withStyles(theme => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    large: {
        width: theme.spacing(25),
        height: theme.spacing(25),
    },
}));
const user = JSON.parse(localStorage.getItem('adminUser'))
export default function MyProfileAvatar() {
    const classes = useStyles();
    const { authUser } = useSelector(({ auth }) => auth);
    const dispatch = useDispatch();
    let [openModal, setOpenModal] = useState(false);
    let user = JSON.parse(localStorage.getItem('adminUser'));


    const handelProfileedit = () => {
        setOpenModal(true);
    }

    const handleClose = () => {
        let user = JSON.parse(localStorage.getItem('adminUser'));
        authUser.imageUrl = user?.imageUrl;
        authUser.imageHash = Date.now();
        dispatch(setAuthUser(authUser));
        //console.log('authUser', authUser);
        // setUserImage(user.imageUrl)
        // setUserHash(Date.now())
        setOpenModal(false);
    }

    return (

        <div>
            <Box className={classes.root}>
                <Badge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<div style={{ background: 'white', color: 'gray', border: '1px solid lightgray', borderRadius: '50%', height: '1.6rem', width: '1.6rem' }}>
                        <Edit onClick={() => { handelProfileedit() }} />
                    </div>}>
                    <Avatar alt={authUser?.userName} src={`${authUser?.imageUrl}?${authUser?.imageHash ? authUser.imageHash : Date.now()}`} className={classes.large} />
                </Badge>
            </Box>
            <EditProfilePicModal open={openModal} handleClose={handleClose} />
        </div>
    );
}
