import React, {createRef, useEffect, useState} from 'react';
import {Autocomplete} from "@material-ui/lab";
import searchLocations from '../../../../../src/api-handling/fetchLocalLocations';
import {TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {setKey} from "../../../../redux/actions/JobActions";

export const Address = ({onChange, defaultAddressFlag = true}) => {
    const dispatch = useDispatch();
    let [location, setLocation] = useState([]);
    // let [autocomplete, setAutoComplete] = useState('');
    let {addressAutoCompleteKey, autocomplete} = useSelector(({JobReducer}) => JobReducer);
    const userInfo = localStorage.getItem('adminUserInfo') ? JSON.parse(localStorage.getItem('adminUserInfo')) : null

    const handleChange = (selectedLocation) => {
        let selectedAddress = selectedLocation ?
            `${selectedLocation?.postcode}, ${selectedLocation?.locality}, ${selectedLocation?.territory}` : ''
        dispatch(setKey({key: 'autocomplete', value: selectedLocation}));
        onChange(selectedAddress)
    }

    const handleLocationChange = async (value, manualSelect = false) => {
        if (value.length > 2) {
            await searchLocations(value).then(res => {
                if (res.length) {
                    if (manualSelect) {
                        const location = res.find(i => {
                            return i.postcode == value
                        })
                        location && handleChange(location)
                    }
                    setLocation(res);
                }
            });
        }
    }

    useEffect(() => {
        if (!location.length && userInfo && defaultAddressFlag)
            handleLocationChange(userInfo.postal_code.short_name, true)
    }, [userInfo])

    return <div>
        <label className={"mb-2"}>Where do you need the service?</label>
        <Autocomplete
            key={addressAutoCompleteKey}
            size='small'
            value={autocomplete}
            id="combo-box-demo"
            limitTags={10}
            options={location}
            getOptionLabel={option => {
                if (option)
                    return option.postcode + ', ' + option.locality + ', ' + option.territory
                return '';
            }
            }
            onChange={(event, value) => value && handleChange(value)}
            renderInput={params => (
                <TextField
                    value={"1011"}
                    {...params}
                    variant="outlined"
                    onChange={e => {
                        handleLocationChange(e.target.value);
                    }}
                />
            )}
        />
    </div>
}
