import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import { Card, Grid, Box, Button, TextField, CircularProgress } from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import WelcomeMessage from '../Dashboard/welcomemessage';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import CmtCard from '@coremat/CmtCard';
import SearchIcon from '@material-ui/icons/Search';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtMediaObject from '@coremat/CmtMediaObject';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import ApmExpendableCard from './ApmExpendableCard';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '16px',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quotesContent: {
    marginTop: '1vh',
  },
}));

export const LeadSearch = () => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState('');
  const [userName, setName] = useState('');
  const [userPhone, setPhone] = useState('');
  const [msg, setMsg] = useState('Leads List');
  let [loading, setLoading] = useState(false);

  const handleEmailChange = val => {
    if (val.length > 2) {
      http
        .post(`${rootUrl}/search-email`, {
          email: val,
        })
        .then(res => {
          setEmails(res.data.userEmail);
          console.log('res', res.data.userEmail);
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  };
  const search = () => {
    setLoading(true)
    http
      .post(`${rootUrl}/search-leads`, {
        email: email,
        userName: userName,
        mobile: userPhone,
      })
      .then(res => {
        setLoading(false)
        setUsers(res.data.users);
        setMsg('Leads Not Found');
        console.log('data', res.data.users);
      })
      .catch(error => {
        setLoading(false)
        console.log('error', error);
      });
  };

  const setMessage = val => {
    if (val != '') setMsg('');
  };
  useEffect(() => { }, [users]);
  return (
    <div style={{ width: '80%', margin: '0px auto' }}>
      <Card>
        <Grid container spacing={3} className={'justify-content-between p-3'}>
          <Grid item xs={3} md={3} sm={3} lg={3}>
            <TextField
              id="outlined-basic"
              label="User Name"
              variant="outlined"
              fullWidth
              onChange={e => {
                console.log(e.target.value);
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} md={3} sm={3} lg={3}>
            <TextField
              id="outlined-basic"
              label="User Phone"
              variant="outlined"
              fullWidth
              onChange={e => {
                console.log(e.target.value);
                setPhone(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4} md={4} sm={4} lg={4}>
            <Autocomplete
              id="combo-box-demo"
              limitTags={10}
              name="userEmail"
              options={emails}
              getOptionLabel={option => option.email}
              onChange={(event, value) => {
                console.log(value?.email);
                setEmail(value?.email);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="User Email"
                  onChange={e => {
                    handleEmailChange(e.target.value);
                  }}
                />
              )}
            />
          </Grid>
          <Button
            variant="contained"
            color="primary"
            style={{ height: '40px', marginTop: '15px' }}
            onClick={search}
            className={'text-capitalize'}>
            Search
          </Button>
        </Grid>
      </Card>

      <Card className={'mt-3'}>
        <Card className={'mt-3'}>
          <div>
            {users &&
              users.map((user, userIndex) => {
                return (
                  user.jobs &&
                  user.jobs.length > 0 &&
                  user.jobs.map((job, jobIndex) => {
                    // console.log('-----1')
                    setMessage(msg);
                    return (
                      <div style={{ marginBottom: '1vh' }}>
                        {userIndex == 0 && jobIndex == 0 && (
                          <Card style={{ textAlign: 'center' }}>
                            <CmtCard>
                              <CmtCardHeader title={<h4 className="font-bold">{'Leads List'}</h4>}></CmtCardHeader>
                            </CmtCard>
                          </Card>
                        )}

                        <ApmExpendableCard job={job} getJobs={search}></ApmExpendableCard>
                      </div>
                    );
                  })
                );
              })}
          </div>
        </Card>

        {msg != '' && (
          <Card style={{ textAlign: 'center' }}>
            <CmtCard>
              <CmtCardHeader title={<h4 className="font-bold">{msg}</h4>}></CmtCardHeader>
            </CmtCard>
          </Card>
        )}
      </Card>
      {
        loading &&
        <div style={{
          position: 'fixed',
          zIndex: 9999,
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }}>
          <CircularProgress class="position-absolute" />
        </div>
      }
    </div>
  );
};
