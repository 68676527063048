import React, {useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Box, Typography} from '@material-ui/core';
import {CloudUpload} from "@material-ui/icons";

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const DragDropField = ({onChangeHandler,acceptedTypes}) => {
    const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone({accept: 'image/*'});

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept],
    );

    return (
        <Box>
            <Box {...getRootProps({style})}>
                <input {...getInputProps()} accept={acceptedTypes}  onChange={(e)=>onChangeHandler(e.target.files[0])}/>
                <Typography>
                    <div className="text-center">
                        <CloudUpload/>
                        <p>Select a file</p>
                        <p>File types accepted: csv</p>
                    </div>
                </Typography>
            </Box>
        </Box>
    );
};

export default DragDropField;
