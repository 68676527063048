import React, { useEffect, useState } from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import { Card, FormInput, Form } from 'semantic-ui-react';
import { Grid, TextField, Box, Input, Button } from '@material-ui/core';

import moment from 'moment';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import { NotificationManager } from 'react-notifications';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { selectUser } from 'redux/actions/UserManagementActions';

// const useStyles=makeStyles({
//     editProfileFormRoot: {
//         width: '1000px', marginLeft: '5%', marginTop: '10px',background:"#ffffff", padding:"20px",height:"1000px"
//     },
// })

// const editProfile1 = {
//     // '@media (max-width: 600px)': {

//        width: '300px', marginLeft: '6%'

//   }

const style = {
  // width:"80%",
  // height:"100%",
  // padding:"20px",
  // top:5,
  left: 60,
  position: 'relative',
  background: '#ffffff',
  padding: '20px',
};
const textField = {
  //width:"70px"
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '2em',
    padding: '2em',
  },
  button: {
    marginLeft: '2em',
  },
}));

const UserForm = ({ setFormFlagHandler }) => {
  // const classes=useStyles();
  const dispatch = useDispatch();
  const { selectedUser } = useSelector(({ UserManagementReducer }) => UserManagementReducer);
  let user = JSON.parse(localStorage.getItem('adminUser'));
  let uName = selectedUser?.userName;
  let uemail = selectedUser?.email;
  let uMobile = selectedUser?.mobile;
  let bName = selectedUser?.businessName;
  let bABN = selectedUser?.businesABN;
  let bAddress = selectedUser?.address;
  let profession = selectUser?.businessProfession;
  let [values, setValues] = useState({
    userName: uName,
    _id: selectedUser?._id,
    mobile: uMobile,
    email: uemail,
    businessName: bName,
    businessABN: bABN,
    address: bAddress,
    businessProfession: profession,
  });

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const submitFormHandler = () => {
    let id = selectedUser._id;

    let userName = values.userName != null ? values.userName : selectedUser.userName;

    let email = values.email != null ? values.email : selectedUser.email;

    let businessABN = values.businessABN != null ? values.businessABN : selectedUser.businessABN;

    let businessName = values.businessName != null ? values.businessName : selectedUser.businessName;

    let address = values.address != null ? values.address : selectedUser.address;
    let businessProfession = values.businessProfession != null ? values.businessProfession : selectedUser.businessProfession;

    http
      .put(`${rootUrl}/admin/update-user`, {
        user: values,
      })
      .then(res => {
        if (res) {
          if (res.data.responseCode != '200') {
            //  NotificationManager.error(res.data.message, 'Error');
            sweetAlerts(res.data.message, 'srror');
          } else {
            //  NotificationManager.success(res.data.message, 'Success');
            sweetAlerts(res.data.message, 'success');
            console.log(res.data.user.userName);
            dispatch(selectUser(res.data.user[0]));
            let user = JSON.parse(localStorage.getItem('adminUser'));
            user.userName = res.data.user.userName;
            user.email = res.data.user.email;
            user.mobile = res.data.user.mobile;
            user.businessName = res.data.user.businessName;
            user.businessABN = res.data.user.businessABN;
            user.address = res.data.user.address;
            user.businessProfession = res.data.user.businessProfession;
            localStorage.setItem('adminUser', JSON.stringify(user));
            setFormFlagHandler(false);
          }
        }
      })
      .catch(error => {
        //  NotificationManager.error(error.data.message, 'Error');
        sweetAlerts(error, 'Error');
      });
  };

  return (
    // <StyleRoot>
    <Grid container style={style} className={'editform'}>
      <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
        <Typography className={'flex-1'} variant="h2">
          UserName
        </Typography>
        <TextField
          label={'User Name'}
          fullWidth
          onChange={handleChange('userName')}
          defaultValue={selectedUser?.userName}
          value={values.userName}
          margin="normal"
          variant="outlined"
          className={textField}
        />
      </Grid>
      <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
        <Typography variant="h2">Email</Typography>
        <TextField
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          onChange={handleChange('email')}
          defaultValue={selectedUser?.email}
          value={values.email}
          margin="normal"
          variant="outlined"
          className={textField}
        />
      </Grid>

      <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
        <Typography variant="h2">Mobile</Typography>
        <TextField
          label={<IntlMessages id="appModule.mobile" />}
          fullWidth
          onChange={handleChange('mobile')}
          defaultValue={selectedUser?.mobile}
          value={values.mobile}
          margin="normal"
          variant="outlined"
          className={textField}
        />
      </Grid>

      <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
        <Typography variant="h2">Registration Date</Typography>
        <TextField
          fullWidth
          onChange={handleChange('registrationDate')}
          defaultValue={moment(`${selectedUser?.registrationDate}`).format('YYYY-MM-DD')}
          value={values.registrationDate}
          margin="normal"
          variant="outlined"
          type="date"
          className={textField}
        />
      </Grid>
      {selectedUser?.userType === 'Business User' && (
        <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
          <Typography className={'flex-1'} variant="h2">
            Business Name
          </Typography>
          <TextField
            label={'Business Name'}
            fullWidth
            onChange={handleChange('businessName')}
            defaultValue={selectedUser?.businessName}
            value={values.businessName}
            margin="normal"
            variant="outlined"
            className={textField}
          />
        </Grid>
      )}

      {selectedUser?.userType === 'Business User' && (
        <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
          <Typography variant="h2">Address</Typography>
          <TextField
            label={'Business Address'}
            fullWidth
            onChange={handleChange('address')}
            defaultValue={selectedUser?.address}
            value={values.address}
            margin="normal"
            variant="outlined"
            className={textField}
          />
        </Grid>
      )}
      {selectedUser?.userType === 'Business User' && (
        <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
          <Typography variant="h2">Business NZBN</Typography>
          <TextField
            label={'Business NZBN'}
            fullWidth
            onChange={handleChange('businessABN')}
            defaultValue={selectedUser?.businessABN}
            value={values.businesABN}
            margin="normal"
            variant="outlined"
            className={textField}
          />
        </Grid>
      )}
      {selectedUser?.userType === 'Business User' && (
        <Grid item className={'mb-2 ml-3'} xs={12} sm={12} md={5} lg={5}>
          <Typography variant="h2">Profession</Typography>
          <TextField
            label={'Services'}
            fullWidth
            onChange={handleChange('businessABN')}
            defaultValue={selectedUser?.businessProfession}
            value={values.businessProfession}
            margin="normal"
            variant="outlined"
            className={textField}
          />
        </Grid>
      )}

      <Grid container className={'mb-2 ml-3 center'} xs={12} sm={12} md={12} lg={12}>
        <Box mb={{ xs: 2, sm: 0 }} style={{ marginLeft: '-14%' }}>
          <Button variant="contained" color="primary" onClick={() => submitFormHandler()}>
            <BackupOutlinedIcon />
            &nbsp;
            <IntlMessages id="appModule.submit" />
          </Button>
          &nbsp;
          <Button variant="contained" className={'cancel'} onClick={() => setFormFlagHandler(false)}>
            <CloseOutlinedIcon />
            &nbsp;
            <IntlMessages id="appModule.cancel" />
          </Button>
        </Box>
      </Grid>
    </Grid>

    // </StyleRoot>
  );
};

export default UserForm;
