import React, { useState ,useEffect} from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Paper, Card } from '@material-ui/core';
import { AddCategory } from './AddCategory';
import { ShowCategory } from './CategoryListView';
import SearchBox from '@jumbo/components/AppLayout/VerticalLayouts/ModernSidebar/ActionSideBar/Search/SearchBox';
import { getSearchedProfession } from '../../../../redux/actions/CategoryManagementAction';

export const CategoryManagement = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);
  let { params } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);
  const [searched, setSearched] = useState('');

  const handleTabChange = (e, value) => {
    setValue(value);
  };
  const requestSearch = value => {
    setSearched(value);
  };
  useEffect(() => {
    dispatch(getSearchedProfession(params, searched));
}, [params.page, params.perPage, searched]);

  return (
    <PageContainer>
            <SearchBox value={searched} onChange={requestSearch} />

      <Paper square className={'p-3'}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example">
          <Tab style={{ textTransform: 'capitalize', fontWeight: 'bold' }} label="Professions" value={0} />
        </Tabs>
        {value === 0 && (
          <Card className={'m-2'}>
            <AddCategory />
            <ShowCategory />
          </Card>
        )}
      </Paper>
    </PageContainer>
  );
};
