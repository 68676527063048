import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications/lib/NotificationManager';
import AddIcon from '@mui/icons-material/Add';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getProfession, getDifCosts } from '../../../../redux/actions/CostManagementAction';
import { fetchError, fetchStart, fetchSuccess } from '../../../../redux/actions';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Modal from '@material-ui/core/Modal';
import Radium, { StyleRoot } from 'radium';
import { store } from 'react-notifications-component';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import { getAllProfession } from 'redux/actions/CategoryManagementAction';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  let top = 45;
  let left = 45;
  let wid = 50;
  //alert(window.innerWidth );
  if (window.innerWidth < 600) {
    wid = 100;
    left = 0;
    top = 0;
  }
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${wid}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  profession: '',
  price: 0,
};

export const AddCategory = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const { params } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);
  const [data, setData] = useState({ ...resetForm });
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  //validations
  const requiredFields = {
    profession: 'required',
    price: 'required',
  };

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      confirmPassword: {
        // name the rule
        message: 'The :attribute does not match password',
        rule: (val, params, validator) => {
          return params[0] === val;
        },
        required: true, // optional
      },
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  //handlers
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setData({ ...resetForm })
  };

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  const AddProfession = () => {
    const formData = new FormData();
    function getExt(filename) {
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
    }
    let myNewFile = null;
    if (selectedImage != null) {
      let fileExt = getExt(selectedImage.name);
      myNewFile = new File([selectedImage], `categoryImages/${data.profession.replace(/ /g, '')}.${fileExt[0]}`);
      formData.append('newFile', myNewFile);
      formData.append('newFileName', myNewFile.name);
      formData.append('profession', data.profession);
      formData.append('price', data.price);
    }


    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    let userData = JSON.parse(localStorage.getItem('adminUser'));
    handleClose();
    if (myNewFile != null) {
      http
        .post(`${rootUrl}/add-profession-with-image`, formData, config)
        .then(res => {
          sweetAlerts('Successfully added Profession', 'success');
          dispatch(getAllProfession(params));
        })
        .catch(error => {
          sweetAlerts(error.data, 'error');
        });
    } else {
      http
        .post(`${rootUrl}/add-profession`, { profession: data.profession, price: data.price, user: userData })
        .then(res => {
          sweetAlerts('Successfully added Profession', 'success');
          dispatch(getAllProfession(params));
        })
        .catch(error => {
          //console.log(error)
          sweetAlerts(error.data, 'error');
        });
    }
  };

  useEffect(() => {
    dispatch(getProfession());
  }, []);

  const uploadImageInGallery = async (event, file) => {
    const fsize = file.size;
    const newFile = Math.round(fsize / 1024);
    const src = URL.createObjectURL(file);
    console.log(src);
    if (newFile >= 4096) {
      // handleClose();
      sweetAlerts('File too Big, please select a file less than 4mb', 'Error');
      return event.target.value = null;

    } else if (file.type.split('/')[0] === 'image') {
      setSelectedImage(file);
    } else {
      //handleClose();
      sweetAlerts('Invalid file type', 'Error');
      return event.target.value = null;

    }
  };

  return (
    <StyleRoot>
      <div>
        {permissions['User Management'] && permissions['User Management'].includes('create') && (
          <Button
            variant="contained"
            className={'m-3'}
            size={'large'}
            onClick={handleOpen}
            color="primary"
            style={{ textTransform: 'capitalize' }}>
            <AddIcon />
            Add
          </Button>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Card style={modalStyle}>
            <Card.Content className={'p-4 m-4'}>
              <Container>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.profession}
                      name="profession"
                      label="Profession Name"
                      variant="outlined"
                    />
                    {/* {validationError('profession')} */}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={event => {
                        console.log(event.target.files[0]);
                        uploadImageInGallery(event, event.target.files[0]);
                      }}
                      style={{ width: '100%' }}
                      name={'price'}
                      variant="outlined"
                      type="file"
                    />
                    {/* {validationError('price')} */}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.price}
                      name={'price'}
                      label="Credits"
                      variant="outlined"
                      type="number"
                    />
                    {/* {validationError('price')} */}
                  </div>
                </div>
              </Container>
              <div className={'d-flex justify-content-end'}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ textTransform: 'capitalize' }}
                  onClick={handleClose}
                  color="secondary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={'ml-2'}
                  size="large"
                  style={{ textTransform: 'capitalize' }}
                  onClick={AddProfession}
                  //disabled={!validator.allValid()}
                  color="primary">
                  Create
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Modal>
      </div>
    </StyleRoot>
  );
};
