import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Card } from 'semantic-ui-react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPageActions, updateUserRoleActions } from '../../../../redux/actions/UserRoleActions';
import { ToggleOpenModal } from '../../../../redux/actions/RoleManagement';
import Checkbox from '@material-ui/core/Checkbox';
import { StyleRoot } from 'radium';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2em',
    margin: '0 2em',
  },
}));
const actionStyle = {
  '@media (min-width: 601px)': {
    padding: '2em',
    margin: '0 2em',
  },
};
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const WhiteCheckbox = withStyles({
  root: {
    color: blue[50],
    '&$checked': {
      color: blue['A700'],
      fontSize: 22,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export const Actions = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let { selected, pageActions } = useSelector(({ UserRoleReducer }) => UserRoleReducer);
  let { showRolesData } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let { loading } = useSelector(({ common }) => common);
  const [tableData, setTableData] = useState();
  const [dashboard, setDashboard] = useState(null);
  const [bulkUpload, setBulkUpload] = useState(null);
  const [activityLog, setActivityLog] = useState(null);
  const [complaint, setComplaint] = useState(null);

  const [checked, setChecked] = useState([]);
  const headers = ['Modules', 'View', 'Create', 'Edit', 'Delete'];

  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSelected = usePrevious(selected);

  const updateRole = () => {
    dispatch(updateUserRoleActions(selected[0]._id, checked, dashboard, bulkUpload, activityLog, complaint));
    dispatch(ToggleOpenModal(false));
  };

  const handleActionChange = (e, action, page) => {
    let data = [...checked];
    if (e.target.checked) {
      if (action.title !== 'View') {
        const view = page.actions.find(i => i.title == 'View');
        if (action._id !== view._id && !data.includes(view._id)) {
          data.push(view._id);
        }
      }
      data.push(action._id);
    } else {
      if (action.title === 'View') {
        const allActions = page.actions.map(i => i._id);
        data = data.filter(i => !allActions.includes(i));
      } else data = data.filter(i => i !== action._id);
    }
    setChecked(data);
  };

  const pageActionData = (page, action) => {
    const pageAction = page.actions.find(i => i.title == action);
    return pageAction ? (
      <Checkbox
        checked={checked.includes(pageAction._id)}
        onChange={e => handleActionChange(e, pageAction, page)}
        color="primary"
      />
    ) : (
      ''
    );
  };

  const isModuleChecked = page => {
    if (page.actions && page.actions.length) {
      const actions = page.actions.map(i => i._id);
      return checked.filter(i => actions.includes(i)).length;
    }
  };

  const handleModuleChange = (value, page) => {
    let data = [...checked];
    if (page.actions && page.actions.length) {
      page.actions.forEach(item => {
        if (value && !data.includes(item._id)) {
          data.push(item._id);
        }
        if (!value) {
          data = data.filter(i => i !== item._id);
        }
      });
      setChecked(data);
    }
  };

  const updateTableData = () => {
    const data = pageActions.map(page => {
      return (
        <TableRow key={page._id}>
          <TableCell>
            {page.name === 'Dashboard' && (
              <Checkbox
                checked={dashboard}
                onChange={e => {
                  setDashboard(e.target.checked);
                }}
                color="primary"
              />
            )}
            {page.name === 'Bulk Upload' && (
              <Checkbox
                checked={bulkUpload}
                onChange={e => {
                  setBulkUpload(e.target.checked);
                }}
                color="primary"
              />
            )}
            {page.name === 'Activity Logs' && (
              <Checkbox
                checked={activityLog}
                onChange={e => {
                  setActivityLog(e.target.checked);
                }}
                color="primary"
              />
            )}
            {page.name === 'Complaint' && (
              <Checkbox
                checked={complaint}
                onChange={e => {
                  setComplaint(e.target.checked);
                }}
                color="primary"
              />
            )}
            {!['Dashboard', 'Bulk Upload', 'Activity Logs', 'Complaint'].includes(page.name) && (
              <Checkbox
                checked={isModuleChecked(page)}
                onChange={e => handleModuleChange(e.target.checked, page)}
                color="primary"
              />
            )}
            {page.name}
          </TableCell>
          <TableCell>{pageActionData(page, 'View')}</TableCell>
          <TableCell>{pageActionData(page, 'Create')}</TableCell>
          <TableCell>{pageActionData(page, 'Edit')}</TableCell>
          <TableCell>{pageActionData(page, 'Delete')}</TableCell>
        </TableRow>
      );
    });
    setTableData(data);
  };

  const headerActions = (header = false) => {
    const allActions = [];
    pageActions.forEach(page => {
      page.actions.forEach(action => {
        if (header && action.title === header) {
          allActions.push(action._id);
        } else if (!header) {
          allActions.push(action._id);
        }
      });
    });
    return allActions;
  };

  const isHeaderChecked = header => {
    if (header === 'Modules') {
      return headerActions().length === checked.length && bulkUpload && dashboard && activityLog;
    }
    const allActions = headerActions(header);
    return allActions.length === allActions.filter(i => checked.includes(i)).length;
  };

  const handleHeaderChecked = (header, value) => {
    //debugger;
    let data = [...checked];
    if (header === 'Modules') {
      if (value) {
        pageActions.forEach(page => {
          page.actions.forEach(item => {
            !data.includes(item._id) && data.push(item._id);
          });
        });
      } else {
        data = [];
      }
      setDashboard(value);
      setBulkUpload(value);
      setActivityLog(value);
      setComplaint(value);
    } else {
      if (header === 'View') {
        if (!value) data = [];
      } else {
        let viewIds = [];
        pageActions.forEach(page => {
          page.actions.forEach(action => {
            action.title === 'View' && viewIds.push(action._id);
          });
        });
        viewIds.forEach(i => {
          !data.includes(i) && data.push(i);
        });
      }
      headerActions(header).forEach(item => {
        if (value && !data.includes(item)) {
          data.push(item);
        } else if (!value && data.includes(item)) {
          data = data.filter(i => i !== item);
        }
      });
    }
    setChecked(data);
  };

  const updateState = () => {
    if (selected && selected.actions && selected.actions.length) {
      let data = selected.actions.map(i => i._id);
      setChecked(data);
    } else {
      setChecked([]);
    }
    setDashboard(selected.dashboard);
    setBulkUpload(selected.bulkUpload);
    setActivityLog(selected.activityLog);
    setComplaint(selected.complaint)
  };

  useEffect(() => {
    if (!pageActions.length) {
      dispatch(getAllPageActions());
    }
    if (prevSelected !== selected && selected) {
      updateState();
    }
    if (selected && pageActions.length) updateTableData();
  }, [selected, pageActions, checked, dashboard, bulkUpload, activityLog, complaint]);

  useEffect(() => {
    //debugger
    if (selected) {
      const filteredCheckbox = selected[0].actions.map(check => check._id);
      setChecked(filteredCheckbox);
      setDashboard(selected[0].dashboard);
      setBulkUpload(selected[0].bulkUpload);
      setActivityLog(selected[0].activityLog);
      setComplaint(selected[0].complaint);
    }
  }, [selected]);

  return (
    <StyleRoot>
      {showRolesData && (
        <Card style={actionStyle}>
          <h1>{selected && selected.title}</h1>
          <Card.Content>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    {headers.map(header => {
                      return (
                        <StyledTableCell key={header}>
                          <WhiteCheckbox
                            checked={isHeaderChecked(header)}
                            onChange={e => {
                              handleHeaderChecked(header, e.target.checked);
                            }}
                            color="primary"
                          />
                          {header}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>{tableData}</TableBody>
              </Table>
            </TableContainer>
            <div>
              <Button
                variant="contained"
                disabled={loading}
                style={{ margin: '0.9em', textTransform: 'capitalize' }}
                className={classes.button}
                onClick={updateRole}
                color="primary">
                Update Role
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                style={{ margin: '0.9em', textTransform: 'capitalize' }}
                className={classes.button}
                onClick={() => {
                  dispatch(ToggleOpenModal(false));
                }}
                color="primary">
                Close
              </Button>
            </div>
          </Card.Content>
        </Card>
      )}
    </StyleRoot>
  );
};
