import React, { useState,useEffect } from 'react';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Paper, Card } from '@material-ui/core';
import { AddDifCost } from './add_def_cost';
import { ShowDifCost } from './def_cost_list_view';

import { AddCostWithLocation } from './add_cost_with_location';
import { ShowCostWithLocation } from './cost_list_view_with_location';

import SearchBox from '@jumbo/components/AppLayout/VerticalLayouts/ModernSidebar/ActionSideBar/Search/SearchBox';
import { getSearchedCost } from '../../../../redux/actions/CostManagementAction';

export const CostManagement = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  let { params } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);
  const [searched, setSearched] = useState('');
  const handleTabChange = (e, value) => {
    setValue(value);
  };
  const requestSearch = value => {
    setSearched(value);
  };
  useEffect(() => {
    dispatch(getSearchedCost(params, searched));
}, [params.page, params.perPage, searched]);
  return (
    <PageContainer>
       <SearchBox value={searched} onChange={requestSearch} />
      <Paper square className={'p-3'}>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="disabled tabs example">
          <Tab style={{ textTransform: 'capitalize', fontWeight: 'bold' }} label="Default Cost Management" value={0} />
          <Tab
            style={{ maxWidth: 'fit-content', textTransform: 'capitalize', fontWeight: 'bold' }}
            label="Location Wise Cost Management"
            value={1}
          />
        </Tabs>
        {value === 0 && (
          <Card className={'m-2'}>
            <AddDifCost />
            <ShowDifCost />
          </Card>
        )}
        {value === 1 && (
          <Card className={'m-2'}>
            <AddCostWithLocation />
            <ShowCostWithLocation />
          </Card>
        )}
      </Paper>
    </PageContainer>
  );
};
