import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

export default function DateSelector({ handler,name,defaultValue }) {
  const handleInputChange = event => {
    handler(event);
  };
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        name={name}
        InputProps={{inputProps: {min: moment().format('YYYY-MM-DD')}}}
        label="Select Date"
        type="date"
        defaultValue={defaultValue}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleInputChange}
      />
    </form>
  );
}
