import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: "80%"
        },
    },
}));

export const TextArea = ({value, onChange}) => {
    const classes = useStyles();

    const handleInputChange = event => {
        onChange(event.target.value)
    };
    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic"
                       size="small"
                       variant="outlined"
                       onChange={handleInputChange} value={value}/>
        </form>
    );
}
