import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles } from '../../../../redux/actions/UserRoleActions';
import Modal from '@material-ui/core/Modal';
import { getAdmins, updateAdmin } from '../../../../redux/actions/AdminActions';
import { StyleRoot } from 'radium';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  let top = 50;
  let left = 50;
  let wid = 50;

  if (window.innerWidth < 600) {
    wid = 100;
    left = 0;
    top = 0;
  }
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${wid}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  firstName: '',
  lastName: '',
  email: '',
  mobile: '',
  roleIds: [],
};

const UpdateAdmin = ({ open, setOpen, row }) => {
  console.log('row', row);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  let { allUserRoles } = useSelector(({ UserRoleReducer }) => UserRoleReducer);
  console.log('allUserROles', allUserRoles);
  const [data, setData] = useState({ ...resetForm });
  const [roles, setRoles] = useState(null);

  useEffect(() => {
    const roleIds = row.roles.map(x => x._id);
    console.log('rolesIds', roleIds);
    setRoles(row.roles);
    setData(prev => {
      return {
        ...prev,
        ...row,
        roleIds: roleIds,
      };
    });
  }, []);

  const requiredFields = {
    firstName: 'required|alpha',
    lastName: 'required|alpha',
    email: 'required|email',
    mobile: 'required|phone',
    roleIds: 'required:length',
  };

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  const handleRoleChange = (value, _id, role) => {
    let roleIds = [...data.roleIds];

    if (!value) {
      roleIds = roleIds.filter(i => i !== _id);
      setRoles(prev => {
        return prev.filter(obj => obj._id !== _id);
      });
    } else {
      roleIds.push(_id);
      setRoles(prev => [...prev, role]);
    }
    setData({ ...data, roleIds });
  };

  useEffect(() => {
    console.log('roles', roles);
  }, [roles]);

  const updateUser = () => {
    if (validator.allValid()) {
      const newData = { ...data, roles };
      delete newData.roleIds;
      console.log('newData', newData);
      dispatch(updateAdmin(newData));
    }
  };

  useEffect(() => {
    if (!allUserRoles.length) {
      dispatch(getUserRoles());
    }
    validator.showMessages();
  }, [data]);

  return (
    <StyleRoot>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Card style={modalStyle}>
            <Card.Content className={'p-4 m-4'}>
              <Typography variant="h2" className={'mb-3'}>
                Update Admin
              </Typography>
              <Container>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.firstName}
                      name={'firstName'}
                      label="First Name"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('firstName')}
                  </div>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.lastName}
                      name={'lastName'}
                      label="Last Name"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('lastName')}
                  </div>
                </div>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.email}
                      name={'email'}
                      label="Email"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('email')}
                  </div>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data?.mobile}
                      name={'mobile'}
                      label="Phone Number"
                      type="search"
                      variant="outlined"
                    />
                    {validationError('mobile')}
                  </div>
                </div>
                <Typography className={'mb-3'} variant="h2">
                  Roles
                </Typography>
                {validationError('roleIds')}
                <div className={'w-50 p-0'} style={{ marginLeft: '1em' }}>
                  <Grid container className={'p-0'} spacing={1}>
                    {allUserRoles.map(item => {
                      return (
                        <Grid item xs={4} key={item._id}>
                          <FormControlLabel
                            label={item.title}
                            control={
                              <Checkbox
                                checked={data.roleIds.includes(item._id)}
                                onChange={event => handleRoleChange(event.target.checked, item._id, item)}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Container>
              <div className={'d-flex justify-content-end'}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleClose}
                  color="secondary"
                  style={{ textTransform: 'capitalize' }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={'ml-2'}
                  size="large"
                  onClick={updateUser}
                  disabled={!validator.allValid()}
                  color="primary"
                  style={{ textTransform: 'capitalize' }}>
                  Update
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Modal>
      </div>
    </StyleRoot>
  );
};

export default UpdateAdmin;
