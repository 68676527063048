import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import firebaseConfig from '../../../../../src/key/config';
import { Box, Button } from '@material-ui/core';
import TextInputField from './TextInputField';
import { useDispatch, useSelector } from 'react-redux';
import { selectQuestion, setIndex, setKey, setQuestions } from '../../../../redux/actions/JobActions';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';

export default function FirebaseValidation() {
  const [code, setCode] = useState('');
  const { showCode, questions, index, selectedQuestion, isPhoneVerified } = useSelector(({ JobReducer }) => JobReducer);
  const dispatch = useDispatch();
  const validate = () => {
    window.confirmationResult
      .confirm(code)
      .then(result => {
        dispatch(setKey({ key: 'isPhoneVerified', value: true }));
        console.log(
          'selectedQuestion[`answer_${selectedQuestion.questionType}`]',
          selectedQuestion[`answer_${selectedQuestion.questionType}`],
        );
        dispatch(setKey({ key: 'validPhone', value: selectedQuestion[`answer_${selectedQuestion.questionType}`] }));
        sweetAlerts('Phone number verified successfully', 'success');
      })
      .catch(error => {
        console.log('error', error);
        sweetAlerts(error.message ? error.message : 'Something went wrong', 'error');
      });
  };
  const handler = value => setCode(value);

  useEffect(() => {
    //Note:when click next and then come back, useEffect is not calling therefore the recaptcha is not rerendering(displaying without sending code)

    // if firebase app not initialized, initialize it or if already initialized, use it
    !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');

    const recaptchaVerifier = window.recaptchaVerifier;

    // [START auth_phone_recaptcha_render]
    recaptchaVerifier.render().then(widgetId => {
      dispatch(setKey({ key: 'isPhoneVerified', value: false }));
      dispatch(setKey({ key: 'validPhone', value: '' }));
      window.recaptchaWidgetId = widgetId;
      const phoneNumber = selectedQuestion[`answer_${selectedQuestion.questionType}`];
      const appVerifier = window.recaptchaVerifier;
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(confirmationResult => {
          //if success response
          let ques = [...questions];
          if (!ques.find(i => i.pushedQuestion === 'Phone OTP'))
            ques.splice(index + 1, 0, {
              _id: 130,
              question: `Please enter the OTP sent on your phone`,
              questionType: 'phone_recaptcha',
              answer_phone_recaptcha: phoneNumber,
              pushedQuestion: 'Phone OTP',
            });
          dispatch(setQuestions(ques));
          dispatch(selectQuestion(ques[index + 1]));
          dispatch(setIndex(index + 1));
          dispatch(setKey({ key: 'showCode', value: true }));
          window.confirmationResult = confirmationResult;
          // ...
        })
        .catch(error => {
          if (error.code === 'auth/invalid-phone-number') sweetAlerts('Invalid Phone number', 'error');
          if (error.code === 'auth/too-many-requests') sweetAlerts('Too many requests sent', 'error');
          if (error.code === 'auth/quota-exceeded') sweetAlerts('SMS sending quota exceeded', 'error');
          console.log('error', error.code);
          // Error; SMS not sent
          // ...
        });
    });
  }, []);

  return (
    <div>
      {showCode ? (
        <div>
          <TextInputField onChange={handler} value={code} />
          <Button
            onClick={validate}
            variant="contained"
            color="primary"
            disabled={isPhoneVerified || (code && code.length != 6)}>
            Verify
          </Button>
        </div>
      ) : (
        <Box mb={2}>
          <div id="recaptcha-container"></div>
        </Box>
      )}
    </div>
  );
}
