import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { NotificationContainer, NotificationManager } from 'react-notifications/lib/NotificationManager';
import AddIcon from '@mui/icons-material/Add';
import { Container, TextField, Button, Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getProfession, getDifCosts } from '../../../../redux/actions/CostManagementAction';
import { selectCat } from '../../../../redux/actions/CategoryManagementAction';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Modal from '@material-ui/core/Modal';
import Radium, { StyleRoot } from 'radium';
import { store } from 'react-notifications-component';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  let top = 45;
  let left = 45;
  let wid = 50;
  //alert(window.innerWidth );
  if (window.innerWidth < 600) {
    wid = 100;
    left = 0;
    top = 0;
  }
  return {
    top: `${top}%`,
    left: `${left}%`,
    width: `${wid}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  profession: '',
  price: '',
};

export const AddSubCategory = ({ subCatOpen, setSubCatOpen }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  let { open, selectedCat, professions } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);

  const [data, setData] = useState({ ...resetForm });
  const [profession, setProfession] = useState('');

  //validations
  const requiredFields = {
    profession: 'required',
    price: 'required',
  };

  const validator = new SimpleReactValidator({
    autoForceUpdate: this,
    validators: {
      confirmPassword: {
        // name the rule
        message: 'The :attribute does not match password',
        rule: (val, params, validator) => {
          return params[0] === val;
        },
        required: true, // optional
      },
      length: {
        // name the rule
        message: 'At least one role must be selected',
        rule: (val, params, validator) => {
          return val.length;
        },
        required: true, // optional
      },
    },
  });
  Object.keys(requiredFields).forEach(field => {
    validator.message(field, data[field], requiredFields[field]);
  });

  //handlers

  const handleClose = () => {
    setSubCatOpen(false);
  };

  const handleChange = event => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const validationError = field => {
    if (validator.errorMessages[field]) {
      return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>;
    }
  };

  useEffect(() => {
    //dispatch(getProfession());
    //validator.showMessages();
  }, []);
  //console.log("-------",professions)
  const handleSubmit = () => {
    console.log(selectedCat);
    let newid;
    if (selectedCat.categories.length > 0) {
      newid = selectedCat.categories.reduce((prev, current) => (prev._id > current._id ? prev._id + 1 : current._id + 1), 0);
    } else {
      newid = 0;
    }
    let newObj = { _id: newid, name: data.price, questions: [] };
    selectedCat.categories.push(newObj);
    //selectCat
    dispatch(selectCat({ ...selectedCat }));
    http.post(`${rootUrl}/update-profession-categorys`, { selectedCat }).then(res => {
      sweetAlerts('SubCategory Added Successfully', 'success');
      handleClose();
      //window.location.reload();

    });
  };
  return (
    <StyleRoot>
      <div>
        <Modal
          open={subCatOpen}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Card style={modalStyle}>
            <Card.Content className={'p-4 m-4'}>
              <Container>
                <div className={'d-flex mb-3'}>
                  <div className={classes.inputField}>
                    <TextField
                      id="outlined-search"
                      onChange={handleChange}
                      style={{ width: '100%' }}
                      value={data.price}
                      name={'price'}
                      label="Sub Category Name"
                      variant="outlined"
                    />
                    {/* {validationError('price')} */}
                  </div>
                </div>
              </Container>
              <div className={'d-flex justify-content-end'}>
                <Button
                  variant="contained"
                  size="large"
                  style={{ textTransform: 'capitalize' }}
                  onClick={handleClose}
                  color="secondary">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={'ml-2'}
                  size="large"
                  style={{ textTransform: 'capitalize' }}
                  onClick={handleSubmit}
                  disabled={data.price.length === 0}
                  color="primary">
                  Create
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Modal>
      </div>
    </StyleRoot>
  );
};
