import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  const logoUrl = process.env.REACT_APP_LOGO_URL ? process.env.REACT_APP_LOGO_URL : '/images/Tradingseek.svg';
  // const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/" className="d-flex text-black">
          {/*<img src={logoUrl} alt="TradingSeekLogo" style={{width: '2rem'}}/>*/}
          <CmtImage src={logoUrl} alt="logo" color="black" />
          <img
            src={
              process.env.REACT_APP_COUNTRY !== "NET"
                ? `https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`
                : '/images/beta.png'
            }
            alt="flag"
            style={{ width: '1.2rem', margin: '10px' }}
          />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <img src={logoUrl} alt="TradingSeekLogo" style={{ width: '2rem' }} />
          <img
            src={
              process.env.REACT_APP_COUNTRY !== "NET"
                ? `https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`
                : '/images/beta.png'
            }
            alt="flag"
            style={{ width: '20px', height: '20px', margin: '10px 30px' }}
          />
          {/*<CmtImage src={logoSymbolUrl} alt="logo" />*/}
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
