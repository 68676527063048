import React, { forwardRef, useEffect, useState, Fragment } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import Checkbox from '@material-ui/icons/CheckBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import UpdateAdmin from 'routes/Pages/Authenticated/admins/update';
import { IconButton } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Checkbox: forwardRef((props, ref) => <Checkbox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ApmTable({
  columns,
  data,
  title,
  addHandler,
  updateHandler,
  deleteHandler,
  paramsHandler,
  params,
  actions = [],
  name,
  showActions = true,
}) {
  const classes = useStyles();
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [state, setState] = useState({
    columns: [
      ...columns,
      {
        title: 'Actions',
        field: '',
        editable: false,
        render: rowData => {
          return (
            <div style={{ transform: 'translateX(-8px)' }}>
              <IconButton
                onClick={() => {
                  setSelectedRow(rowData);
                  setOpen(true);
                }}>
                <EditIcon style={{ color: 'black' }} />
              </IconButton>
              <IconButton onClick={() => deleteHandler(rowData)}>
                <DeleteOutlineIcon style={{ color: 'black' }} />
              </IconButton>
            </div>
          );
        },
      },
    ],
    data: data,
  });

  useEffect(() => {
    setState({ ...state, data: data });
  }, [data, name]);

  useEffect(() => {
    console.log('selectedRow', selectedRow);
  }, [selectedRow]);

  return (
    <Fragment>
      <MaterialTable
        actions={actions}
        className={classes.root}
        icons={tableIcons}
        title={`${title}`}
        key={state.data.length}
        columns={state.columns}
        data={state.data}
        options={{
          pageSize: params.perPage,
          emptyRowsWhenPaging: false,
          selection: true,
          search: false,
          actionsColumnIndex: -1,
        }}
        components={{
          Pagination: props => (
            <TablePagination
              {...props}
              rowsPerPageOptions={[5, 25, 50, 100, 200]}
              rowsPerPage={params.perPage}
              count={params.total}
              page={params.page}
              onChangePage={(e, page) => {
                paramsHandler('page', page);
              }}
              onChangeRowsPerPage={event => {
                paramsHandler('perPage', event.target.value);
              }}
            />
          ),
        }}
      />
      {open ? <UpdateAdmin open={open} setOpen={setOpen} row={selectedRow} /> : null}
    </Fragment>
  );
}
