import React, {useEffect, useState} from "react";
import {Card, Typography} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import {http} from "../../../../../appmakers/services/http-service";
import {rootUrl} from "../../../../../api-handling/rootUrl";
import {useSelector} from "react-redux";
import {useParams} from "react-router";

const ReviewComment = () => {
    const {selectedUser} = useSelector(({BusinessDirectoryReducer}) => BusinessDirectoryReducer);
    const {id} = useParams();

    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        getReviews()
    }, [reviews])
    const getReviews = () => {
        http.get(`${rootUrl}/get-reviews/${id}`).then(res => {
            setReviews(res.data);
        }).catch(error => {
            console.log('Error', error);
        })
    }
    return (
        <div className="d-flex flex-col ml-4 align-content-between pt-2 text-gray-800">
            <Typography component="h4" variant="h4">Jobs completed by {selectedUser.businessName} company</Typography>
            {reviews && reviews?.map((review, index) => {
                return (
                    <div className="mt-6 mr-4 p-2 shadow border-r-4" style={{background: 'whitesmoke'}}>
                        <Typography component="h4" variant="h4">
                            {review.postedUserName}
                        </Typography>
                        <div className="d-flex flex-row align-items-center">
                            <Rating name="half-rating" defaultValue={review.stars} size="small" className="mt-2"/>
                            <Typography component="h6" variant="h6" aria-setsize="50"
                                        color={"primary"}>({review.stars})</Typography>

                        </div>
                        <Typography component="div" variant="body2">
                            {review.comment}
                        </Typography>
                    </div>
                )
            })}
        </div>
    )
}

export default ReviewComment
