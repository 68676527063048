import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '30rem',
    },
  },
}));

export default function TextFieldComponent({ handler, val }) {
  const classes = useStyles();

  const [value, setValue] = React.useState(val);
  useEffect(() => {
    setValue(val);
  }, [val]);

  const handleInputChange = event => {
    setValue(event.target.value === '' ? '' : event.target.value);
    handler(event);
  };
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField id="outlined-basic" label="Outlined" variant="outlined" onChange={handleInputChange} value={value} />
    </form>
  );
}
