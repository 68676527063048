import {
    SET_PROFESSIONS,
    SET_QUESTIONS,
    SET_CATEGORY,
    SET_MODAL,
    SELECT_QUESTION,
    SET_INDEX,
    SET_KEY
} from "../constants/JobActionTypes";

const initialState = {
    isJobModal:false,       //flag for post job from 'post a job' button in category carousel
    loading: false,
    addressAutoCompleteKey:true,
    professions: [],
    profession: null,
    address: '',
    autocomplete:'',
    isPhoneVerified: false,
    validPhone: '',
    showCode: false,
    category: null,
    validEmailEntered: false,
    questions: [],
    selectedQuestion: {},
    showModal: false,
    showEmailModal: false,
    emailRequest: false,
    showFirebaseRecaptcha: false,
    index: 0,
    others: {
        'Other (Please specify)': 'textarea',
        'Other (please specify)': 'textarea',
        'Specific date': 'dateSelector',
        'Total': 'BudgetInput',
        'Hourly Rate': 'BudgetInput'
    },
    userInfo: {
        email: '',
        isEmailVerified: false,
        new: false
    }
};

export const JobReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_PROFESSIONS: {
            return {
                ...state,
                professions: payload
            }
        }
        case SET_QUESTIONS: {
            return {
                ...state,
                questions: payload
            }
        }
        case SET_KEY: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }
        case SELECT_QUESTION: {
            return {
                ...state,
                selectedQuestion: payload
            }
        }
        case SET_CATEGORY: {
            return {
                ...state,
                category: payload
            }
        }
        case SET_INDEX: {
            return {
                ...state,
                index: payload
            }
        }
        case SET_MODAL: {
            return {
                ...state,
                showModal: payload
            }
        }
        default:
            return state
    }
}
