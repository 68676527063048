import React from 'react';
import SignIn from '../../@jumbo/components/Common/authComponents/SignIn';

const Login = () => {

    return <div className="w-100 d-flex flex-row justify-content-center align-items-center">
        <SignIn variant="default" wrapperVariant="bgColor"/>
    </div>
}

export default Login;
