import React, { useEffect, useState, useRef } from 'react';
import UserPreference from './userpreferences';
import LeadPreferences from './leadpreferences';
import LeadPreferencesLocation from './leadpreferences-location';
import MonthsStatisticsLead from './monthlyStatisticsLead';
import MonthsStatisticsUser from './monthlyStatisticsUser';

import WeeklyStatisticsLead from './weeklyStatisticsLead';
import WeeklyStatisticsUser from './weeklyStatisticsUser';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import WelcomeMessage from './welcomemessage';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { getProfession } from '../../../../redux/actions/CostManagementAction';
import { setKey } from '../../../../redux/actions/DashboardAction';
import Card from '@material-ui/core/Card';
import moment from 'moment';
import { fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { rootUrl } from '../../../../api-handling/rootUrl';
import { http } from '../../../../appmakers/services/http-service';
const { getStartAndEndOfDayInUTC,
  getStartAndEndOfMonthInUTC,
  getStartAndEndOfYearInUTC,
  getStartAndEndOfMonthInUTCForInsert,
  getOffSet } = require("../../../../helpers/dateHelper")

const userStyle = {
  '@media (max-width: 600px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 0px 0px -25px',
    float: 'left',
    padding: '20px',
  },
  '@media (min-width: 601px)': {
    boxShadow: '5px 7px 19px grey',
    width: '357px',
    backgroundColor: 'white',
    margin: '0px 10px',
    float: 'left',
  },
};

const useStyles = makeStyles(() => ({
  welcomeDisplay: {
    display: 'flex',
    ['@media (max-width:741px)']: {
      display: 'block'
    }
  },
  searchDivPosition: {
    ['@media (max-width:741px)']: {
      position: 'absolute !important'
    }
  },
  rootWidth: {
    width: '80%',
    ['@media (max-width:550px)']: {
      width: '95%'
    }
  }
}))
export const Dashboard = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const myRef = useRef(null);
  const myRef1 = useRef(null);

  const utcOffset = getOffSet();

  const { count, weeksInCurrentMonth, selectedWeek, selectedWeekStatistics, weekDays } = useSelector(({ DashboardReducer }) => DashboardReducer);

  useEffect(() => {
    dispatch(getProfession());
  }, []);

  const handleWeekChange = (value) => {
    if (count === 2) {
      dispatch(setKey({ key: 'selectedWeek', value: value }));
      const startDateUTC = moment.utc(value.startDate);
      const startDate = startDateUTC.clone().utcOffset(utcOffset);
      const endDateUTC = moment.utc(value.endDate);
      const endDate = endDateUTC.clone().utcOffset(utcOffset);
      dispatch(setKey({ key: 'startDate', value: startDate.clone().startOf('day') }));
      dispatch(setKey({ key: 'endDate', value: endDate.clone().endOf('day') }));
    }
  };

  const handleWeekChangeStatistics = (value) => {
    dispatch(setKey({ key: 'selectedWeekStatistics', value: value }));
    const startDateUTC = moment.utc(value.startDate);
    const startDate = startDateUTC.clone().utcOffset(utcOffset);
    const endDateUTC = moment.utc(value.endDate);
    const endDate = endDateUTC.clone().utcOffset(utcOffset);
    console.log(startDate.clone().startOf('day'))
    console.log(endDate.clone().endOf('day'))
    const weekDays = getDatesInRange(startDate, endDate);
    dispatch(setKey({ key: 'weekDays', value: weekDays }));
    dispatch(setKey({ key: 'startDateStatistics', value: startDate.clone().startOf('day') }));
    dispatch(setKey({ key: 'endDateStatistics', value: endDate.clone().endOf('day') }));
  };

  function getDatesInRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dateArray = [];
    let currentDate = start;
    while (currentDate <= end) {
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  useEffect(() => {
    const currentMonth = moment().startOf('month');
    const monthEnd = moment().endOf('month');

    // Adjust week start and end based on your requirement (Sunday to Saturday)
    const weekStart = currentMonth.clone().subtract(currentMonth.day(), 'days');
    const weekEnd = weekStart.clone().add(6, 'days');

    const weeks = [];
    while (weekStart.isSameOrBefore(monthEnd)) {
      weeks.push({
        value: { startDate: weekStart.format('YYYY-MM-DD'), endDate: weekEnd.format('YYYY-MM-DD') }, // Use a format suitable for your needs
        label: `${weekStart.format('DD-MMM')} to ${weekEnd.format('DD-MMM')}`, // Format week range for display
      });
      weekStart.add(7, 'days');
      weekEnd.add(7, 'days');
    }
    dispatch(setKey({ key: 'weeksInCurrentMonth', value: weeks }));
    const currentWeek = weeks.find((week) => moment().isBetween(moment(week.value.startDate), moment(week.value.startDate).add(6, 'days'), 'day'));
    handleWeekChangeStatistics(currentWeek.value)
    myRef1.current.focus();

    if (count !== 2) {
      dispatch(setKey({ key: 'selectedWeek', value: null }));
    } else {
      handleWeekChange(currentWeek.value)
      myRef.current.focus();
    }
  }, [count]);

  const refreshData = () => {
    dispatch(fetchStart());
    http
      .get(`${rootUrl}/update-dashboard-data`)
      .then(res => {
        dispatch(fetchStart());
        window.location.reload();
      })
  }
  const handleCountChange = (event) => {
    dispatch(setKey({ key: 'count', value: event.target.value }));
    const nowUTC = moment.utc();
    const now = nowUTC.clone().utcOffset(utcOffset);
    let startDate;
    let endDate;
    if (event.target.value == 4) {
      startDate = now.clone().startOf('year');
      endDate = now.clone().endOf('year');
    } else if (event.target.value == 3) {
      startDate = now.clone().startOf('month');
      endDate = now.clone().endOf('month');
    } else if (event.target.value == 1) {
      startDate = now.clone().startOf('day');
      endDate = now.clone().endOf('day');
    } else {
      return
    }
    dispatch(setKey({ key: 'startDate', value: startDate }));
    dispatch(setKey({ key: 'endDate', value: endDate }));
  };
  useEffect(() => {
    if (!utcOffset)
      return
    if (count != 1)
      return
    const nowUTC = moment.utc();
    const now = nowUTC.clone().utcOffset(utcOffset);
    let startDate = now.clone().startOf('day');
    let endDate = now.clone().endOf('day');
    dispatch(setKey({ key: 'startDate', value: startDate }));
    dispatch(setKey({ key: 'endDate', value: endDate }));
  }, [utcOffset])
  return (
    <div className={`${classes.rootWidth}`} style={{ margin: '0px auto' }}>
      <div className={`${classes.welcomeDisplay} justify-content-between`}>
        <WelcomeMessage />
        <div className={`${classes.searchDivPosition}`}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={() => {
              history.push('/user-search');
            }}
            className={'text-capitalize'}>
            User Search
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<SearchIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={() => {
              history.push('/lead-search');
            }}
            className={'text-capitalize'}>
            Lead Search
          </Button>
        </div>
        {/* <div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<RotateRightIcon />}
            style={{ margin: '10px 7px', float: 'right', borderRadius: '20px', height: '25px' }}
            onClick={refreshData}
            className={'text-capitalize'}>
            Refresh Data
          </Button>
        </div> */}
      </div>

      <Divider style={{ marginBottom: '20px' }} />
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', minWidth: 120, margin: '10px 0px' }}>
        <FormControl sx={{ minWidth: 200 }}> {/* Adjust the width as needed */}
          <InputLabel id="demo-simple-select-label">Select view</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={count}
            label="Select view"
            onChange={handleCountChange}
          >
            <MenuItem value={1}>Today's Summary</MenuItem>
            <MenuItem value={2}>Weekly Summary</MenuItem>
            <MenuItem value={3}>Monthly Summary</MenuItem>
            <MenuItem value={4}>Yearly Summary</MenuItem>
          </Select>
        </FormControl>
        {count == 2 && <FormControl sx={{ minWidth: 200, marginLeft: '10px' }} ref={myRef}
        > {/* Adjust the width as needed */}
          <InputLabel id="demo-simple-select-label1">Select week</InputLabel>
          <Select
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={selectedWeek}
            label="Select week"
            onChange={(e) => { handleWeekChange(e.target.value) }}
            disabled={count !== 2}
            autoFocus
          >
            {weeksInCurrentMonth.map((week) => (
              <MenuItem key={week.value} value={week.value}>
                {week.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>}
      </Box>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={12} md={4} style={{ display: 'flex' }}>
          <Card style={{ flexGrow: 1 }}>
            <UserPreference />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
          <Card boxShadow={5} style={{ flexGrow: 1 }}>
            <LeadPreferences />
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
          <Card boxShadow={5} style={{ flexGrow: 1 }}>
            <LeadPreferencesLocation />
          </Card>
        </Grid>
      </Grid>

      <Divider style={{ marginTop: '20px' }} />
      <div>
        <FormControl sx={{ minWidth: 200, marginLeft: '10px', marginTop: '10px' }} ref={myRef1}>
          <InputLabel id="demo-simple-select-label1">Select week</InputLabel>
          <Select
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={selectedWeekStatistics || []}
            label="Select week"
            onChange={(e) => { handleWeekChangeStatistics(e.target.value) }}
          //autoFocus
          >
            {weeksInCurrentMonth.map((week) => (
              <MenuItem key={week.value} value={week.value}>
                {week.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <WeeklyStatisticsLead />
            </Grid>
            <Grid item xs={6}>
              <WeeklyStatisticsUser />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <MonthsStatisticsLead />
            </Grid>
            <Grid item xs={6}>
              <MonthsStatisticsUser />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
