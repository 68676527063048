import {
    FETCH_BUSINESS_USER,
    FETCH_PROFESSION, FETCH_REVIEWS_BY_PROFESSION, FETCH_REVIEWS_BY_USER_ID,
    GET_ALL_PROFESSIONS,
    GET_SELECTED_BUSINESSES
} from "../constants/ActionTypes";

export const getAllProfessions = (professions) => {
    return {
        type: GET_ALL_PROFESSIONS,
        payload: professions
    }
}

export const getSelectedBusinesses = (selectedBusinesses) => {
    return {
        type: GET_SELECTED_BUSINESSES,
        payload: selectedBusinesses
    }
}

export const fetchBusinessUser = (businessUser) => {
    return {
        type: FETCH_BUSINESS_USER,
        payload: businessUser
    }
}
export const fetchProfession = (profession) => {
    return {
        type: FETCH_PROFESSION,
        payload: profession
    }
}
export const fetchReviewsByProfession = (profession) => {
    return {
        type: FETCH_REVIEWS_BY_PROFESSION,
        payload: profession
    }
}
export const fetchReviewsByUserId = (profession) => {
    return {
        type: FETCH_REVIEWS_BY_USER_ID,
        payload: profession
    }
}
