import React, {useEffect, useState} from "react";
import {Card} from "semantic-ui-react";
import SimpleReactValidator from 'simple-react-validator';
import {
    Container,
    TextField,
    Button,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography
} from "@material-ui/core"
import {makeStyles} from "@material-ui/styles";
import {useDispatch, useSelector} from "react-redux";
import {getUserRoles} from "../../../../redux/actions/UserRoleActions";
import Modal from '@material-ui/core/Modal';
import {updateDiffCost, toggleModal} from "../../../../redux/actions/CostManagementAction";
import Autocomplete from '@material-ui/lab/Autocomplete';
import searchLocations from "../../../../api-handling/fetchLocations";
import {NotificationContainer,NotificationManager} from "react-notifications";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 40 + rand();
    const left = 45 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        width: "50%",
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    inputField: {
        flex: 1,
        marginLeft: '0.5em'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}))

const resetForm = {
    profession: '',
    Location:'',
    price: 0
}

export const UpdateDefCost = () => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const dispatch = useDispatch();
    let {open, selectedCost,professions} = useSelector(({CostManagementReducer}) => CostManagementReducer)
    const [data, setData] = useState({...resetForm})
    const [profession, setProfession] = useState('')
    const [price,setPrice] = useState(0);
    let [editId,setEditId]= useState('');
    function usePrevious(value) {
        const ref = React.useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevData = usePrevious(data)
    //validations
    const requiredFields = {
        profession: 'required',
        price: 'required'
    }



    const handleChange = event => {
        console.log(data)
        setData({...data, [event.target.name]: event.target.value})
    };
    const validator = new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            length: {  // name the rule
                message: 'At least one role must be selected',
                rule: (val, params, validator) => {
                    return val.length
                },
                required: true  // optional
            },
        }
    });
    Object.keys(requiredFields).forEach(field => {
        validator.message(field, data[field], requiredFields[field])
    })


    //handlers
    const handleOpen = () => {
        dispatch(toggleModal(true))
    };

    const handleClose = () => {
        dispatch(toggleModal(false))
    };

    const validationError = (field) => {
        if (validator.errorMessages[field]) {
            return <span className={'text-danger m-1 ml-2'}>{validator.errorMessages[field]}</span>
        }
    }

    

    const update = () => {
        //console.log(data)
        let profId = profession?profession._id:selectedCost._id;
        let cost = (price)?price:selectedCost.credits;

        // console.log(selectedCost)
        // console.log(profId)
        // console.log(price)
        // console.log(cost)
        dispatch(updateDiffCost({profession:profId,price:cost,_id:selectedCost._id}))
        handleClose()
        //dispatch(updateCost({...selectedCost, data: data}))
    }

  

    useEffect(() => {
      validator.showMessages();
      setEditId(selectedCost?._id)
    }, [selectedCost])

    return <div>
        <NotificationContainer style={{display: 'none'}}/>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Card style={modalStyle}>
                <Card.Content className={'p-4 m-4'}>
                    <Container>
                        <Typography variant="h2" className="mt-5 ml-3 fw-bolder">Update Cost</Typography>
                    <div className={'d-flex mb-3'}>
                            <div className={classes.inputField}>
                            <Autocomplete className="mt-3"
                              id="combo-box-demo"
                              limitTags={10}
                              options={professions}
                              name="profession"
                            label="Profession" 
                            variant="outlined"
                            //value={data.profession}
                            getOptionLabel={(option) => option.name || ""}
                            //value={profession?profession:selectedCost?.profession}
                            defaultValue={selectedCost?selectedCost:''}
                           // onChange={handleChange}
                            onChange={(event, value) => setProfession(value)}
                              renderInput={params => (
                                  <TextField
                                      {...params}
                                      label="Profession"
                                      variant="outlined"
                                      onChange={handleChange}
                                  />
                              )}
                            />
                              {/* {validationError('profession')} */}
                            </div>
                            
                        </div>
                        
                        <div className={'d-flex mb-3'}>
                            <div className={classes.inputField}>
                                <TextField id="outlined-search"
                                           onChange={e => {
                                            setPrice(e.target.value);
                                        }}
                                           style={{width: "100%"}}
                                           defaultValue={selectedCost?.credits}
                                           //value={data.price?data.price:selectedCost?.price}
                                           name={'price'}
                                           label="Price In $" 
                                           variant="outlined"
                                           type="number" />
                                {/* {validationError('price')} */}
                            </div>
                        </div>
                    </Container>
                    <div className={'d-flex justify-content-end'}>
                        <Button variant="contained"
                                size="large"
                                style={{textTransform:"capitalize"}}
                                onClick={handleClose}
                                color="secondary">
                            Cancel
                        </Button>
                        <Button variant="contained"
                                className={'ml-2'}
                                style={{textTransform:"capitalize"}}
                                size="large"
                                onClick={update}
                                //disabled={!validator.allValid()}
                                color="primary">
                            Update
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        </Modal>
    </div>
};
