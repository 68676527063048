import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          const user = { name: name, email: email, password: password };
          localStorage.setItem('adminUser', JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          const user = { name: 'Admin', email: email, password: password };
          dispatch(fetchSuccess());
          localStorage.setItem('adminUser', JSON.stringify(user));
          dispatch(setAuthUser(user));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(fetchSuccess());
          localStorage.removeItem('adminUser');
          dispatch(setAuthUser(null));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        dispatch(updateLoadUser(loaded));

        setTimeout(() => {
          dispatch(fetchSuccess());
          dispatch(setAuthUser(JSON.parse(localStorage.getItem('adminUser'))));
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      try {
        dispatch(fetchStart());

        setTimeout(() => {
          dispatch(setForgetPassMailSent(true));
          dispatch(fetchSuccess());
        }, 300);
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default BasicAuth;
