import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Divider, TextField } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import PicUploadNoCrop from "./editProfilePicModal";
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    width: '29rem',
    maxHeight: '80%',
    overflow: 'auto'
  },
}));

export default function EditProfilePicModal({ handleClose, open }) {

  const classes = useStyles();
  let [picture, setFile] = useState(null);
  let [src, setSrc] = useState(null);
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const uploadImageInGallery = async (file) => {
    const fsize = file.size;
    const newFile = Math.round((fsize / 1024));
    const src = URL.createObjectURL(file);
    console.log(src);
    if (newFile >= 4096) {
      alert("File too Big, please select a file less than 4mb");
    }
    else if (file.type.split("/")[0] === "image") {
      setFile({ file })
      setSrc({ src })

    }
    else {
      alert("Invalid file type");
      window.location.reload();
    }
  }
  const renderPreview = () => {
    if (src) {
      return (
        <img src={src} />
      );
    } else {
      return (
        <p>
          No Preview
        </p>
      );
    }
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <PicUploadNoCrop handleClose={handleClose} dispatch={dispatch} authUser={authUser} />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}