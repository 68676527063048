import React, { useEffect, useState } from 'react';
import MyProfileAvatar from "./MyProfileAvatar";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import EditProfileForm from "./EditProfileForm";
import { http } from '../../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../../api-handling/rootUrl';
import sweetAlerts from '../../../../../appmakers/components/SweetAlertHelper';
import FirebaseValidation from './FirebaseValidation';
import { fetchAndUpdateAuthUser } from '../../../../../redux/actions/Auth';


import { useDispatch, useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import Password from "../password";

const useStyles = makeStyles({
    header: {
        marginLeft: '3%',
        ['@media (max-width:500px)']: {
            marginLeft: '2rem',
        },
        marginTop: '1%',
        fontWeight: 'bold'

    },
    myProfileRoot: {
        // display: 'flex',
        // flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '1rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        ['@media (max-width:500px)']: {
            paddingRight: '0rem',
        }
    },
    avatarAndName: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    myProfileEmail: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        //margin: '2vh',
    },
    myProfilePhone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '-10px',
        //margin: '2vh',
    },
    myEmail: {
        marginLeft: '3rem',
        ['@media (max-width:500px)']: {
            marginLeft: '0.5rem',
        },
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    myPhone: {
        marginLeft: '2.5rem',
        ['@media (max-width:500px)']: {
            marginLeft: '0.5rem',
        },
        marginTop: '1rem',
        marginBottom: '1rem'
    },
    verifiedBadge: {
        borderRadius: '12%',
        border: '1px solid green',
        color: 'green',
        fontSize: '0.7rem',
        paddingLeft: '0.2rem',
        paddingRight: '0.2rem',
        //marginTop: '-13px',

    },
    notVerifiedBadge: {
        //borderRadius: '12%',
        border: '1px solid red',
        color: 'red',
        fontSize: '0.7rem',
        paddingLeft: '0.2rem',
        paddingRight: '0.2rem',
        cursor: 'pointer',
        boxShadow: '-1.5px 1.5px'
        //marginTop: '-13px'
    },
    editProfileBtn: {
        ['@media (max-width:500px)']: {
            marginLeft: '-20px',
        },
        // display: 'flex', flexDirection: 'column', justifyContent: 'center'
    },
    editPwd: {

        ['@media (min-width:500px)']: {
            width: '50%',
        },
        marginLeft: '40px',
        ['@media (max-width:500px)']: {
            marginLeft: '27px',
        },
        marginTop: '40px'
    }
})
const editProfile = {
    '@media (max-width: 800px)': {
        padding: '0px 15px', fontSize: '10px', position: 'absolute'
    }
}
const MyProfile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { authUser } = useSelector(({ auth }) => auth);

    const [formFlag, setFormFlag] = useState(false);
    const [formFlagPwd, setFormFlagPwd] = useState(false);
    const [showFirebase, setShowFirebase] = useState(false);

    const setFormFlagHandler = (value) => {
        setFormFlag(value)
    }
    useEffect(() => {
        dispatch(fetchAndUpdateAuthUser());
    }, [showFirebase, formFlag])

    return (
        <StyleRoot>
            <div>
                {!formFlagPwd && <h4 className={classes.header}>My Profile</h4>}
                <div className={classes.myProfileRoot}>
                    <div>
                        {!formFlag && !formFlagPwd &&
                            <div>
                                <div className={classes.avatarAndName}>
                                    <MyProfileAvatar />
                                    <h3>{authUser?.userName}</h3>
                                </div>
                                <div className={classes.myProfileEmail}>
                                    <label>Email</label>
                                    <div className='tooltipmsgsSide'>
                                        <p className={classes.myEmail}>{authUser?.email
                                        }</p>
                                    </div>
                                    &nbsp;
                                    {/* {authUser?.isEmailVerified && <div className={classes.verifiedBadge}>Verified</div>}
                                    {!authUser?.isEmailVerified && <div className={classes.notVerifiedBadge} onClick={sendVerificationMail}>Verify Now</div>} */}
                                </div>
                                {authUser?.mobile && (<div className={classes.myProfilePhone}>
                                    <label>Phone</label>
                                    <p className={classes.myPhone}>{authUser?.mobile}</p>&nbsp;
                                    {/* {authUser?.isPhoneVerified && <div className={classes.verifiedBadge}>Verified</div>}
                                    {!authUser?.isPhoneVerified && <div className={classes.notVerifiedBadge} onClick={() => { setShowFirebase(true) }}>Verify Now</div>} */}
                                </div>)}
                                {authUser?.mobile && !authUser?.isPhoneVerified && showFirebase && (<FirebaseValidation setShowFirebase={setShowFirebase} />)}

                            </div>
                        }
                    </div>
                    {!formFlag && !formFlagPwd &&
                        <div className={classes.editProfileBtn} style={editProfile}>
                            <Button variant="outlined" style={{ textTransform: "capitalize" }} onClick={() => setFormFlagHandler(true)}>Edit Profile</Button>
                        </div>
                    }
                </div>
                <div className={classes.editPwd} >
                    {!formFlag && <Password setFormFlagPwd={setFormFlagPwd} setFormFlagHandlerProfile={setFormFlagHandler} />}
                </div>
                {
                    formFlag &&
                    <EditProfileForm setFormFlagHandler={setFormFlagHandler} />
                }
            </div>
        </StyleRoot>
    );
}

export default MyProfile;
