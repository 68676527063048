import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, Card, fade, IconButton } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import PasswordInput from '../../../../routes/Pages/Common/JobModal/PasswordInput';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    background: 'white',
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '30%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
  },
}));

const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  useEffect(() => {
    console.log('email', email, password);
  }, [email, password]);
  const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

  const handleKeypress = event => {
    console.log('inside event');
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onSubmit = () => {
    console.log('inside onSubmit');
    let err = {};
    err.email = !email || !emailRegex.test(email) ? true : null;
    err.password = !password ? true : null;
    setErrors(err);
    if (!Object.values(err).includes(true)) dispatch(AuhMethods[method].onLogin({ email: email.toLowerCase(), password }));
  };

  return (
    <Card className={classes.authContent}>
      <div className="d-flex justify-content-center mb-4">
        <CmtImage src={process.env.REACT_APP_LOGO_URL} alt="logo" color="black" />
        <img
          src={
            process.env.REACT_APP_COUNTRY !== "NET"
              ? `https://flagcdn.com/16x12/${process.env.REACT_APP_COUNTRY.toLowerCase()}.png`
              : '/images/beta.png'
          }
          alt="flag"
          style={{ width: '1.2rem', margin: '10px' }}
        />
        {/*<img src={process.env.REACT_APP_LOGO_URL} alt="logo" style={{width: '2rem'}}/>*/}
      </div>
      <Typography component="div" variant="h1" className={classes.titleRoot}>
        Admin Login
      </Typography>
      <Box mb={2}>
        <TextField
          size="small"
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          onChange={event => setEmail(event.target.value)}
          defaultValue={email}
          onKeyPress={handleKeypress}
          margin="normal"
          variant="outlined"
          className={classes.textFieldRoot}
        />
        {errors.email && <p className="text-danger ml-1">Valid Email field is required</p>}
      </Box>

      <Box mb={2}>
        <PasswordInput onChange={value => setPassword(value)} onKeyPress={handleKeypress} val={password} label="Password" />
        {errors.password && <p className="text-danger ml-1">Password field is required</p>}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={5} fontSize={{ lg: 16, md: 15, sm: 14 }}>
        <FormControlLabel
          className={classes.formcontrolLabelRoot}
          control={<Checkbox name="checkedA" />}
          label="Remember me"
        />
        <Box component="p" fontSize={{ lg: 16, md: 15, sm: 14 }}>
          <NavLink to="/forgot-password">Forgot Password?</NavLink>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={5} fontSize={{ lg: 16, md: 15, sm: 14 }}>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Sign In
        </Button>
      </Box>

      <ContentLoader />
    </Card>
  );
};

export default SignIn;
