import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: '25ch',
    },
}));

export default function BudgetInput({onChange, value}) {
    const classes = useStyles();

    const handleInputChange = event => {
        onChange(event.target.value)
    };

    return (
        <FormControl className={classes.margin} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
                type="number"
                id="outlined-adornment-amount"
                value={value}
                onChange={handleInputChange}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                labelWidth={60}
            />
        </FormControl>
    );
}
