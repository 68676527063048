import React, { useEffect, useState } from 'react';
import {
  Business,
  Help,
  Home,
  Info,
  MarkunreadMailbox,
  PostAdd,
  RecentActors,
  StoreMallDirectory,
  StoreMallDirectoryTwoTone,
  Work,
} from '@material-ui/icons';
import CmtHorizontal from '../../../../../@coremat/CmtNavigation/Horizontal';
import IntlMessages from '../../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import { getMenu } from '../../../../../redux/actions/RoleManagement';

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const { menu } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const [navigationMenus, setNavigationMenus] = useState([]);

  useEffect(() => {
    if (!menu || !menu.length) dispatch(getMenu());
    else {
      setNavigationMenus(
        menu.map(item => {
          return {
            name: <IntlMessages id={item.title} />,
            type: 'item',
            link: item.link,
          };
        }),
      );
    }
  }, [menu]);

  return <CmtHorizontal menuItems={navigationMenus} />;
};

export default HeaderMenu;
