import {
    SET_KEY,
} from '../constants/DashboardActionTypes';


const initialState = {
    count: 1,
    selectedWeek: null,
    selectedWeekStatistics: null,
    weeksInCurrentMonth: [],
    startDate: null,
    endDate: null,
    startDateStatistics: null,
    endDateStatistics: null,
    weekDays: []
}

export const DashboardReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_KEY: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }
        default:
            return state
    }
}