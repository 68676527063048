import { fetchError, fetchStart, fetchSuccess } from "./Common";
import { http } from "../../appmakers/services/http-service";
import { rootUrl } from "../../api-handling/rootUrl";
import NotificationManager from "react-notifications/lib/NotificationManager";

export const bulkUploadRecords = (file) => {
    console.log('file', file);

    return (dispatch) => {
        dispatch(fetchStart())
        let formData = new FormData();
        formData.append('file', file)
        http.post(`${rootUrl}/bulk-upload`, formData).then(response => {
            //dispatch(fetchSuccess())
        }).catch(error => {
            dispatch(fetchError())
        })
    }
}
