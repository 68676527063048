import React, {useEffect} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const SubrubsInfo = ({user}) => {
    const subrubs = ['Allenton', 'Alma', 'Amberley', 'Anderson Park', 'Andersons Bay', 'Annesbrook', 'Aotea', 'Apiti', 'Appleby', 'Aranui', 'Arapuni', 'Ardgowan', 'Aria', 'Arkles Bay']
    useEffect(() => {

    }, [user])
    return (
        <div className="d-flex flex-col ml-4 align-content-between pt-2 text-gray-800">
            <Typography component="h4" variant="h4">Browse {user?.businessProfession} by subrub</Typography>
            <div className="pr-2 mt-3">
                <Grid container spacing={4} style={{color: 'rgb(0, 145, 234)'}}>
                    {user?.locations?.map((item, index) => {
                        return (
                            <Grid item md={6} key={index}>
                                <Link to={`/profession/${user?.businessProfession}?selectedLocation=${item?.locality}`}>{item?.locality}</Link>
                            </Grid>
                        )
                    })}
                </Grid>
                <Button variant="contained" style={{textTransform:"capitalize"}} className="m-3" color="primary">View Subrubs Directory</Button>
            </div>
        </div>
    )
}

export default SubrubsInfo
