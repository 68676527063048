import React, { useEffect, useState } from 'react';
import firebase from "firebase";
import firebaseConfig from '../../../../../../src/key/config';
import { Box, Button } from "@material-ui/core";
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import sweetAlerts from "../../../../../appmakers/components/SweetAlertHelper";
import TextInputField from "../../../Common/JobModal/TextInputField";
import { http } from '../../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../../api-handling/rootUrl';
import { CropLandscapeOutlined } from '@material-ui/icons';
import Modal from '@mui/material/Modal';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 430,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
};
export default function FirebaseValidation({ setShowFirebase }) {
    const [code, setCode] = useState('');
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const [showOtp, setShowOtp] = useState(false);

    const { authUser } = useSelector(({ auth }) => auth);

    const dispatch = useDispatch();
    const validate = () => {
        window.confirmationResult.confirm(code).then((result) => {
            setIsPhoneVerified(true)
            http
                .put(`${rootUrl}/update-user-field`, { value: true, field: 'isPhoneVerified' })
                .then(response => {
                    sweetAlerts('Phone number verified successfully', 'success')
                    setShowFirebase(false)
                    setCode('')
                    setShowOtp(false)
                })
                .catch(error => {

                    sweetAlerts(error.data, 'error');
                });
            ///update-user-field
        }).catch((error) => {
            console.log('error', error);
            setShowFirebase(false)
            setCode('')
            setShowOtp(false)
            sweetAlerts('Something went wrong', 'error')
        });
    }
    const handler = (value) => setCode(value.replace(/[^0-9]/g, ''))

    useEffect(() => {
        //Note:when click next and then come back, useEffect is not calling therefore the recaptcha is not rerendering(displaying without sending code)

        // if firebase app not initialized, initialize it or if already initialized, use it
        !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();

        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                console.log('response', response)                // reCAPTCHA solved, allow signInWithPhoneNumber.

            }
        });

        const recaptchaVerifier = window.recaptchaVerifier;

        // [START auth_phone_recaptcha_render]
        recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
            const phoneNumber = authUser.phone;
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    //if success response
                    // let ques = [...questions];
                    // if (!ques.find(i => i.pushedQuestion === 'Phone OTP'))
                    //     ques.splice(index + 1, 0, {
                    //         _id: 130,
                    //         question: `Please enter the OTP sent on your phone`,
                    //         questionType: "phone_recaptcha",
                    //         answer_phone_recaptcha: phoneNumber,
                    //         pushedQuestion: 'Phone OTP',
                    //     })

                    setShowOtp(true);
                    window.confirmationResult = confirmationResult;
                    // ...
                }).catch((error) => {
                    if (error.code === 'auth/invalid-phone-number')
                        sweetAlerts('Invalid Phone number', 'error')
                    if (error.code === 'auth/too-many-requests')
                        sweetAlerts('Too many requests sent', 'error')
                    if (error.code === 'auth/quota-exceeded')
                        sweetAlerts('SMS sending quota exceeded', 'error')
                    console.log('error', error);
                    // Error; SMS not sent
                    // ...
                });
        });
    }, [])

    return (
        <div style={{ width: '269px' }}>
            {showOtp ?

                <Modal
                    open={showOtp}
                    onClose={() => { setShowFirebase(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>

                        <div className='d-flex'>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Please enter the OTP sent on your phone {authUser.phone}

                            </Typography>
                            <CloseOutlinedIcon className='header' style={{ cursor: 'pointer' }} onClick={() => { setShowFirebase(false) }} />

                        </div>
                        <TextInputField onChange={handler}
                            value={code} />
                        <Button style={{ margin: '10px' }} onClick={() => { setShowFirebase(false) }} variant="contained" color="denger"
                        >
                            Cancel
                        </Button>
                        <Button style={{ margin: '10px' }} onClick={validate} variant="contained" color="primary"
                            disabled={isPhoneVerified || (code && code.length != 6)}>
                            Verify
                        </Button>
                    </Box>
                </Modal>
                :
                <Box mb={2}>
                    <div id='recaptcha-container' style={{ display: 'none' }} ></div>

                </Box>
            }

        </div>
    );
}
