import React, { useEffect, useState } from 'react';
import { Card } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';
import { Container, TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { toggleModal, selectCat } from '../../../../redux/actions/CategoryManagementAction';
import { rootUrl } from '../../../../api-handling/rootUrl';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import Edit from '@material-ui/icons/Edit';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IntlMessages from '@jumbo/utils/IntlMessages';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

import { http } from '../../../../appmakers/services/http-service';
import sweetAlerts from '../../../../appmakers/components/SweetAlertHelper';
import '../../../../css/CategoryManagement/updateCategory.css';
import { UpdateQuestions } from './updateQuestions';

const useStyles = makeStyles(theme => ({
  inputField: {
    flex: 1,
    marginLeft: '0.5em',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const resetForm = {
  profession: '',
  Location: '',
  price: 0,
};

export const UpdateCategory = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  let { open, selectedCat, professions } = useSelector(({ CategoryManagementReducer }) => CategoryManagementReducer);
  const [data, setData] = useState({ ...resetForm });
  const [profession, setProfession] = useState('');
  const [price, setPrice] = useState(0);
  let [editId, setEditId] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [formFlag, setFormFlag] = useState(false);
  const [isCedit, setIsCedit] = useState('');
  const [isSCedit, setIsSCedit] = useState('');
  function usePrevious(value) {
    const ref = React.useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const setFormFlagHandler = value => {
    setFormFlag(value);
    setIsCedit('');
  };

  //handlers
  const handleOpen = () => {
    dispatch(toggleModal(true));
  };

  const handleClose = () => {
    setSelectedImage(null);
    dispatch(toggleModal(false));
  };

  const uploadImageInGallery = async (event, file) => {
    const fsize = file.size;
    const newFile = Math.round(fsize / 1024);
    const src = URL.createObjectURL(file);
    console.log(src);
    if (newFile >= 4096) {
      handleClose();
      sweetAlerts('File too Big, please select a file less than 4mb', 'Error');
      return event.target.value = null;

    } else if (file.type.split('/')[0] === 'image') {
      setSelectedImage(file);
    } else {
      handleClose();
      sweetAlerts('Invalid file type', 'Error');
      return event.target.value = null;

    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    let profName = profession ? profession : selectedCat?.name;
    let cost = price ? price : selectedCat?.credits;
    const formData = new FormData();
    let userDtata = JSON.parse(localStorage.getItem('adminUser'));

    function getExt(filename) {
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
    }
    let myNewFile = null;
    if (selectedImage != null) {
      let fileExt = getExt(selectedImage?.name);
      myNewFile = new File([selectedImage], `categoryImages/${profName.replace(/ /g, '')}.${fileExt[0]}`);
    }
    if (selectedImage != null) {
      let fileExt = getExt(selectedImage.name);
      myNewFile = new File([selectedImage], `categoryImages/${profName.replace(/ /g, '')}.${fileExt[0]}`);
      formData.append('newFile', myNewFile);
      formData.append('newFileName', myNewFile.name);
      formData.append('_id', selectedCat?._id);
      formData.append('name', profName);
      formData.append('credits', cost);
      formData.append('user', userDtata);
    }
    let data = {
      _id: selectedCat?._id,
      name: profName,
      credits: cost,
      user: userDtata,
    };
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    handleClose();
    if (myNewFile != null) {

      http
        .post(`${rootUrl}/update-profession-with-image`, formData, config)
        .then(res => {
          //debugger;
          dispatch(selectCat(res.data.data))
          setFormFlagHandler(false)
          sweetAlerts('Profession updated Successfully', 'success');
        })
        .catch(error => {
          sweetAlerts(error.data, 'error');
        });
    } else {
      http
        .post(`${rootUrl}/update-profession`, { data })
        .then(res => {
          //debugger;
          dispatch(selectCat(res.data.data))
          setFormFlagHandler(false)
          sweetAlerts('Profession updated Successfully', 'success');
        })
        .catch(error => {
          sweetAlerts('Failed to update Profession', 'error');
        });
    }

  };
  useEffect(() => {
    setEditId(selectedCat?._id);
  }, [selectedCat]);

  const main = { display: 'flex', height: '100%' };

  return (
    <div style={main}>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3} className={'item'}>
          <Card>
            <div className={'leftmenu'}>
              <ul>
                <li className={'main'}>
                  {' '}
                  Edit
                  <ul>
                    <li className={'in active'}> Category</li>
                  </ul>
                </li>
                <li className={'main'}>
                  {' '}
                  Quick Links
                  <ul>
                    <li className={`in ${isCedit}`}> Edit Profession</li>
                    <li className={`in ${isSCedit}`}> Edit Subcategory</li>
                  </ul>
                </li>
              </ul>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9} className={'item'}>
          {!formFlag && (
            <Card>
              <CmtCardHeader>
                <h4>
                  Edit Profession - <span className={'link'}>{selectedCat?.name}</span>
                </h4>
              </CmtCardHeader>

              <div className={'mt-3 ml-3'}>
                <Grid container>
                  <Grid item className={'half1'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      <label>Professions Name </label>{' '}
                    </b>
                    {selectedCat?.name}
                    <b>
                      <label className="mt-6"> Credits </label>{' '}
                    </b>
                    {selectedCat?.credits}
                  </Grid>
                  <Grid item className={'d-flex'} xs={12} sm={12} md={5} lg={5}>
                    <b>
                      <label>Image </label>
                    </b>
                    <img className={'img'} src={`${selectedCat?.imageUrl}?${new Date()}`} />
                  </Grid>
                </Grid>
              </div>

              <div className={'d-flex justify-content-center m-3 p3'}>
                <Button
                  className={'edit'}
                  onClick={() => {
                    setFormFlag(true);
                    setIsCedit('active');
                  }}
                  variant="contained"
                  color="success">
                  <Edit style={{ fontSize: 16 }} /> &nbsp;Edit Profession
                </Button>
                <Button
                  className={'cancel'}
                  onClick={() => {
                    history.push('/category-management');
                  }}
                  variant="contained"
                  color="error">
                  <KeyboardBackspaceIcon style={{ fontSize: 16 }} />
                  &nbsp; Back
                </Button>
              </div>
            </Card>
          )}
          {formFlag && (
            <Card>
              <CmtCardHeader>
                <h4>
                  Edit Profession - <span className={'link'}>{selectedCat?.name}</span>
                </h4>
              </CmtCardHeader>
              <Card.Content className={'pt-4 pl-4 pr-4  m-4'}>
                <Container>
                  <Grid container spacing={2}>
                    <Grid item lg="6">
                      <div className={'d-flex mb-3'}>
                        <div className={classes.inputField}>
                          <TextField
                            id="outlined-search"
                            style={{ width: '100%' }}
                            defaultValue={selectedCat?.name}
                            //value={data.price?data.price:selectedCat?.price}
                            onChange={event => setProfession(event.target.value)}
                            name={'profession'}
                            label="Profession Name"
                            variant="outlined"
                          />
                          {/* {validationError('profession')} */}
                        </div>
                      </div>

                      <div className={'d-flex mb-3'}>
                        <div className={classes.inputField}>
                          <TextField
                            id="outlined-search"
                            onChange={event => {
                              console.log(event.target.files[0]);
                              uploadImageInGallery(event, event.target.files[0]);
                            }}
                            style={{ width: '100%' }}
                            name={'imageUrl'}
                            variant="outlined"
                            type="file"
                            accept="image/*"
                          />
                          {/* {validationError('price')} */}
                        </div>
                      </div>

                      <div className={'d-flex mb-3'}>
                        <div className={classes.inputField}>
                          <TextField
                            id="outlined-search"
                            onChange={e => {
                              setPrice(e.target.value);
                            }}
                            style={{ width: '100%' }}
                            defaultValue={selectedCat?.credits}
                            //value={data.price?data.price:selectedCat?.price}
                            name={'price'}
                            label="Credits"
                            variant="outlined"
                            type="number"
                          />
                          {/* {validationError('price')} */}
                        </div>
                      </div>
                    </Grid>
                    <Grid item lg="6">
                      <img
                        alt="Not Found"
                        style={{ height: '200px' }}
                        src={selectedImage ? URL.createObjectURL(selectedImage) : `${selectedCat?.imageUrl}?${new Date()}`}
                      />
                    </Grid>
                  </Grid>
                  <div className={'d-flex justify-content-center p3'}>
                    <Button variant="contained" color="primary" onClick={e => handleSubmit(e)}>
                      <BackupOutlinedIcon />
                      &nbsp;
                      <IntlMessages id="appModule.submit" />
                    </Button>
                    <Button variant="contained" className={'cancel'} onClick={() => setFormFlagHandler(false)}>
                      <CloseOutlinedIcon />
                      &nbsp;
                      <IntlMessages id="appModule.cancel" />
                    </Button>
                  </div>
                </Container>
              </Card.Content>
            </Card>
          )}
          <UpdateQuestions setIsSCedit={setIsSCedit} />
        </Grid>
      </Grid>
    </div>
  );
};
