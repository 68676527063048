import React, { forwardRef, useEffect, useState } from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import Checkbox from '@material-ui/icons/CheckBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { http } from "../services/http-service";
let axios = require('axios');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Checkbox: forwardRef((props, ref) => <Checkbox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ApmTable({
  columns,
  data,
  title,
  addHandler,
  updateHandler,
  deleteHandler,
  paramsHandler,
  params,
  actions = [],
  name,
  showActions = true,
  NameError,
}) {
  const classes = useStyles();
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const setError = (obj) => {
    NameError(obj)
    setTimeout(() => {
      NameError({})
    }, 600)
  }
  const [state, setState] = React.useState({
    columns: columns,
    data: data,
  });
  const [selectedRow, setSelectedRow] = React.useState([{}])

  useEffect(() => {
    setState({ ...state, data: data });
  }, [data, name]);

  const editable = { isDeleteHidden: (rowData) => true };

  if (name && showActions) {
    let permission = permissions[name];
    if (permission && permission.includes('edit')) {
      editable.onRowAdd = async newData =>
        await new Promise((resolve, reject) => {
          setTimeout(async () => {
            const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
            let data = {
              error: false,
              helperText: ''
            }
            if (!newData.userName) {
              data.error = true;
              data.helperText = data.helperText + ' User name is required .';
            }
            if (!emailRegex.test(newData.email)) {
              data.error = true;
              data.helperText = data.helperText + 'Valid email is required .';
            }
            if (!newData.userType) {
              data.error = true;
              data.helperText = data.helperText + ' User type is required .';
            }
            if (newData.userType == 'Business User' && !newData.businessName) {
              data.error = true;
              data.helperText = data.helperText + ' Business name is required .';
            }
            if (newData.userType == 'Business User' && !newData.businessABN) {
              data.error = true;
              data.helperText = data.helperText + ' NZBN no is required .';
            }
            if (newData.userType == 'Business User' && !newData.professions) {
              data.error = true;
              data.helperText = data.helperText + ' Professions is required .';
            }
            if (newData.userType == 'Business User' && !newData.address) {
              data.error = true;
              data.helperText = data.helperText + ' Address is required .';
            }
            // if (newData.userType == 'Business User' && newData.businessABN != '') {
            //   const requestOptions = {
            //     //method: 'GET',
            //     headers: {
            //       'Content-Type': 'application/json',
            //       Authorization: 'Bearer dd41bb5137cf5fe45c62d6e7e6692d6b',
            //     },
            //   };

            //   await axios.get(`https://api.business.govt.nz/services/v4/nzbn/entities/${newData.businessABN}`, requestOptions)
            //     .then(res => res.json())
            //     .then(data1 => {

            //     }).catch(error => {
            //       console.log(error.response)
            //       if (error?.response?.status == 400) {
            //         data.error = true;
            //         data.helperText = data.helperText + 'Please provide a valid NZBN no .';
            //       }
            //     })

            // }
            if (data.error) {
              setError(data);
              reject();
              return;
            } else {
              addHandler(newData);
              setState(prevState => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
              resolve();
            }
          }, 600);
        });
      editable.onRowUpdate = async (newData, oldData) =>
        await new Promise((resolve, reject) => {
          const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
          let data = {
            error: false,
            helperText: ''
          }
          setTimeout(async () => {
            if (!newData.userName) {
              data.error = true;
              data.helperText = data.helperText + ' User name is required .';
            }
            if (!newData.email) {
              data.error = true;
              data.helperText = data.helperText + ' Email is required .';
            }

            if (!newData.mobile) {
              data.error = true;
              data.helperText = data.helperText + ' Mobile number is required .';
            }
            if (!newData.userType) {
              data.error = true;
              data.helperText = data.helperText + ' User type is required .';
            }
            if (newData.userType == 'Business User' && !newData.businessName) {
              data.error = true;
              data.helperText = data.helperText + ' Business name no is required .';
            }
            if (newData.userType == 'Business User' && !newData.businessABN) {
              data.error = true;
              data.helperText = data.helperText + ' NZBN no is required .';
            }
            if (newData.userType == 'Business User' && !newData.professions) {
              data.error = true;
              data.helperText = data.helperText + ' Professions is required .';
            }
            if (newData.userType == 'Business User' && !newData.address) {
              data.error = true;
              data.helperText = data.helperText + ' Address is required .';
            }
            // if (newData.userType == 'Business User' && newData.businessABN != '') {
            //   const requestOptions = {
            //     //method: 'GET',
            //     headers: {
            //       'Content-Type': 'application/json',
            //       Authorization: 'Bearer dd41bb5137cf5fe45c62d6e7e6692d6b',
            //     },
            //   };

            //   await axios.get(`https://api.business.govt.nz/services/v4/nzbn/entities/${newData.businessABN}`, requestOptions)
            //     .then(res => res.json())
            //     .then(data1 => {

            //     }).catch(error => {
            //       console.log(error.response)
            //       if (error?.response?.status == 400) {
            //         data.error = true;
            //         data.helperText = data.helperText + 'Please provide a valid NZBN no .';
            //       }
            //     })

            // }
            if (data.error) {
              setError(data);
              reject();
              return;
            }
            resolve();
            updateHandler(newData);
            if (oldData) {
              setState(prevState => {
                const data = [...prevState.data];
                data[data.indexOf(oldData)] = newData;
                return { ...prevState, data };
              });
            }
            resolve();
          }, 600);
        });
    }
    if (permission && permission.includes('delete')) {
      editable.onRowDelete = oldData =>
        new Promise(resolve => {
          setTimeout(() => {
            deleteHandler(oldData);
            resolve();
            setState(prevState => {
              const data = [...prevState.data];
              data.splice(data.indexOf(oldData), 1);
              return { ...prevState, data };
            });
          }, 600);
        });
    }
  }

  return (
    <MaterialTable
      actions={actions}
      className={classes.root}
      icons={tableIcons}
      title={`${title}`}
      key={state.data.length}
      columns={state.columns}
      data={state.data}
      options={{
        pageSize: params.perPage,
        emptyRowsWhenPaging: false,
        selection: true,
        search: false,
        actionsColumnIndex: -1,
        // rowStyle: (rowData) => ({
        //   backgroundColor:
        //   selectedRow.length > 0 && selectedRow?.tableData?.checked === rowData?.tableData?.checked ? "#FFF" : "rgb(236, 239, 252)",
        // }),
      }}
      onSelectionChange={(rowData) => {
        let rowDataCopy = [...rowData];
        setSelectedRow(rowDataCopy);
      }}
      editable={editable}
      components={{

        Pagination: props => (
          <TablePagination
            {...props}
            rowsPerPageOptions={[5, 25, 50, 100, 200]}
            rowsPerPage={params.perPage}
            count={params.total}
            page={params.page}
            onChangePage={(e, page) => {
              paramsHandler('page', page);
            }}
            onChangeRowsPerPage={event => {
              paramsHandler('perPage', event.target.value);
            }}
          />
        ),
      }}
      tableLayout="fixed"
    />
  );
}
