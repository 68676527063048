import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function PasswordInput({ onKeyPress, onChange, val, label }) {
  const classes = useStyles();

  const [value, setValue] = useState(val);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    setValue(val);
  }, [val]);

  const handleInputChange = event => {
    onChange(event.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return (
    <div className={classes.root}>
      <FormControl variant="outlined" size="small" className={classes.withoutLabel}>
        {label && <InputLabel htmlFor="standard-adornment-password">{label}</InputLabel>}
        <OutlinedInput
          fullWidth
          label={label}
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={value}
          onChange={handleInputChange}
          onKeyPress={onKeyPress}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                // aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
    </div>
  );
}
