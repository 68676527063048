import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import { http } from '../../../../appmakers/services/http-service';
import { rootUrl } from '../../../../api-handling/rootUrl';
import Badge from '@material-ui/core/Badge';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { shadows } from '@material-ui/system';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Radium, { StyleRoot } from 'radium';
import { makeStyles } from '@material-ui/core';
import { fetchStart, fetchSuccess } from '../../../../redux/actions/Common';
import moment from 'moment';
const { getStartAndEndOfDayInUTC,
  getStartAndEndOfMonthInUTC,
  getStartAndEndOfYearInUTC,
  getStartAndEndOfMonthInUTCForInsert,
  getOffSet } = require("../../../../helpers/dateHelper")


const useStyles = makeStyles(() => ({
  spanWidth: {
    width: '275px',
    ['@media (min-width:600px) and (max-width:803px)']: {
      width: '185px',
    },
    ['@media (min-width:960px) and (max-width:1212px)']: {
      width: '205px',
    }
  },

}))

const leadStyle = {
  '@media (max-width: 600px)': {
    width: '314px',
    backgroundColor: 'white',
    margin: '0px 0px 0px -1px',
    float: 'left',
    boxShadow: '5px 7px 19px grey',
  },
  '@media (min-width: 601px)': {
    width: '314px',
    backgroundColor: 'white',
    margin: '0px 30px',
    float: 'left',
    boxShadow: '5px 7px 19px grey',
  },
};
export const LeadPreferences = () => {
  //const Today = new Date("2021-07-09T12:36:09.493+00:00").getTime()
  const [leads, setLeads] = useState([]);
  const { permissions } = useSelector(({ RoleManagementReducer }) => RoleManagementReducer);
  const { count, startDate, endDate } = useSelector(({ DashboardReducer }) => DashboardReducer);
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const utcOffset = getOffSet();

  const getLeads = () => {
    dispatch(fetchStart());
    const startDateUTC = new Date(startDate).toISOString();
    const endDateUTC = new Date(endDate).toISOString();
    http
      .get(`${rootUrl}/get-dashboard-data-leads?startDate=${startDateUTC}&endDate=${endDateUTC}`)
      .then(res => {
        let result = res.data.data[0].jobsCountByCategory;
        setLeads(result.slice(0, 5));
        dispatch(fetchSuccess());
      })
      .catch(e => {
        dispatch(fetchSuccess());
        console.log(e)
      });

    // http
    //   .get(`${rootUrl}/get-daily-summary`)
    //   .then(res => {
    //     let result = '';
    //     if (count == 2) {
    //       result = res.data.data[0].leads_category_count_month;
    //     } else if (count == 3) {
    //       result = res.data.data[0].leads_category_count_year;
    //     } else {
    //       result = res.data.data[0].leads_category_count;
    //     }

    //     //console.log(res.data.data[0].leads_category_count)
    //     setLeads(result.slice(0, 5));
    //     // var result = res.data.data.filter(d => {
    //     //   //var time = new Date(d.postedDate).getTime();
    //     //   //   // console.log(time,Today)
    //     //   //   // return (time >= Today);
    //     //   console.log(d)
    //     //    });
    //     dispatch(fetchSuccess());

    //   })
    //   .catch(e => {
    //     dispatch(fetchSuccess());
    //     console.log(e)
    //   });
  }
  // useEffect(() => {
  //   getLeads();
  // }, []);


  useEffect(() => {
    if (!startDate || !endDate)
      return
    getLeads(count);
  }, [startDate, endDate]);
  return (
    <StyleRoot>
      <div>
        <CardContent>
          <Typography style={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            <h6 style={{ color: 'black', fontSize: '15px' }}> Leads based on Category </h6>
          </Typography>
          <Divider />
          {leads &&
            leads.map((job, index) => {
              return (
                <Typography variant="h5" component="h2" style={{ marginTop: '13px' }}>
                  <Badge badgeContent={job.Count} color="primary">
                    <span
                      className={`${classes.spanWidth}`}
                      style={{
                        backgroundColor: '#eaeaf1',
                        height: '24px',
                        textAlign: 'left',
                        padding: '4px',
                        borderRadius: '8px',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        color: '#667eea',
                      }}
                      onClick={() => {
                        history.push('/view-leads-details/' + job.categoryId);
                      }}>
                      {/* onClick={()=>{history.push('/view-leads-details/'+job.categoryId)}} */}
                      {job.category.length > 0 ? job.category[0].name : ''}
                    </span>
                  </Badge>
                </Typography>
              );
            })}
          {leads.length == 0 && (
            <Typography
              variant="h5"
              component="h2"
              style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
              No Leads Avaliable
            </Typography>
          )}
          <Divider style={{ marginTop: '10px' }} />
          <Button
            variant="contained"
            color="primary"
            disabled={!permissions['User Management']}
            endIcon={<VisibilityIcon />}
            style={{
              margin: '10px 7px',
              float: 'right',
              borderRadius: '20px',
              height: '25px',
              textTransform: 'capitalize',
            }}
            onClick={() => {
              history.push(`/view-all-leads`);
            }}>
            View All
          </Button>
        </CardContent>
        <CardActions>
          <Button size="small"></Button>
        </CardActions>
      </div>
    </StyleRoot>
  );
};
export default LeadPreferences;
