const firebaseConfig = {
  apiKey: "AIzaSyAcX3ZTutJV1D-EZzLJLZO5YgrGj_2qigw",
  authDomain: "tradingseek-3a85a.firebaseapp.com",
  projectId: "tradingseek-3a85a",
  storageBucket: "tradingseek-3a85a.appspot.com",
  messagingSenderId: "307099534870",
  appId: "1:307099534870:web:1c87d02e90d43239195737",
  measurementId: "G-MBCY0FCTJ0"
};

export default firebaseConfig;
